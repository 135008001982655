// Test with: https://react-pdf.org/repl
import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

type Props = {
  title?: string;
  headers: string[];
  rows: (string | number | boolean)[][];
};

export const TablePDF: React.FC<Props> = ({ title, headers, rows }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        {title && <Text style={styles.title}>{title}</Text>}
        <View style={[styles.th, styles.tr]}>
          {headers.map((header, index) => (
            <Text
              key={index}
              style={[
                styles.td,
                {
                  width: `${(1 / headers.length) * 100}%`,
                  ...(index === 0 && { borderLeftWidth: 1 }),
                },
              ]}
            >
              {header}
            </Text>
          ))}
        </View>
        {rows.map((row, rIndex) => (
          <View key={rIndex} style={styles.tr}>
            {row.map((cell, cIndex) => (
              <Text
                key={cIndex}
                style={[
                  styles.td,
                  {
                    width: `${(1 / headers.length) * 100}%`,
                    ...(cIndex === 0 && { borderLeftWidth: 1 }),
                  },
                ]}
              >
                {cell}
              </Text>
            ))}
          </View>
        ))}
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 20,
    marginBottom: 10,
  },
  th: {
    fontSize: 14,
    textAlign: "center",
    color: "grey",
    borderTopWidth: 1,
  },
  td: {
    borderRightWidth: 1,
    fontSize: 14,
  },
  tr: {
    flexDirection: "row",
    borderBottomWidth: 1,
  },
});
