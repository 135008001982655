/* @flow */
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { isPlainObject, mapValues, isArray } from "lodash-es";

import fontAwesome from "./fontAwesome";
import { plainToFlattenObject } from "../../utils/object";

// TODO: fonts import based on instance?
pdfMake.vfs = {
  ...pdfFonts.pdfMake.vfs,
  "FontAwesome-Italic.ttf": fontAwesome,
  "FontAwesome-Medium.ttf": fontAwesome,
  "FontAwesome-MediumItalic.ttf": fontAwesome,
  "FontAwesome-Regular.ttf": fontAwesome,
};

pdfMake.fonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
  FontAwesome: {
    normal: "FontAwesome-Regular.ttf",
    bold: "FontAwesome-Medium.ttf",
    italics: "FontAwesome-Italic.ttf",
    bolditalics: "FontAwesome-MediumItalic.ttf",
  },
};

const hydrateWithFunctions = (docDefinitions, functions, config, values) => {
  /*
  Transform a string containing Javascript code to a Javascript function
  */
  const docContext = { values, config };
  const stringToClosureWithContext = (code: string) => {
    // I know that `Function` is not safe but our use case needs it
    try {
      /* eslint-disable no-new-func */
      //$FlowFixMe
      const func = Function('"use strict";return ' + code).call(docContext);
      /* eslint-enable no-new-func */
      return func;
    } catch (err) {
      throw new Error(
        `Unable to parse export function with code:\n ${code} \n\n${err}`
      );
    }
  };

  /*
  Functions deserialization

  Each object field that has `fnString` as child is transformed to a callable closure, 
  if explicitly requested the closure is called and its value is returned.

  Function are serialized as follow:
    {
      fnString: "functionName", -> name to lookup on structure functions
      params: [param1, param2], -> list of parameters
      execute: true -> if true the function is executed right ahead its creation,
                        otherwise the closure is returned
    }
 */
  const parseStringFunction = (functionObj: Object) => {
    if (functionObj.fnString) {
      const params = functionObj.params || [];
      if (functionObj.execute) {
        return stringToClosureWithContext(functions[functionObj.fnString])(
          ...params
        );
      } else {
        return (...args) =>
          stringToClosureWithContext(functions[functionObj.fnString])(
            ...params,
            ...args
          );
      }
    }
  };

  // Walk recursively through the structure object and deserialize functions
  const mapValuesDeep = (obj: Object) => {
    return mapValues(obj, (val) => {
      if (isPlainObject(val)) {
        if (val.fnString) {
          return parseStringFunction(val);
        } else {
          return mapValuesDeep(val);
        }
      } else if (isArray(val)) {
        return val.map((v) => (isPlainObject(v) ? mapValuesDeep(v) : v));
      } else {
        return val;
      }
    });
  };
  return mapValuesDeep(docDefinitions);
};

//// EXPORT

// create and open a pdf in a new window
const exportEntry = (structure: Object, values: Object): void => {
  const hydratedTemplate = hydrateWithFunctions(
    structure.docDefinitions,
    structure.functions,
    structure.config || {},
    plainToFlattenObject(values)
  );
  pdfMake.createPdf(hydratedTemplate).open();
};

export default {
  exportEntry,
};
