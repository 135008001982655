import { Ability } from "@casl/ability";
import { ContentType } from "../../models/ContentType";
import type {
  ACLRule,
  ACLRules,
  Entry,
  User,
  UserRole,
  Tenant,
  Organization,
} from "../../types";

const toACLRule = (backendACL: any): ACLRule => ({
  policy: backendACL.policy,
  policyExceptions: backendACL.policyExceptions,
});

const toACLRules = (backendACLs: any): ACLRules => {
  const aclRules = {};

  Object.keys(backendACLs).forEach(
    (backendACL) => (aclRules[backendACL] = toACLRule(backendACLs[backendACL]))
  );

  return aclRules;
};

const toContentType = (backendContentType: any): ContentType =>
  new ContentType(
    backendContentType.uid,
    backendContentType.slug,
    backendContentType.label,
    backendContentType.description,
    backendContentType.schema,
    backendContentType.ui_schema
  );

const toEntry = (backendEntry: any): Entry => ({
  uid: backendEntry.uid,
  contentTypeId: backendEntry.content_type_id,
  data: backendEntry.data,
  createdAt: new Date(backendEntry.created_at).getTime(),
  updatedAt: new Date(backendEntry.updated_at).getTime(),
  stagesMetadata: backendEntry.stage_status,
  contentTypeName: backendEntry.content_type_name,
  tenantId: backendEntry.tenant_id,
});

const toUserEntry = (contentTypeId: number) => {
  return (backendUsersEntry: Object) => ({
    id: backendUsersEntry.id,
    contentTypeId,
    data: backendUsersEntry.data,
    createdAt: new Date(backendUsersEntry.created_at).getTime(),
    updatedAt: new Date(backendUsersEntry.updated_at).getTime(),
  });
};

const toUserRole = (backendUserRole: any): UserRole => {
  return {
    uid: backendUserRole.uid,
    name: backendUserRole.name,
    label: backendUserRole.label,
    acls: new Ability(backendUserRole.acls),
  };
};

const toUser = (backendUser: any): User => ({
  uid: backendUser.uid,
  email: backendUser.email,
  name: backendUser.name,
  surname: backendUser.surname,
  nominative: backendUser.nominative,
});

const toTenant = (backendTenant: any): Tenant => ({
  uid: backendTenant.uid,
  slug: backendTenant.slug,
  label: backendTenant.label,
  configuration: backendTenant.configuration,
  role: toUserRole(backendTenant.role),
  contentTypes: backendTenant.content_types.map((contentType) =>
    toContentType(contentType)
  ),
  organizationId: backendTenant.organization_id,
});

const toOrganization = (backendOrganization: any): Organization => ({
  uid: backendOrganization.uid,
  name: backendOrganization.name,
  description: backendOrganization.description,
  contacts: {
    phone: backendOrganization.contacts?.phone,
    email: backendOrganization.contacts?.email,
    address1: backendOrganization.contacts?.address1,
    address2: backendOrganization.contacts?.address2,
    website: backendOrganization.contacts?.website,
  },
});

export default {
  toContentType,
  toEntry,
  toUserEntry,
  toUserRole,
  toUser,
  toTenant,
  toOrganization,
};
