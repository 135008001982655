/* @flow */
const setItem = (key: string, value: Object) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getItem = (key: string) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

const removeItem = (key: string) => {
  localStorage.removeItem(key);
};

const cacheAction = (key: string, data: any) => {
  const queue = getItem(key) || [];
  queue.push(data);
  setItem(key, queue);
};

const cacheUploadFileAction = (key: string, data: any) => {
  const queue = getItem(key) || [];
  const cachedFileAction = queue.find(
    (element) =>
      element.entryId === data.entryId && element.fieldId === data.fieldId
  );
  if (!cachedFileAction) {
    queue.push(data);
    setItem(key, queue);
  }
};

export default {
  setItem,
  getItem,
  removeItem,
  cacheAction,
  cacheUploadFileAction,
};
