/* @flow */
import { merge } from "lodash-es";
import { lighten, darken, transparentize } from "polished";

import type { Theme } from "../../types";

const BRAND_COLOR = "#EDD84F";
const SECONDARY_COLOR = "#555756";

const accentColors = ["#6FFFB0", "#FD6FFF", "#81FCED", "#FFCA58"];
const neutralColors = ["#00873D", "#3D138D", "#00739D", "#A2423D"];
const statusColors = {
  critical: "#FF4040",
  error: "#FF4040",
  warning: "#FFAA15",
  ok: "#00C781",
  unknown: "#CCCCCC",
  disabled: "#CCCCCC",
};
const darkColors = [
  "#333333",
  "#555555",
  "#777777",
  "#999999",
  "#999999",
  "#999999",
];
const lightColors = [
  "#F8F8F8",
  "#F2F2F2",
  "#EDEDED",
  "#DADADA",
  "#DADADA",
  "#DADADA",
];
const focusColor = accentColors[0];

const colors = {
  background: "#F8FAFB",
  darkenBackground: darken(0.03, "#F8FAFB"),
  active: "rgba(221, 221, 221, 0.5)",
  inactive: "rgba(0, 0, 0, 0.025)",
  black: "#000000",
  white: "#FFFFFF",
  border: {},
  /* normal: {
      light: "#eaeaea",
      dark: "rgba(255, 255, 255, 0.2)"
    },
    hover: {
      light: "#d1d1d1",
      dark: "rgba(0,0,0,0.35)"
    },
    dark: "rgba(255, 255, 255, 0.2)",
    light: "#eaeaea"
  },*/
  brand: BRAND_COLOR,
  lightBrand: lighten(0.08, BRAND_COLOR),
  darkBrand: darken(0.08, BRAND_COLOR), // TODO: calcolare dopo nella funzione per prendere il brand color dall'oggetto (che può essere sovrascritto dalla config del tenant)
  secondaryColor: SECONDARY_COLOR,
  lightSecondaryColor: lighten(0.08, SECONDARY_COLOR),
  darkSecondaryColor: darken(0.08, SECONDARY_COLOR),
  sidebar: {
    backgroundColor: "#F8FAFB",
  },
  control: {
    dark: "accent-1",
    light: "brand",
  },
  focus: focusColor,
  placeholder: "#AAAAAA",
  selected: "brand",
  status: statusColors,
  text: {
    dark: "#f8f8f8",
    light: "#444444",
  },
  secondaryText: {
    dark: "#f8f8f8",
    light: "#6c757d",
  },
  icon: {
    dark: "#f8f8f8",
    light: "#666666",
  },
  table: {
    title: "rgba(0,0,0,0.6)",
  },
  nav: {
    normal: "rgba(252, 252, 252, 0.5)",
    focus: "rgba(252, 252, 252, 0.7)",
    active: "rgba(252, 252, 252, 0.9)",
  },
  link: {
    initial: SECONDARY_COLOR,
    hover: lighten(0.08, SECONDARY_COLOR),
    active: darken(0.1, SECONDARY_COLOR),
  },
};

const generateTheme = (baseSpacing = 24, scale = 6): Theme => {
  colors.border = {
    normal: darken(0.03, colors.background),
    hover: darken(0.11, colors.background),
  };

  const global = {
    animation: {
      duration: "1s",
      jiggle: {
        duration: "0.1s",
      },
      fade: {
        duration: "0.3s",
      },
    },
    border: {
      size: {
        xsmall: "1px",
        small: "2px",
        medium: `${baseSpacing / 6}px`, // 4
        large: `${baseSpacing / 2}px`, // 12
        xlarge: `${baseSpacing}px`, // 24
      },
      radius: {
        xsmall: "1px",
        small: "2px",
        medium: `${baseSpacing / 6}px`, // 4
        large: `${baseSpacing / 2}px`, // 12
        xlarge: `${baseSpacing}px`, // 24
      },
    },
    breakpoints: {
      small: {
        value: `${baseSpacing * 24 + 1}px`, // 577 - Small devices
      },
      medium: {
        value: `${baseSpacing * 32 + 1}px`, // 769 - Medium devices
      },
      large: {
        value: `${baseSpacing * 41 + 1}px`, // 985 - Large devices
      },
      xlarge: {
        value: `${baseSpacing * 50 + 1}px`, // 1201 - Extra large devices
      },
    },
    colors,
    elevation: {
      none: "none",
      xsmall: `0px 1px 2px ${transparentize(0.18, colors.border.normal)}`,
      small: `0px 2px 4px ${transparentize(0.18, colors.border.normal)}`,
      medium: `0px 4px 8px ${transparentize(0.18, colors.border.normal)}`,
      large: `0px 8px 16px ${transparentize(0.18, colors.border.normal)}`,
      xlarge: `0px 12px 24px ${transparentize(0.18, colors.border.normal)}`,
      /*light: {
        none: "none",
        xsmall: "0px 1px 2px rgba(0, 0, 0, 0.05)",
        small: "0px 2px 4px rgba(0, 0, 0, 0.05)",
        medium: "0px 4px 8px rgba(0, 0, 0, 0.05)",
        large: "0px 8px 16px rgba(0, 0, 0, 0.05)",
        xlarge: "0px 12px 24px rgba(0, 0, 0, 0.05)"
      },
      dark: {
        none: "none",
        xsmall: "0px 2px 2px rgba(255, 255, 255, 0.40)",
        small: "0px 4px 4px rgba(255, 255, 255, 0.40)",
        medium: "0px 6px 8px rgba(255, 255, 255, 0.40)",
        large: "0px 8px 16px rgba(255, 255, 255, 0.40)",
        xlarge: "0px 12px 24px rgba(255, 255, 255, 0.40)"
      }*/
    },
    padding: {
      none: "0px",
      hair: "1px", // for Chart
      xxsmall: "0.1875rem", // 3
      xsmall: "0.5rem", // 6
      small: "1rem", // 12
      medium: "1.5rem", // 24
      large: "3rem", // 48
      xlarge: "6rem", // 96
      responsiveBreakpoint: "small",
    },
    spacing: `${baseSpacing}px`,
    // size: {
    //   xxsmall: `${baseSpacing * 2}px`, // 48
    //   xsmall: `${baseSpacing * 4}px`, // 96
    //   small: `${baseSpacing * 8}px`, // 192
    //   medium: `${baseSpacing * 16}px`, // 384
    //   large: `${baseSpacing * 32}px`, // 768
    //   xlarge: `${baseSpacing * 48}px`, // 1152
    //   xxlarge: `${baseSpacing * 64}px`, // 1536
    //   full: "100%"
    // },
    typography: {
      lineHeight: {
        title: 1.2,
        text: 1.4,
        button: 1.5,
      },
      heading: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif`,
        fontSize: "0.875em",
        fontWeight: 500,
      },
      paragraph: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif`,
        fontSize: "0.875em",
        fontWeight: 400,
      },
      code: {
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif`,
        fontSize: "0.875em",
        fontWeight: 400,
      },
      text: {
        fontWeight: 400,
        boldWeight: 600,
      },
      h1: {
        fontSize: "2.5em",
        fontWeight: 500,
      },
      h2: {
        fontSize: "2em",
        fontWeight: 300,
      },
      h3: {
        fontSize: "1.75em",
        fontWeight: 500,
      },
      h4: {
        fontSize: "1.5em",
        fontWeight: 500,
      },
      h5: {
        fontSize: "1.25em",
        fontWeight: 500,
      },
      label: {
        fontWeight: 500,
      },
    },
    input: {
      minHeight: "2.125rem",
    },
    mobile: {
      header: {
        height: "2.5rem",
      },
    },
  };

  const components = {};

  const widgets = {};

  return merge({}, { global, components, widgets });
};

export default generateTheme();
