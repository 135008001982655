export const floatSafeRemainder = (val: number, step: number) => {
  var valDecCount = (val.toString().split(".")[1] || "").length;
  var stepDecCount = (step.toString().split(".")[1] || "").length;
  var decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  var valInt = parseInt(val.toFixed(decCount).replace(".", ""));
  var stepInt = parseInt(step.toFixed(decCount).replace(".", ""));
  return (valInt % stepInt) / Math.pow(10, decCount);
};

export const roundNumber = (number: number, places?: number) => {
  // @ts-ignore
  return +(Math.round(number + "e+" + (places || 2)) + "e-" + (places || 2));
};

export const randomInteger = () => {
  return Math.round(Number.MAX_SAFE_INTEGER * Math.random());
};

/*
  Returns a number whose value is limited to the given range
*/
const clamp = (num: number, min: number, max: number) => {
  return Math.min(Math.max(num, min), max);
};

const charIsNumber = (char?: string) => {
  return !!(char || "").match(/\d/);
};

//spilt a float number into different parts beforeDecimal, afterDecimal, and negation
const splitDecimal = (numStr: string, allowNegative: boolean = true) => {
  const hasNagation = numStr[0] === "-";
  const addNegation = hasNagation && allowNegative;
  numStr = numStr.replace("-", "");

  const parts = numStr.split(".");
  const beforeDecimal = parts[0];
  const afterDecimal = parts[1] || "";

  return {
    beforeDecimal,
    afterDecimal,
    hasNagation,
    addNegation,
  };
};

/**
 * limit decimal numbers to given scale
 * Not used .fixedTo because that will break with big numbers
 */
const limitToScale = (
  numStr: string,
  scale: number,
  fixedDecimalScale?: boolean
) => {
  let str = "";
  const filler = fixedDecimalScale ? "0" : "";
  for (let i = 0; i <= scale - 1; i++) {
    str += numStr[i] || filler;
  }
  return str;
};

/**
 * This method round value to given scale.
 * Not used .round or .fixedTo because that will break with big numbers
 */
const roundToPrecision = (
  numStr: string,
  scale: number,
  fixedDecimalScale?: boolean
) => {
  //if number is empty don't do anything return empty string
  if (["", "-"].indexOf(numStr) !== -1) return numStr;

  const shoudHaveDecimalSeparator = numStr.indexOf(".") !== -1 && scale;
  const { beforeDecimal, afterDecimal, hasNagation } = splitDecimal(numStr);
  const roundedDecimalParts = parseFloat(`0.${afterDecimal || "0"}`)
    .toFixed(scale)
    .split(".");
  const intPart = beforeDecimal
    .split("")
    .reverse()
    .reduce((roundedStr, current, idx) => {
      if (roundedStr.length > idx) {
        return (
          (Number(roundedStr[0]) + Number(current)).toString() +
          roundedStr.substring(1, roundedStr.length)
        );
      }
      return current + roundedStr;
    }, roundedDecimalParts[0]);

  const decimalPart = limitToScale(
    roundedDecimalParts[1] || "",
    Math.min(scale, afterDecimal.length),
    fixedDecimalScale
  );
  const negation = hasNagation ? "-" : "";
  const decimalSeparator = shoudHaveDecimalSeparator ? "." : "";
  return `${negation}${intPart}${decimalSeparator}${decimalPart}`;
};

export default {
  clamp,
  charIsNumber,
  splitDecimal,
  limitToScale,
  roundToPrecision,
};
