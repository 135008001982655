import { toJS } from "mobx";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Container from "../../components/Container";
import EntryContent from "../../components/EntryContent";
import EntryHeader from "../../components/EntryHeader";
import Form from "../../components/Form";
import { Spinner } from "../../components/Spinner";
import Tabs from "../../components/Tabs";
import { useStores } from "../../hooks/useStores";
import {
  FixMeLater,
  JSONSchema,
  Organization,
  Tenant,
  UISchema,
} from "../../types";
import navigationUtils from "../../utils/navigation";

import StyledTenantSettings from "./styled";

type Props = {};

export const TenantSettings: React.FC<Props> = observer(() => {
  const { t } = useTranslation();
  const { tenant } = useStores();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const currentTenantId: Tenant["uid"] | null = useMemo(() => {
    return navigationUtils.fromRoutes.tenantId(location.pathname);
  }, [location]);

  const currentTenant: Tenant | null = useMemo(() => {
    return currentTenantId
      ? tenant.tenants.find((tenant) => tenant.uid === currentTenantId) || null
      : null;
  }, [currentTenantId, tenant]);

  useEffect(() => {
    if (currentTenant && currentTenant.organizationId) {
      try {
        tenant.fetchOrganization(currentTenant.organizationId);
      } catch (err) {
        enqueueSnackbar(t("common.error"), {
          variant: "error",
        });
      }
    }
  }, [currentTenant, tenant, enqueueSnackbar, t]);

  if (tenant.isFetching || tenant.isFetchingOrganization) {
    return <Spinner size={150} />;
  }
  const schemaPreferences: JSONSchema = {
    title: "",
    type: "object",
    properties: {
      lang: {
        title: { [tenant.locale]: t("settings.general.lang") },
        type: "string",
        enum: currentTenant?.configuration.languages,
        enumNames: currentTenant?.configuration.languages.map((lang) => {
          const langs = {};

          for (let language of currentTenant?.configuration.languages) {
            langs[language] = t(`languages.${lang}`);
          }

          return langs;
        }),
      },
    },
  };

  const uiSchemaPreferences: UISchema = {};

  const schemaOrganization: JSONSchema = {
    title: "",
    type: "object",
    properties: {
      name: {
        title: { [tenant.locale]: t("settings.organization.title") },
        type: "string",
      },
      description: {
        title: { [tenant.locale]: t("settings.organization.description") },
        type: "string",
        localized: true,
      },
      phone: {
        title: { [tenant.locale]: t("settings.organization.phone") },
        type: "string",
      },
      email: {
        title: { [tenant.locale]: t("settings.organization.email") },
        type: "string",
      },
      address1: {
        title: { [tenant.locale]: t("settings.organization.address1") },
        type: "string",
      },
      address2: {
        title: { [tenant.locale]: t("settings.organization.address2") },
        type: "string",
      },
      website: {
        title: { [tenant.locale]: t("settings.organization.website") },
        type: "string",
      },
    },
  };

  const uiSchemaOrganization: UISchema = {};

  const tabs = [
    {
      id: "preferences",
      title: { [tenant.locale]: t("settings.general.title") },
      render: () => (
        <Form
          schema={schemaPreferences}
          uiSchema={uiSchemaPreferences}
          formData={{ lang: tenant.locale }}
          onSubmit={async (values: FixMeLater) => {
            tenant.changeLocale(values.lang);
          }}
          submitLabel={t("common.save")}
          language={tenant.locale}
          languages={currentTenant?.configuration.languages}
        />
      ),
      icon: null,
    },
    {
      id: "organization",
      title: { [tenant.locale]: t("settings.organization.title") },
      icon: null,
      render: () => {
        return (
          <>
            {tenant.organization && (
              <Form
                schema={schemaOrganization}
                uiSchema={uiSchemaOrganization}
                formData={{
                  name: tenant.organization.name,
                  description: tenant.organization.description,
                  phone: tenant.organization.contacts?.phone || "",
                  email: tenant.organization.contacts?.email || "",
                  address1: tenant.organization.contacts?.address1 || "",
                  address2: tenant.organization.contacts?.address2 || "",
                  website: tenant.organization.contacts?.website || "",
                }}
                onSubmit={async (values: FixMeLater) => {
                  const editedOrganization: Organization = {
                    uid: tenant.organization.uid,
                    name: values.name,
                    description: toJS(values.description),
                    contacts: {
                      phone: values.phone,
                      email: values.email,
                      address1: values.address1,
                      address2: values.address2,
                      website: values.website,
                    },
                  };
                  try {
                    tenant.updateOrganization(editedOrganization);
                    enqueueSnackbar(t("common.actions.updateCompleted"), {
                      variant: "success",
                    });
                  } catch (err) {
                    enqueueSnackbar(t("common.error"), {
                      variant: "error",
                    });
                  }
                }}
                submitLabel={t("common.save")}
                language={tenant.locale}
                languages={currentTenant?.configuration.languages}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <Container>
      <EntryHeader.Header bordered>
        <StyledTenantSettings.Title>
          {t("settings.title")}
        </StyledTenantSettings.Title>
      </EntryHeader.Header>
      <EntryContent>
        <Tabs tabs={tabs} orientation={"vertical"} language={tenant.locale} />
      </EntryContent>
    </Container>
  );
});
