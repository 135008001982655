/* @flow */
import React, { useCallback } from "react";

import Input from "../../components/Input";
import WidgetReadOnlyEmptyValue from "../../components/WidgetReadOnlyEmptyValue";

type Props = {|
  id: string,
  name?: string,
  onBlur?: (event: any) => any,
  onChange?: (event: any) => any,
  value?: string,
  type?: string,
  defaultValue?: string,
  autoFocus?: boolean,
  pattern?: string,
  readOnly?: boolean,
  isOnline?: boolean,
|};

const TelWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  type = "tel",
  defaultValue = "",
  autoFocus = false,
  pattern,
  readOnly = false,
  isOnline,
}: Props) => {
  const handleChange = useCallback(
    (event: any) => {
      if (readOnly) return;
      onChange && onChange(event);
    },
    [onChange, readOnly]
  );
  if (readOnly) {
    return <div>{value || defaultValue || <WidgetReadOnlyEmptyValue />}</div>;
  }
  return (
    <div>
      <Input
        type={type}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        value={value != null ? value : defaultValue}
        autoFocus={autoFocus}
        pattern={pattern}
        readOnly={readOnly || !isOnline}
      />
    </div>
  );
};

TelWidget.mapSchemaToProps = (schema: Object, uiSchema: Object) => {
  let props = {};
  props.type = uiSchema["ui:widget"] ? uiSchema["ui:widget"] : "tel";
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.pattern = schema.pattern || "";
  props.readOnly = schema.readOnly;
  return props;
};

export default TelWidget;
