import React from "react";
import { withTranslation } from "react-i18next";

import StyledEmptyData from "./styled";
import Icon from "../Icon";

import type { TFunction } from "react-i18next";

type ReasonType = "unauthorized" | "noData";

const reasonToIconType = {
  unauthorized: "alert",
  noData: "inbox",
};

type Props = {
  reason: ReasonType,
  t: TFunction,
};

const EmptyState = ({ reason, t }: Props) => (
  <StyledEmptyData.Container>
    <StyledEmptyData.IconWrapper>
      <Icon type={reasonToIconType[reason]} size={"xxlarge"} />
    </StyledEmptyData.IconWrapper>
    <div>{t(reason)}</div>
  </StyledEmptyData.Container>
);

export default withTranslation(["emptyState"])(EmptyState);
