import styled from "styled-components";

const StyledLabel = styled.label`
  position: relative;
  display: block;
  width: 100%;
  margin-top: ${(props) => props.theme.global.padding.xxsmall};
  color: ${(props) => props.theme.global.colors.status.error};
  font-size: 0.8em;
  line-height: 1.5em;
`;

export default StyledLabel;
