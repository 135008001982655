import React, { useCallback, useMemo, useState } from "react";
import Button from "../../components/Button";
import Icon, { DynamicIcon } from "../../components/Icon";
import navigationUtils from "../../utils/navigation";
import { Avatar } from "../../components/Avatar";
import Dropdown from "../../components/Dropdown";
import DropdownToggle from "../../components/DropdownToggle";
import DropdownMenu from "../../components/DropdownMenu";
import DropdownItem from "../../components/DropdownItem";
import StyledSidebar from "./styled";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/useStores";
import { useHistory } from "react-router-dom";
import { Tenant, DynamicIcon as TypeDynamicIcon } from "../../types";
import { ContentType } from "../../models/ContentType";

type Props = {};

export const Sidebar: React.FC<Props> = observer(({}) => {
  const { t } = useTranslation();
  const { auth, app, tenant } = useStores();
  const history = useHistory();
  const location = useLocation();

  const currentTenantId: Tenant["uid"] | null = useMemo(() => {
    return navigationUtils.fromRoutes.tenantId(location.pathname);
  }, [location]);

  const currentTenant: Tenant | null = useMemo(() => {
    return currentTenantId
      ? tenant.tenants.find((tenant) => tenant.uid === currentTenantId) || null
      : null;
  }, [currentTenantId, tenant]);

  const currentUserName = useMemo(() => {
    return auth.user
      ? auth.user?.nominative
        ? `${auth.user.nominative}`
        : auth.user?.email
      : "";
  }, [auth.user]);

  let currentUserRole = "TODO Role";
  // if (selectedTenantId) {
  //   const tenant = getTenant(selectedTenantId);
  //   currentUserRole = tenant ? tenant.role.label : "";
  // }

  const currentContentId: ContentType["uid"] | null = useMemo(() => {
    return navigationUtils.fromRoutes.contentTypeId(location.pathname);
  }, [location]);

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleSelectContentTypeId = useCallback(
    (contentTypeId: ContentType["uid"] | null) => {
      setMenuOpen(false);
      if (currentTenantId != null && contentTypeId != null) {
        history.push(
          navigationUtils.routes.entry.list(currentTenantId, contentTypeId)
        );
      } else {
        history.push(navigationUtils.routes.tenant.list());
      }
    },
    [location, history, setMenuOpen, currentTenantId]
  );

  const handleGoToProfile = useCallback(() => {
    history.push(navigationUtils.routes.user.profile());
  }, [history]);

  const handleToggleMenu = useCallback(() => {
    setMenuOpen((isMenuOpen) => !isMenuOpen);
  }, [setMenuOpen]);

  const handleLogoutClick = useCallback(() => {
    setMenuOpen(false);
    auth.logout();
  }, [auth, setMenuOpen]);

  const handleCollapseSidebar = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, setIsCollapsed]);

  return (
    <>
      <StyledSidebar.MobileHeader>
        <StyledSidebar.NavToggler>
          <Button
            model={"secondary"}
            icon={isMenuOpen ? "close" : "menu"}
            onClick={handleToggleMenu}
          />
        </StyledSidebar.NavToggler>
        <StyledSidebar.NavLogo to={"/"}>
          <img src={app.logo} alt={app.title} />
        </StyledSidebar.NavLogo>
      </StyledSidebar.MobileHeader>
      <StyledSidebar.Container isOpen={isMenuOpen} isCollapsed={isCollapsed}>
        <StyledSidebar.BrandContainer>
          <Link
            to={
              currentTenantId
                ? navigationUtils.routes.contentType.list(currentTenantId)
                : navigationUtils.routes.tenant.list()
            }
          >
            <StyledSidebar.Brand>
              <img src={app.logo} alt={app.title} />
            </StyledSidebar.Brand>
          </Link>
          <h5>{app.title}</h5>
        </StyledSidebar.BrandContainer>
        <StyledSidebar.CollapseSidebarButton onClick={handleCollapseSidebar}>
          <Icon
            type={isCollapsed ? "angleRight" : "angleLeft"}
            size={"xsmall"}
          />
        </StyledSidebar.CollapseSidebarButton>
        <StyledSidebar.ScrollableNavWrapper>
          {currentTenant &&
            currentTenant.contentTypes.map((contentType) => (
              <StyledSidebar.NavItem
                className={
                  String(currentContentId) === String(contentType.uid)
                    ? "active"
                    : ""
                }
                key={contentType.uid}
                onClick={() => handleSelectContentTypeId(contentType.uid)}
              >
                <StyledSidebar.NavItemIcon>
                  {contentType.uiSchema["ui:icon"] ? (
                    <DynamicIcon
                      lib={
                        (contentType.uiSchema["ui:icon"] as TypeDynamicIcon)
                          .library
                      }
                      iconName={
                        (contentType.uiSchema["ui:icon"] as TypeDynamicIcon)
                          .name
                      }
                    />
                  ) : (
                    <Icon type={"thLarge"} />
                  )}
                </StyledSidebar.NavItemIcon>
                <StyledSidebar.NavItemText>
                  {contentType.label[tenant.locale]}
                </StyledSidebar.NavItemText>
              </StyledSidebar.NavItem>
            ))}
        </StyledSidebar.ScrollableNavWrapper>
        <StyledSidebar.NavWrapper>
          {currentTenantId && (
            <StyledSidebar.NavItem
              className={""}
              onClick={() =>
                history.push(
                  navigationUtils.routes.tenant.settings(currentTenantId)
                )
              }
            >
              <StyledSidebar.NavItemIcon>
                <DynamicIcon lib={"ai"} iconName={"AiOutlineSetting"} />
              </StyledSidebar.NavItemIcon>
              <StyledSidebar.NavItemText>
                {t("settings.title")}
              </StyledSidebar.NavItemText>
            </StyledSidebar.NavItem>
          )}

          {(tenant.isMultiProducts || true) && (
            <StyledSidebar.NavItem
              className={!currentTenantId ? "active" : ""}
              onClick={() => handleSelectContentTypeId(null)}
            >
              <StyledSidebar.NavItemIcon>
                <Icon type={"apps"} />
              </StyledSidebar.NavItemIcon>
              <StyledSidebar.NavItemText>
                {t("common.tenants")}
              </StyledSidebar.NavItemText>
            </StyledSidebar.NavItem>
          )}

          <Dropdown drop={"right"} alignRight>
            <DropdownToggle variant={"success"} id={"sidebar-user-dropdown"}>
              {/* @ts-ignore */}
              <StyledSidebar.NavItem>
                <StyledSidebar.NavItemIcon>
                  <Avatar user={currentUserName.split(" ")} />
                </StyledSidebar.NavItemIcon>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden !important",
                  }}
                >
                  <StyledSidebar.NavItemText>
                    {currentUserName}
                  </StyledSidebar.NavItemText>
                  {/* TODO:  <StyledSidebar.NavItemText
                    style={{ fontSize: "0.75rem", marginTop: "3px" }}
                  >
                    {currentUserRole}
                  </StyledSidebar.NavItemText> */}
                </div>
              </StyledSidebar.NavItem>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={handleGoToProfile}>
                {t("common.profile")}
              </DropdownItem>
              <DropdownItem onClick={handleLogoutClick}>
                {t("auth.logout")}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          {/* Mobile additional items */}

          <StyledSidebar.MobileNavItem>
            <StyledSidebar.NavItemIcon>
              <Avatar user={currentUserName.split(" ")} />
            </StyledSidebar.NavItemIcon>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{currentUserName}</span>
              {/* TODO: <span style={{ fontSize: "0.75rem", marginTop: "3px" }}>
                {currentUserRole}
              </span> */}
            </div>
          </StyledSidebar.MobileNavItem>
          <StyledSidebar.MobileNavItem onClick={handleGoToProfile}>
            <StyledSidebar.NavItemIcon>
              <Icon type={"user"} />
            </StyledSidebar.NavItemIcon>
            {t("common.profile")}
          </StyledSidebar.MobileNavItem>
          <StyledSidebar.MobileNavItem onClick={handleLogoutClick}>
            <StyledSidebar.NavItemIcon>
              <Icon type={"logout"} />
            </StyledSidebar.NavItemIcon>
            {t("auth.logout")}
          </StyledSidebar.MobileNavItem>
        </StyledSidebar.NavWrapper>
      </StyledSidebar.Container>
    </>
  );
});
