import React, { useState, useCallback, useRef } from "react";
import Icon from "../Icon";
import StyledTabs from "./styled";
import type { Locale, Tab as TabType } from "../../types";
import { useClickOutside } from "../../utils/hooks/useClickOutside";

type Props = {
  tabs: TabType[];
  onChange?: (tabId: string) => any;
  language: Locale;
};

const TabsDropdown = ({ tabs, onChange, language }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(() =>
    tabs.length > 0 ? tabs[0] : null
  );
  const handleToggleDropdown = useCallback(() => setOpen((isOpen) => !isOpen), [
    setOpen,
  ]);
  const handleSelectItem = useCallback(
    (selectedTab) => {
      setSelectedTab(selectedTab);
      setOpen(false);
      onChange && onChange(selectedTab.id);
    },
    [setSelectedTab, setOpen, onChange]
  );
  const ref = useRef();
  useClickOutside(ref, () => setOpen(false));

  return (
    <StyledTabs.TabsDropdownWrapper ref={ref}>
      {selectedTab && (
        <StyledTabs.TabsDropdownHeader onClick={handleToggleDropdown}>
          <StyledTabs.HeaderTab key={selectedTab.id}>
            <StyledTabs.IconContainer>
              {selectedTab.icon && (
                <Icon type={selectedTab.icon} size={"small"} />
              )}
            </StyledTabs.IconContainer>
            <span>{selectedTab.title[language]}</span>
          </StyledTabs.HeaderTab>
          <Icon type={isOpen ? "angleUp" : "angleDown"} size={"small"} />
        </StyledTabs.TabsDropdownHeader>
      )}
      {isOpen && (
        <StyledTabs.TabsDropdownList onClick={(e) => e.stopPropagation()}>
          {tabs.map((tab) => (
            <StyledTabs.TabsDropdownListItem
              key={tab.id}
              onClick={() => handleSelectItem(tab)}
              selected={selectedTab && selectedTab.id === tab.id}
            >
              <StyledTabs.IconContainer>
                {tab.icon && <Icon type={tab.icon} size={"small"} />}
              </StyledTabs.IconContainer>
              <span>{tab.title}</span>
            </StyledTabs.TabsDropdownListItem>
          ))}
        </StyledTabs.TabsDropdownList>
      )}
    </StyledTabs.TabsDropdownWrapper>
  );
};

export default TabsDropdown;
