import {
  isObject,
  isPlainObject,
  forEach,
  transform,
  isEqual,
  isArray,
} from "lodash-es";

export const deepFreeze = (obj: Object) => {
  Object.freeze(obj);
  Object.keys(obj).forEach(
    (key) =>
      key &&
      isObject(obj[key]) &&
      !Object.isFrozen(obj[key]) &&
      deepFreeze(obj[key])
  );
  return obj;
};

export const jsonToFormData = (data: Object): FormData => {
  const buildFormData = (formData: FormData, data: any, parentKey?: string) => {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else if (parentKey) {
      const value = data == null ? "" : data;
      formData.append(parentKey, value);
    }
  };
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};

export function plainToFlattenObject(data: Object) {
  const result = {};
  function flatten(obj, prefix = "") {
    forEach(obj, (value, key) => {
      if (isPlainObject(value)) {
        flatten(value, `${prefix}${key}.`);
      } else {
        result[`${prefix}${key}`] = value;
      }
    });
  }
  flatten(data);
  return result;
}

// https://gist.github.com/Yimiprod/7ee176597fef230d1451#gistcomment-2565071
export const difference = (object: Object, base: Object) => {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      // @ts-ignore
      result[key] =
        isObject(value) && !isArray(value) && isObject(base[key])
          ? difference(value, base[key])
          : value;
    }
  });
};
