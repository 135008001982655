// const openInNewTab = (
//   { location, history }: { location: RouterLocation, history: RouterHistory },
//   destination: string
// ) => {
//   const newTab = window.open(destination, "_blank");
//   newTab?.focus();
// };

const reloadCurrentPage = () => {
  window && window.location.reload();
};

const routesUtils = {
  newId: "new",
};
const fromRoutes = {
  tenantId: (pathname: string): string | null => {
    const tenantRegex = /\/tenants\/([^\/?#]+)/i;
    const found = pathname.match(tenantRegex);
    return (found && found[1]) || null;
  },
  contentTypeId: (pathname: string): string | null => {
    const contentTypeRegex = /\/content_types\/([^\/?#]+)/i;
    const found = pathname.match(contentTypeRegex);
    return (found && found[1]) || null;
  },
  entryId: (pathname: string): string | null => {
    const entryRegex = /\/entries\/([^\/?#]+)/i;
    const found = pathname.match(entryRegex);
    return (found && found[1]) || null;
  },
};
const routes = {
  auth: {
    login: () => "/auth/login",
    signup: () => "/auth/signup",
    recover: () => "/auth/change-password",
    lostPassword: () => "/auth/lost-password",
  },
  cms: {
    main: () => "/cms",
  },
  contentType: {
    listRoute: () => `/cms/tenants/:tenantId/content_types`,
    list: (tenantId: string | number) =>
      `/cms/tenants/${tenantId}/content_types`,
  },
  entry: {
    listRoute: "/cms/tenants/:tenantId/content_types/:contentTypeId/entries",
    createRoute:
      "/cms/tenants/:tenantId/content_types/:contentTypeId/entries/new",
    findRoute:
      "/cms/tenants/:tenantId/content_types/:contentTypeId/entries/:entryId",
    list: (tenantId: string | number, contentTypeId: string | number) =>
      `/cms/tenants/${tenantId}/content_types/${contentTypeId}/entries`,
    find: (
      tenantId: string | number,
      contentTypeId: string | number,
      entryId: string | number
    ) =>
      `/cms/tenants/${tenantId}/content_types/${contentTypeId}/entries/${entryId}`,
    create: (tenantId: string | number, contentTypeId: string | number) =>
      `/cms/tenants/${tenantId}/content_types/${contentTypeId}/entries/${routesUtils.newId}`,
  },
  notFound: {
    main: () => "/404",
  },
  tenant: {
    settingsRoute: `/cms/tenants/:tenantId/settings`,
    list: () => `/cms/tenants`,
    settings: (tenantId: string | number) =>
      `/cms/tenants/${tenantId}/settings`,
  },
  user: {
    invite: (tenantId: string | number) => `/tenants/${tenantId}/users/invite`,
    profile: () => `/cms/profile`,
  },
};

export default {
  reloadCurrentPage,
  routes,
  fromRoutes,
  routesUtils,
};
