import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = Object.assign({}, process.env, runtimeEnv());

const IS_DEV = env.NODE_ENV !== "production";
const BACKEND_DOMAIN_URL =
  env.REACT_APP_BACKEND_URL || "http://localhost:4000/";
const BACKEND_PWA_URL =
  env.REACT_APP_BACKEND_PWA_URL || `${BACKEND_DOMAIN_URL}pwa/`;
const BACKEND_API_URL =
  env.REACT_APP_BACKEND_API_URL || `${BACKEND_DOMAIN_URL}api/v2/`;
const LOGS_RETENTION_MILLISECONDS =
  Number(env.REACT_APP_LOGS_RETENTION_MILLISECONDS) || 5000;
const PRESIGN_FILE_S3_URL =
  env.REACT_APP_PRESIGN_FILE_S3_URL || BACKEND_DOMAIN_URL;
const STAZA_BI_ENDPOINT_URL =
  env.REACT_APP_STAZA_BI_ENDPOINT_URL || "http://localhost:4000/bi/v1";

export default {
  IS_DEV,
  BACKEND_PWA_URL,
  BACKEND_API_URL,
  LOGS_RETENTION_MILLISECONDS,
  PRESIGN_FILE_S3_URL,
  STAZA_BI_ENDPOINT_URL,
};
