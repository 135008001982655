import React from "react";

import StyledSpinner from "./styled";

type Props = {
  isVisible?: boolean,
  size?: number,
  type?: "spin" | "pulse",
  relativeStyle?: boolean,
};

export const Spinner = (props: Props) => {
  const {
    isVisible = true,
    size = 25,
    type = "pulse",
    relativeStyle = false,
  } = props;
  const SelectedSpinner =
    type === "spin" ? StyledSpinner.Spin : StyledSpinner.Pulse;
  return (
    <SelectedSpinner
      size={size}
      //isVisible={isVisible}
      relativeStyle={relativeStyle}
    />
  );
};

