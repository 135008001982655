/* @flow */
import styled, { css } from "styled-components";
import { readableColor } from "polished";

const Label = styled.label`
  display: ${(props) => (!!props.inline ? "inline-flex" : "flex")};
  margin-right: ${(props) => props.theme.global.padding.medium};
  margin-bottom: ${(props) => props.theme.global.padding.xsmall};
  &:last-child {
    margin-right: 0;
  }
  align-items: center;
  position: relative;
  box-sizing: border-box;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "pointer")};
  color: ${(props) => props.theme.global.colors.text.light};
`;

const optionBaseStyle = css`
  position: relative;
  margin-left: ${(props) => props.theme.global.padding.xsmall};
  border-radius: 2px;
`;

const OptionText = styled.span`
  ${optionBaseStyle}
  color: ${(props) => props.theme.global.colors.text.light};
`;

const OptionColor = styled.span`
  ${optionBaseStyle}
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 15em;
  max-width: 100%;
  min-height: 2em;
  background: ${(props) => props.color};
  color: ${(props) => readableColor(props.color)};
  padding: 0.5em;
  & > * {
    margin-right: 0.5em;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Box = styled.span`
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  max-height: 1.15em;
  max-width: 1.15em;
  min-height: 1.15em;
  min-width: 1.15em;
  transition: opacity ${(props) => props.theme.global.animation.fade.duration}
    ease;
  transition: border-color
    ${(props) => props.theme.global.animation.fade.duration} ease;
  transition: background
    ${(props) => props.theme.global.animation.fade.duration} ease;
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.theme.global.colors.brand
        : props.theme.global.colors.border.normal};
  background: ${(props) =>
    props.checked ? props.theme.global.colors.brand : "initial"};
  border-radius: 2px;
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.global.colors.black};
  }
  &::-moz-focus-inner {
    border: none;
  }
  &::-moz-focus-outer {
    border: none;
  }
  &:after {
    display: ${(props) => (props.checked ? "block" : "none")};
    content: "";
    position: absolute;
    left: 20%;
    top: 20%;
    width: 60%;
    height: 60%;
    background: white;
    border-radius: 50%;
    box-shadow: ${(props) => props.theme.global.elevation.small};
  }
  &:hover {
    opacity: 0.8;
  }
`;

const Input = styled.input`
  display: none;
  box-sizing: border-box;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  -webkit-appearance: none;
`;

export default {
  Label,
  Input,
  Box,
  OptionText,
  OptionColor,
};
