/* https://github.com/react-bootstrap/react-bootstrap/blob/master/src/SelectableContext.js */
import React from "react";

type SelectableContextType = (key: string | null, event: any) => void;

const SelectableContext = React.createContext<SelectableContextType | null>(
  null,
);

export const makeEventKey = (
  eventKey: string | null,
  href: string | null = null,
): string | null => {
  if (eventKey != null) return String(eventKey);
  return href || null;
};

export default SelectableContext;
