/* @flow */
import React, { useCallback } from "react";
import { readableColor } from "polished";

import StyledRadio from "./styled";
import Icon from "../../components/Icon";
import WidgetReadOnlyEmptyValue from "../../components/WidgetReadOnlyEmptyValue";

type RadioOptionValue = string | boolean | number;
type RadioOptionLabel = string;
type RadioOption = {
  value: RadioOptionValue,
  label: RadioOptionLabel,
};

type Props = {|
  id: string,
  name: string,
  onBlur?: (event: any) => any,
  onChange?: (value: any) => any,
  value?: RadioOptionValue,
  defaultValue?: RadioOptionValue,
  options?: RadioOption[],
  inline?: boolean,
  appearance?: "text" | "color",
  readOnly?: boolean,
  isOnline?: boolean,
|};

const RadioWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue,
  options = [],
  inline = false,
  appearance = "text",
  readOnly = false,
  isOnline,
}: Props) => {
  const handleChange = useCallback(
    (event) => {
      if (readOnly) return;
      onChange && onChange(event);
    },
    [onChange, readOnly]
  );
  const checkedValue = value != null ? value : defaultValue;

  if (readOnly) {
    return <div>{checkedValue || <WidgetReadOnlyEmptyValue />}</div>;
  }

  return (
    <fieldset id={id}>
      {options.map((option, index) => (
        <StyledRadio.Label
          htmlFor={`${id}${index}`}
          key={`${id}${index}`}
          inline={inline}
          readOnly={readOnly}
        >
          <StyledRadio.Input
            type={"radio"}
            id={`${id}${index}`}
            name={`${id}`}
            onBlur={onBlur}
            onChange={handleChange}
            checked={option.value === checkedValue}
            value={option.value}
            readOnly={readOnly || !isOnline}
          />
          {appearance !== "color" && (
            <StyledRadio.Box checked={option.value === checkedValue} />
          )}
          {appearance === "color" ? (
            <StyledRadio.OptionColor color={option.value}>
              <p>{option.label}</p>
              <Icon
                type={"check"}
                color={
                  option.value === checkedValue
                    ? readableColor(String(option.value))
                    : "transparent"
                }
                size={"small"}
              />
            </StyledRadio.OptionColor>
          ) : (
            <StyledRadio.OptionText>{option.label}</StyledRadio.OptionText>
          )}
        </StyledRadio.Label>
      ))}
    </fieldset>
  );
};

RadioWidget.mapSchemaToProps = (schema: Object, uiSchema: Object) => {
  let props = {};
  const uiOptions = uiSchema["ui:options"] || {};
  const appearance = uiSchema["ui:widget"];
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.readOnly = schema.readOnly;
  props.inline = !!uiOptions.inline;
  if (schema.type === "boolean") {
    props.options = [
      { value: true, label: "Sì" },
      { value: false, label: "No" },
    ];
  }
  if (schema.enum != null) {
    props.options = schema.enum.map((optionValue, index) => ({
      value: optionValue,
      label:
        schema.enumNames != null && index < schema.enumNames.length
          ? String(schema.enumNames[index])
          : appearance === "radio-color"
          ? ""
          : String(optionValue),
    }));
  }
  if (appearance === "radio-color") {
    props.appearance = "color";
  }
  return props;
};

export default RadioWidget;
