/* @flow */
import styled from "styled-components";

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #e35149;
  align-items: center;
  justify-content: center;
`;

const StyledDocumentLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.global.colors.text.dark};

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: ${(props) => props.theme.global.colors.text.dark};
  }

  span {
    margin-left: ${(props) => props.theme.global.padding.xsmall};
  }

  svg {
    fill: ${(props) => props.theme.global.colors.text.dark};
  }
`;

export default {
  Container: StyledContainer,
  Link: StyledDocumentLink,
};
