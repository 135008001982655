/* @flow */
import styled, { css } from "styled-components";

const rangeTrackStyle = css`
  box-sizing: border-box;
  width: 100%;
  height: ${(props) => props.theme.global.border.size.small};
  background: ${(props) => props.theme.global.colors.border.light};
`;

const rangeThumbStyle = css`
  box-sizing: border-box;
  position: relative;
  border-radius: ${(props) => props.theme.global.spacing};
  height: ${(props) => props.theme.global.spacing};
  width: ${(props) => props.theme.global.spacing};
  overflow: visible;
  background: ${(props) => props.theme.global.colors.brand};
  -webkit-appearance: none;
  cursor: pointer;
`;

const firefoxMicrosoftThumbStyle = css`
  ${rangeThumbStyle} margin-top: 0px;
  height: ${(props) => props.theme.global.spacing};
  width: ${(props) => props.theme.global.spacing};
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  position: relative;
  -webkit-appearance: none;
  border-color: transparent;
  height: ${(props) => props.theme.global.spacing};
  width: 100%;
  padding: 0px;
  cursor: pointer;
  background: transparent;
  &:focus {
    outline: none;
  }
  &::-moz-focus-inner {
    border: none;
  }
  &::-moz-focus-outer {
    border: none;
  }
  &::-webkit-slider-runnable-track {
    ${rangeTrackStyle}
  }
  &::-webkit-slider-thumb {
    margin-top: calc(
      (
          ${(props) =>
            `${props.theme.global.spacing} - ${props.theme.global.border.size.small}`}
        ) * -0.5
    );
    ${rangeThumbStyle}
    margin-top: -10px;
    ${(props) =>
      !props.disabled &&
      css`
        &:hover {
          box-shadow: 0px 0px 0px 2px ${props.theme.global.colors.brand};
        }
      `}
  }
  &::-moz-range-track {
    ${rangeTrackStyle}
  }
  &::-moz-range-thumb {
    ${firefoxMicrosoftThumbStyle}
  }
  &::-ms-thumb {
    ${firefoxMicrosoftThumbStyle}
  }
  ${(props) =>
    !props.disabled &&
    css`
      &:hover::-moz-range-thumb {
        box-shadow: 0px 0px 0px 2px ${props.theme.global.colors.brand};
      }
      &:hover::-ms-thumb {
        box-shadow: 0px 0px 0px 2px ${props.theme.global.colors.brand};
      }
    `}
  &::-ms-track {
    ${rangeTrackStyle}
    border-color: transparent;
    color: transparent;
  }
  &::-ms-fill-lower {
    background: ${(props) => props.theme.global.colors.border.light};
    border-color: transparent;
  }
  &::-ms-fill-upper {
    background: ${(props) => props.theme.global.colors.border.light};
    border-color: transparent;
  }
`;

export default StyledInput;
