import { MouseEventHandler, useCallback } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { ImageMapMarker as TImageMapMarker } from "../../types";
import { StyledMarkerContainer } from "./styled";

type Props = {
  item: TImageMapMarker;
  value: string;
  tileSize: [number, number];
  onDrag: (item: TImageMapMarker, data: DraggableData) => any;
  onClick: (item: TImageMapMarker) => any;
};

export const ImageMapMarker: React.FC<Props> = ({
  item,
  value,
  tileSize,
  onDrag,
  onClick,
}) => {
  const handleDrag = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      e.stopPropagation();
      onDrag(item, data);
    },
    [item, onDrag]
  );

  const handleClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      onClick(item);
    },
    [item, onClick]
  );

  return (
    <Draggable
      onDrag={handleDrag}
      position={{ x: item.center[0], y: item.center[1] }}
      grid={[5, 5]}
    >
      <StyledMarkerContainer
        onClick={() => null}
        height={tileSize[1]}
        width={tileSize[0]}
      >
        <div style={{ cursor: "pointer" }} onClick={handleClick}>
          {value}
        </div>
      </StyledMarkerContainer>
    </Draggable>
  );
};
