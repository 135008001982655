export const formatColumnHeader = (columnKey: string): string => {
  return (
    columnKey
      .split(".")
      .pop()
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};
