import * as React from "react";
import { Redirect, Route } from "react-router-dom";

import navigationUtils from "../../utils/navigation";

type PrivateRouteProps = {
  component: React.ComponentType<any>,
  isAuthenticated: boolean,
  path: string,
};

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...otherProps
}: PrivateRouteProps) => {
  return (
    <Route
      {...otherProps}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} {...otherProps} />
        ) : (
          <Redirect
            to={{
              pathname: navigationUtils.routes.auth.login(),
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
