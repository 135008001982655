import React from "react";

import StyledLabel from "./styled";

type Props = {
  title?: string;
  description?: string;
  required?: boolean;
  htmlFor?: string;
};

const FieldTitleLabel = ({
  title = "",
  description = "",
  required = false,
  htmlFor = "",
}: Props) => {
  return (
    <StyledLabel htmlFor={htmlFor}>{`${title}${
      required ? " *" : ""
    }`}</StyledLabel>
  );
};

export default FieldTitleLabel;
