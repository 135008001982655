import { makeAutoObservable } from "mobx";
import backendService from "../services/backend";
import i18n from "../services/i18n";

import type { Locale, Organization, Stores, Tenant } from "../types";

export default class TenantStore {
  stores: Stores;

  isInitialized: boolean = false;
  tenants: Tenant[] = [];
  isFetching: boolean = false;
  locale: Locale = i18n.language as Locale;
  organization: Organization | null = null;

  isFetchingOrganization: boolean = false;

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  initialize = async (): Promise<void> => {
    try {
      const locale = localStorage.getItem("i18nextLng").toString() as Locale;

      if (locale) {
        this.locale = locale;
      }
    } catch (err) {
      this.stores.log.warning(`Errore nella inizializzazione TenantStore`, err);
    }
    this.isInitialized = true;
  };

  // resetStores = () => {
  //   this.appStore.destroy();
  //   this.contentTypeStore.destroy();
  //   this.entryStore.destroy();
  // };

  destroy = async (): Promise<void> => {
    this.tenants = [];
    this.isFetching = false;
  };

  fetchTenants = async (): Promise<void> => {
    this.isFetching = true;
    let tenants: Tenant[] = [];
    try {
      tenants = await backendService.getTenants();
    } catch (err) {
      console.log(err);
      // this.stores.log.warning(
      //   `Errore nel caricamento tipologia dati, assicurarsi di essere connessi a internet`,
      //   err
      // );
      throw err;
    } finally {
      this.tenants = tenants;
      this.isFetching = false;
    }
  };

  getTenant = (tenantNameOrId: Tenant["uid"]): Tenant | null => {
    return this.tenants.find((tenant) => tenant.uid === tenantNameOrId) || null;
  };

  get isLoading(): boolean {
    return this.isFetching;
  }

  get isMultiProducts(): boolean {
    return this.tenants.length > 1;
  }

  changeLocale = (locale: Locale) => {
    i18n.changeLanguage(locale);
    this.locale = locale;
    localStorage.setItem("i18nextLng", locale);
  };

  fetchOrganization = async (organizationId: Organization["uid"]) => {
    this.isFetchingOrganization = true;
    try {
      this.organization = await backendService.getOrganization(organizationId);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetchingOrganization = false;
    }
  };

  updateOrganization = async (organization: Organization) => {
    this.isFetchingOrganization = true;
    try {
      this.organization = await backendService.updateOrganization(
        this.organization.uid,
        organization
      );
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isFetchingOrganization = false;
    }
  };
}
