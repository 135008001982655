import styled, { css } from "styled-components";
import {
  meetsContrastGuidelines,
  invert,
  darken,
  lighten,
  getLuminance,
} from "polished";

import StyledSpinner from "../Spinner/styled";

const defaultButtonStyle = css`
  line-height: ${(props) => props.theme.global.typography.lineHeight.button};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(props) => props.theme.global.typography.text.fontWeight};
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 0px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  touch-action: manipulation;
  min-height: 42px;
  min-width: 64px;
  padding: 0 ${(props) => props.theme.global.padding.small};
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.65);
  background: transparent;

  ${(props) => props.block && "width: 100%;"};

  svg {
    margin-right: ${(props) => props.theme.global.padding.xsmall};
  }

  &,
  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    text-decoration: none;
  }
`;

const StyledPrimaryButton = styled.button`
  ${defaultButtonStyle}

  background-color: ${(props) => props.theme.global.colors.brand};

  ${(props) => {
    const { AA } = meetsContrastGuidelines(
      props.theme.global.colors.brand,
      props.theme.global.colors.secondaryColor
    );

    const color = AA
      ? props.theme.global.colors.secondaryColor
      : props.theme.global.colors.white;
    // : invert(props.theme.global.colors.secondaryColor);

    return `
      color: ${color};
      
      svg {
        fill: ${color};
      }

      ${StyledSpinner.Spin} {
        border-left-color: ${color};
        border-right-color: ${color};
        border-bottom-color: ${color};
      }
    `;
  }}

  &:hover,
  &:focus {
    background-color: ${(props) =>
      getLuminance(props.theme.global.colors.brand) > 0.5
        ? darken(0.07, props.theme.global.colors.brand)
        : lighten(0.07, props.theme.global.colors.brand)};
  }

  &:active {
    background-color: ${(props) =>
      getLuminance(props.theme.global.colors.brand) > 0.5
        ? darken(0.12, props.theme.global.colors.brand)
        : lighten(0.12, props.theme.global.colors.brand)};
  }
`;

/*const StyledPrimaryButton1 = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  text-transform: uppercase;
  font-weight: bold;
  color: ${props => props.theme.global.colors.text.dark};
  padding: ${props => props.theme.global.padding.xsmall};
  border: ${props => props.theme.global.border.size.xsmall} solid
    ${props => props.theme.global.colors.border.dark};
  border-radius: ${props => props.theme.global.border.size.medium};
  box-shadow: ${props => props.theme.global.elevation.light.small};
  background: ${props => props.theme.global.colors.brand};
  cursor: pointer;
  &:hover:not([disabled]) {
    border-color: ${props => props.theme.global.colors.brand};
  }
  &:focus:not([disabled]) {
    outline: none;
    border-color: ${props => props.theme.global.colors.border.light};
  }
  &::-moz-focus-inner,
  &::-moz-focus-outer {
    border: none;
  }

  width: ${props => (props.block ? "100%" : "auto")};

  &[disabled] {
    opacity: 0.75;
  }

  ${StyledSpinner.Spin} {
    border-left-color: ${props => props.theme.global.colors.text.dark};
    border-right-color: ${props => props.theme.global.colors.text.dark};
    border-bottom-color: ${props => props.theme.global.colors.text.dark};
  }
`;*/

const StyledSecondaryButton = styled.button`
  display: flex;
  align-items: center;
  position: relative;

  box-shadow: none;
  background-color: transparent;
  border: none;
  border-radius: 2px;

  font-size: ${(props) => (props.size === "large" ? "2em" : "1em")};

  text-transform: uppercase;
  color: ${(props) => props.theme.global.colors.text.light};
  cursor: pointer;

  &:hover:not([disabled]) {
    box-shadow: none;
  }
  &:focus:not([disabled]) {
    outline: none;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
  }
  &::-moz-focus-inner,
  &::-moz-focus-outer {
    border: none;
  }

  & > * {
    vertical-align: middle;
  }
  &[disabled] {
    opacity: 0.75;
  }
`;

export default {
  PrimaryButton: StyledPrimaryButton,
  SecondaryButton: StyledSecondaryButton,
};
