import { Ability } from "@casl/ability";
import { useLocation } from "react-router";
import { useStores } from "./useStores";
import navigationUtils from "../utils/navigation";

export const useUserAbilities = (): Ability | null => {
  const location = useLocation();
  const { tenant } = useStores();

  const tenantId = navigationUtils.fromRoutes.tenantId(location.pathname);
  const currentTenant = tenant.tenants.find(
    (tenant) => tenant.uid === tenantId
  );
  return currentTenant?.role.acls || null;
};
