import { makeAutoObservable } from "mobx";

import backendService from "../services/backend";
import storageService from "../services/storage";

import type { Stores, User } from "../types";

export default class AuthStore {
  stores: Stores;
  isInitialized: boolean = false;
  user: User | null = null;
  isLoggingIn: boolean = false;
  isLoggingOut: boolean = false;
  isUpdatingUserProfile: boolean = false;

  sessionToken: string = "";

  get isLoggedIn(): boolean {
    return this.user !== null;
  }

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  initialize = async () => {
    try {
      this.user = await backendService.getCurrentUser();
      this.sessionToken = (await storageService.getItem(
        "sessionToken"
      )) as string;
    } catch (err) {
      this.stores.log.warning(`Errore nella inizializzazione AuthStore`, err);
    } finally {
      this.isInitialized = true;
    }
  };

  destroy = async (): Promise<void> => {
    await Promise.all([
      this.stores.log.destroy(),
      // this.contentTypeStore.destroy(),
      // this.entryStore.destroy(),
      this.stores.app.destroy(),
      // this.tenantStore.destroy(),
    ]);
    this.user = null;
    this.isLoggingIn = false;
    this.isLoggingOut = false;
  };

  login = async (email: string, password: string) => {
    this.isLoggingIn = true;
    try {
      await backendService.login(email, password);
      await this.initialize();
    } catch (err) {
      console.log(err);
      throw err;
      //this.stores.log.warning(`Errore login`, err);
    } finally {
      this.isLoggingIn = false;
    }
  };

  logout = async () => {
    this.isLoggingOut = true;
    try {
      await backendService.logout();
    } catch (err) {
      this.stores.log.warning(`Errore logout`, err);
    }
    await this.destroy();
    this.isLoggingOut = false;
  };

  updateUserProfile = async (userData: User) => {
    this.isUpdatingUserProfile = true;

    try {
      await backendService.updateUserProfile(userData);
      this.user = userData;
      this.stores.log.success("Profilo aggiornato correttamente", null);
    } catch (err) {
      this.stores.log.warning(`Errore logout`, err);
    }

    this.isUpdatingUserProfile = false;
  };

  forgotPassword = async (email: string) => {
    try {
      await backendService.forgotPassword(email);
      // this.stores.log.success(
      //   "Richiesta eseguita con successo. Controlla la tua casella di posta.",
      //   null
      // );
    } catch (err) {
      console.log(err);
      throw err;
      // this.stores.log.warning(
      //   `Errore durante l'esecuzione della richiesta.`,
      //   err
      // );
    }
  };

  resetPassword = async (email: string, password: string, token: string) => {
    try {
      await backendService.resetPassword(email, password, token);
    } catch (err) {
      console.log(err);
      throw err;
      // this.stores.log.warning(
      //   `Errore durante l'esecuzione della richiesta.`,
      //   err
      // );
    }
  };
}
