import React from "react";

import StyledEntryContent from "./styled";

type Props = {
  children?: any,
};

const EntryContent = ({ children }: Props) => (
  <StyledEntryContent>{children}</StyledEntryContent>
);
export default EntryContent;
