import React from "react";
import styled from "styled-components";
import {
  IoMdCheckmark as SuccessIcon,
  IoMdWarning as WarningIcon,
  IoMdAlert as ErrorIcon,
  IoMdInformationCircle as InfoIcon,
} from "react-icons/io";
import type { AlertSeverity } from "./index";
import { FixMeLater } from "../../types";

const getColorFromStatus = (status?: AlertSeverity) => {
  return status === "success"
    ? "rgb(32, 183, 68)"
    : status === "warning"
    ? "rgb(241, 132, 0)"
    : status === "error"
    ? "rgb(210, 80, 80)"
    : "rgb(0, 100, 224)";
};

const getIconFromStatus = (status?: AlertSeverity) => {
  return status === "success"
    ? SuccessIcon
    : status === "warning"
    ? WarningIcon
    : status === "error"
    ? ErrorIcon
    : InfoIcon;
};

const AlertWrapper = styled.div<{ severity: FixMeLater }>`
  box-sizing: border-box;
  padding: ${(props) => props.theme.global.padding.small};
  margin: 0.5em;
  border-radius: ${(props) => props.theme.global.border.radius.small};
  background: ${(props) => props.theme.global.colors.white};
  width: 24rem;
  max-width: calc(100vw - 32px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 2000;
  margin-right: ${(props) => props.theme.global.padding.medium};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-family: ${(props) => props.theme.global.typography.paragraph.fontFamily};
  font-size: ${(props) => props.theme.global.typography.paragraph.fontSize};

  svg {
    fill: ${(props) => getColorFromStatus(props.severity)};
  }
`;

type AlertIconProps = {
  severity?: AlertSeverity;
};

const AlertIcon = (props: AlertIconProps) => {
  const Icon = getIconFromStatus(props.severity);
  return <Icon size={"2em"} style={{ margin: "0 0.5em" }} />;
};

export default {
  Wrapper: AlertWrapper,
  Icon: AlertIcon,
};
