import React, { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import StyledEntryDetail from "../EntryDetail/styled";

import { Spinner } from "../../components/Spinner";
import Container from "../../components/Container";
import EntryHeader from "../../components/EntryHeader";
import BackButton from "../../components/BackButton";
import Button from "../../components/Button";
import Form from "../../components/Form";
import strings from "../../config/strings";
import i18n from "../../services/i18n";

import { useStores } from "../../hooks/useStores";
import { Locale } from "../../types";

export const ProfileScreen = observer(() => {
  const { t } = useTranslation();
  const { auth } = useStores();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const schema = {
    title: t("user.profile"),
    description: "",
    type: "object",
    required: ["email"],
    properties: {
      user: {
        title: "",
        type: "object",
        properties: {
          nominative: {
            title: { [i18n.language]: t("user.nominative") },
            type: "string",
          },
          email: {
            title: { [i18n.language]: t("user.email") },
            type: "string",
          },
        },
      },
    },
  };
  const uiSchema = {};

  let submitProfileForm = null;
  const bindSubmitForm = (submitForm) => (submitProfileForm = submitForm);

  const handleStoreData = useCallback(
    async (data) => {
      setIsSubmitting(true);
      await auth.updateUserProfile(data.user);
      setIsSubmitting(false);
    },
    [auth]
  );

  const handleSubmit = useCallback(
    (event) => {
      // @ts-ignore
      submitProfileForm && submitProfileForm(event);
    },
    [submitProfileForm]
  );

  return (
    <Container>
      <EntryHeader.Header>
        <StyledEntryDetail.TitleWrapper>
          <BackButton size={"large"} />
          <EntryHeader.Title icon={"user"} title={schema.title} />
        </StyledEntryDetail.TitleWrapper>
        <EntryHeader.ActionContainer>
          <Button icon={"check"} onClick={handleSubmit} loading={isSubmitting}>
            {t("save")}
          </Button>
        </EntryHeader.ActionContainer>
      </EntryHeader.Header>

      {auth.isUpdatingUserProfile && <Spinner size={150} />}

      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={{ user: auth.user }}
        onSubmit={handleStoreData}
        submitLabel={strings.screens.entryDetail.save}
        shouldPreventLeavingDirtyForm={true}
        isOnline={true} // TODO: Fix with store value
        bindSubmitForm={bindSubmitForm}
        hasSubmitButton={false}
        language={i18n.language as Locale}
        languages={[i18n.language as Locale]}
      />
    </Container>
  );
});
