import styled from "styled-components";

const Title = styled.h2`
  font-size: ${(props) => props.theme.global.typography.h2.fontSize};
  font-weight: ${(props) => props.theme.global.typography.h2.fontWeight};
  line-height: ${(props) => props.theme.global.typography.lineHeight.title};
`;

export default {
  Title,
};
