import React, { useCallback } from "react";
import { MdNavigateBefore as NavigateBeforeIcon } from "react-icons/md";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../Button";
import navigationUtils from "../../utils/navigation";

type Props = {
  size?: "small" | "medium" | "large" | undefined
}

const BackButton: React.FC<Props> = ({size}) => {
  const history = useHistory();
  const location = useLocation();

  const handleGoBack = useCallback(() => {
    const tenantId = navigationUtils.fromRoutes.tenantId(location.pathname);
    const contentTypeId = navigationUtils.fromRoutes.contentTypeId(
      location.pathname
    );

    if (tenantId && contentTypeId) {
      history.push(navigationUtils.routes.entry.list(tenantId, contentTypeId))
    } else {
      history.goBack();
    }
  }, [history]);

  return (
    <Button model={"secondary"} onClick={handleGoBack} size={size}>
      <NavigateBeforeIcon />
    </Button>
  );
};

export default BackButton;
