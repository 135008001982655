import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import Form from "../../components/Form";
import { AuthLayout } from "../../components/AuthLayout";
import { Link } from "../../components/Link";

import navigationUtils from "../../utils/navigation";
import i18n from "../../services/i18n";

import type { FormData, JSONSchema, UISchema, Locale } from "../../types";
import { useHistory, useLocation } from "react-router-dom";
import { useStores } from "../../hooks/useStores";
import { useSnackbar } from "notistack";

export const ResetPasswordScreen: React.FC = observer(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { auth } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  const URLParams = new URLSearchParams(location.search);

  const handleSubmit = useCallback(
    async (values: FormData) => {
      const token = URLParams.get("token") || "";

      try {
        await auth.resetPassword(values.email, values.password, token);
        enqueueSnackbar(t("common.actions.actionCompleted"), {
          variant: "success",
        });
        history.push(navigationUtils.routes.auth.login());
      } catch (_) {
        enqueueSnackbar(t("common.error"), {
          variant: "error",
        });
      }
    },
    [URLParams, auth, enqueueSnackbar, history, t]
  );

  const schema: JSONSchema = {
    title: t("auth.newPassword"),
    type: "object",
    required: ["email", "password"],
    properties: {
      email: {
        title: { [i18n.language]: t("auth.email") },
        type: "string",
      },
      password: {
        title: { [i18n.language]: t("auth.newPassword") },
        type: "string",
      },
    },
  };
  const uiSchema: UISchema = {
    email: {
      "ui:autofocus": true,
      "ui:placeholder": t("auth.enterEmail"),
    },
    password: {
      "ui:widget": "password",
    },
  };

  return (
    <AuthLayout title={schema.title}>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={{ email: URLParams.get("email") || "" }}
        onSubmit={handleSubmit}
        submitLabel={t("auth.confirm")}
        language={i18n.language as Locale}
        languages={[i18n.language as Locale]}
      />
      <Link
        href={navigationUtils.routes.auth.login()}
        text={t("auth.backToLogin")}
      />
    </AuthLayout>
  );
});
