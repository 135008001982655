import React, { useCallback, useContext, useMemo, useState } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import StyledEntryDetail from "./styled";
import strings from "../../config/strings";
import navigationUtils from "../../utils/navigation";
import { difference } from "../../utils/object";
import EntryModel from "../../models/Entry";
import Container from "../../components/Container";
import EntryHeader from "../../components/EntryHeader";
import EntryContent from "../../components/EntryContent";
import BackButton from "../../components/BackButton";
import Form from "../../components/Form";
import { Spinner } from "../../components/Spinner";
import EmptyState from "../../components/EmptyState";
import Button from "../../components/Button";

import type { CustomAction, Tenant } from "../../types";
import { useStores } from "../../hooks/useStores";
import { ContentType } from "../../models/ContentType";
import { useSnackbar } from "notistack";
import { AbilityContext } from "../../context/can";
import { useCurrentTenant } from "../../hooks/useCurrentTenant";
import { useCurrentContentType } from "../../hooks/useCurrentContentType";
import { useCurrentEntry } from "../../hooks/useCurrentEntry";

type Props = {};

export const EntryDetailScreen: React.FC<Props> = observer(() => {
  const { tenant, entry } = useStores();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const ability = useContext(AbilityContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState([]);

  const currentTenant = useCurrentTenant();
  const currentContentType = useCurrentContentType();
  const currentEntry = useCurrentEntry();

  const handleGoToEntryId = useCallback(
    (
      tenantId: Tenant["uid"], //| $PropertyType<Tenant, "name">,
      contentTypeId: ContentType["uid"],
      entryId: EntryModel["uid"]
    ) => {
      history.push(
        navigationUtils.routes.entry.find(tenantId, contentTypeId, entryId)
      );
    },
    [history]
  );

  let submitEntryForm = null;
  const bindSubmitForm = (submitForm) => (submitEntryForm = submitForm);

  const handleSubmit = async (e) => {
    if (submitEntryForm) {
      submitEntryForm(e);
    }
  };

  const handleStoreData = useCallback(
    async (values: Object) => {
      let newEntry = null;
      setIsSubmitting(true);

      try {
        if (currentEntry != null) {
          const initialFormState = toJS(formData);
          const editedData = difference(values, initialFormState);

          newEntry = await entry.updateEntry(
            currentTenant.uid || "",
            currentContentType.uid,
            currentEntry.uid,
            editedData
          );
          enqueueSnackbar(t("common.actions.updateCompleted"), {
            variant: "success",
          });
        } else {
          newEntry = await entry.createEntry(
            currentTenant.uid || "",
            currentContentType.uid,
            values
          );
          enqueueSnackbar(t("common.actions.createCompleted"), {
            variant: "success",
          });
        }
        newEntry != null &&
          handleGoToEntryId(
            currentTenant.uid || "",
            currentContentType.uid,
            newEntry.uid
          );
      } catch (err) {
        enqueueSnackbar(t("common.error"), {
          variant: "error",
        });
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      entry,
      setIsSubmitting,
      currentTenant,
      formData,
      handleGoToEntryId,
      currentContentType,
      currentEntry,
      enqueueSnackbar,
      t,
    ]
  );

  const handlePerformCustomAction = useCallback(
    async (action: CustomAction) => {
      try {
        await entry.performCustomAction(
          currentTenant.uid,
          currentContentType.uid,
          currentEntry.uid,
          action
        );
        enqueueSnackbar(t("common.actions.actionCompleted"), {
          variant: "success",
        });
      } catch (err) {
        console.log(err);
        enqueueSnackbar(t("common.error"), {
          variant: "error",
        });
      }
    },
    [entry, t, enqueueSnackbar, currentTenant, currentContentType, currentEntry]
  );

  let customActions = useMemo(() => {
    const actions = [];
    if (currentContentType?.uiSchema["ui:entryActions"]) {
      for (let action of currentContentType.uiSchema["ui:entryActions"]) {
        if (ability.can(action, currentContentType.slug)) {
          actions.push(
            <Button
              model={"secondary"}
              onClick={() => handlePerformCustomAction(action)}
            >
              {t(`entries.actions.${action}`)}
            </Button>
          );
        }
      }
    }
    return actions;
  }, [currentContentType, ability, handlePerformCustomAction, t]);

  if (tenant.isFetching || entry.isFetching || !currentContentType) {
    return <Spinner size={150} />;
  }

  return (
    <Container>
      <EntryHeader.Header bordered>
        <StyledEntryDetail.TitleWrapper>
          <BackButton size={"large"} />
          <EntryHeader.Title
            icon={currentContentType.uiSchema["ui:icon"]}
            title={currentContentType.label[tenant.locale] || ""}
          />
          <StyledEntryDetail.SubTitle>
            {t(currentEntry ? "common.edit" : "common.new")}
          </StyledEntryDetail.SubTitle>
        </StyledEntryDetail.TitleWrapper>
        <EntryHeader.ActionContainer>
          {customActions}
          {(ability.can("write", currentContentType.slug) ||
            ability.can("update", currentContentType.slug)) && (
            <Button
              icon={"check"}
              onClick={handleSubmit}
              loading={isSubmitting}
            >
              {t("common.save")}
            </Button>
          )}
        </EntryHeader.ActionContainer>
      </EntryHeader.Header>
      <EntryContent>
        {(entry != null && !ability.can("read", currentContentType.slug)) ||
        (entry == null && !ability.can("write", currentContentType.slug)) ? (
          <EmptyState reason={"unauthorized"} />
        ) : (
          <Form
            schema={currentContentType.schema}
            uiSchema={currentContentType.uiSchema}
            formData={
              currentEntry ? currentEntry.data : currentContentType.formData
            }
            stagesMetadata={currentEntry ? currentEntry.stagesMetadata : []}
            onSubmit={handleStoreData}
            hasSubmitButton={false}
            submitLabel={strings.screens.entryDetail.save}
            shouldPreventLeavingDirtyForm={true}
            contentTypes={currentTenant.contentTypes}
            contentTypesACL={{}}
            isOnline={true} // TODO: Fix
            tenantId={currentTenant.uid}
            contentTypeId={currentContentType.uid}
            entryId={currentEntry?.uid}
            bindSubmitForm={bindSubmitForm}
            language={tenant.locale}
            languages={currentTenant.configuration.languages}
          />
        )}
      </EntryContent>
    </Container>
  );
});
