
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.global.colors.link.initial};
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.global.colors.link.hover};
  }
  &:active {
    color: ${(props) => props.theme.global.colors.link.active};
  }

  &:active,
  &:hover {
    text-decoration: none;
    outline: 0;
  }
`;

export default StyledLink;
