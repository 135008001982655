/** @flow */
import { escapeRegExp, applyThousandSeparator } from "../../utils/string";
import numberUtils from "../../utils/number";
import inputUtils from "../../utils/input";

const getMaskAtIndex = (mask: string = "", index: number) => {
  if (typeof mask === "string") {
    return mask;
  }

  return mask[index] || " ";
};

const formatWithPattern = (numStr: string, mask: string, format: string) => {
  let hashCount = 0;
  const formattedNumberAry = format.split("");
  for (let i = 0, ln = format.length; i < ln; i++) {
    if (format[i] === "#") {
      formattedNumberAry[i] =
        numStr[hashCount] || getMaskAtIndex(mask, hashCount);
      hashCount += 1;
    }
  }
  return formattedNumberAry.join("");
};

const getSeparators = (
  decimalSeparator: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[]
) => {
  if (thousandSeparator === true) {
    thousandSeparator = ",";
  }
  if (!allowedDecimalSeparators) {
    allowedDecimalSeparators = [decimalSeparator, "."];
  }

  return {
    decimalSeparator,
    thousandSeparator,
    allowedDecimalSeparators,
  };
};

//returned regex assumes decimalSeparator is as per prop
const getNumberRegex = (
  g: boolean,
  ignoreDecimalSeparator: boolean,
  decimalScale?: number,
  format?: string,
  numberDecimalSeparator: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[]
) => {
  const { decimalSeparator } = getSeparators(
    numberDecimalSeparator,
    thousandSeparator,
    allowedDecimalSeparators
  );
  return new RegExp(
    "\\d" +
      (decimalSeparator &&
      decimalScale !== 0 &&
      !ignoreDecimalSeparator &&
      !format
        ? "|" + escapeRegExp(decimalSeparator)
        : ""),
    g ? "g" : undefined
  );
};

const formatAsNumber = (
  numStr: string,
  decimalScale?: number,
  fixedDecimalScale?: boolean,
  prefix?: string,
  suffix?: string,
  allowNegative: boolean,
  thousandsGroupStyle: "thousand" | "lakh" | "wan",
  numDecimalSeparator: string,
  numThousandSeparator?: string | boolean,
  numAllowedDecimalSeparators?: string[]
) => {
  const { thousandSeparator, decimalSeparator } = getSeparators(
    numDecimalSeparator,
    numThousandSeparator,
    numAllowedDecimalSeparators
  );

  const hasDecimalSeparator =
    numStr.indexOf(".") !== -1 || (decimalScale && fixedDecimalScale);
  let { beforeDecimal, afterDecimal, addNegation } = numberUtils.splitDecimal(
    numStr,
    allowNegative
  );

  //apply decimal precision if its defined
  if (decimalScale !== undefined)
    afterDecimal = numberUtils.limitToScale(
      afterDecimal,
      decimalScale,
      fixedDecimalScale
    );

  if (thousandSeparator) {
    beforeDecimal = applyThousandSeparator(
      beforeDecimal,
      thousandSeparator,
      thousandsGroupStyle
    );
  }

  //add prefix and suffix
  if (prefix) beforeDecimal = prefix + beforeDecimal;
  if (suffix) afterDecimal = afterDecimal + suffix;

  //restore negation sign
  if (addNegation) beforeDecimal = "-" + beforeDecimal;

  numStr =
    beforeDecimal +
    ((hasDecimalSeparator && decimalSeparator) || "") +
    afterDecimal;

  return numStr;
};

const getFloatString = (
  num: string = "",
  decimalScale?: number,
  numDecimalSeparator: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[],
  format?: string
) => {
  const { decimalSeparator } = getSeparators(
    numDecimalSeparator,
    thousandSeparator,
    allowedDecimalSeparators
  );
  const numRegex = getNumberRegex(
    true,
    false,
    decimalScale,
    format,
    decimalSeparator,
    thousandSeparator,
    allowedDecimalSeparators
  );

  //remove negation for regex check
  const hasNegation = num[0] === "-";
  if (hasNegation) num = num.replace("-", "");

  //if decimal scale is zero remove decimal and number after decimalSeparator
  if (decimalSeparator && decimalScale === 0) {
    num = num.split(decimalSeparator)[0];
  }

  num = (num.match(numRegex) || []).join("").replace(decimalSeparator, ".");

  //remove extra decimals
  const firstDecimalIndex = num.indexOf(".");

  if (firstDecimalIndex !== -1) {
    num = `${num.substring(0, firstDecimalIndex)}.${num
      .substring(firstDecimalIndex + 1, num.length)
      .replace(new RegExp(escapeRegExp(decimalSeparator), "g"), "")}`;
  }

  //add negation back
  if (hasNegation) num = "-" + num;

  return num;
};

const removePrefixAndSuffix = (
  val: string,
  format?: string,
  prefix?: string,
  suffix?: string
) => {
  //remove prefix and suffix
  if (!format && val) {
    const isNegative = val[0] === "-";

    //remove negation sign
    if (isNegative) val = val.substring(1, val.length);

    //remove prefix
    val =
      prefix && val.indexOf(prefix) === 0
        ? val.substring(prefix.length, val.length)
        : val;

    //remove suffix
    const suffixLastIndex = suffix ? val.lastIndexOf(suffix) : -1;
    val =
      suffix &&
      suffixLastIndex !== -1 &&
      suffixLastIndex === val.length - suffix.length
        ? val.substring(0, suffixLastIndex)
        : val;

    //add negation sign back
    if (isNegative) val = "-" + val;
  }

  return val;
};

const removePatternFormatting = (val: string, format?: string) => {
  const formatArray = format
    ? format.split("#").filter((str) => str !== "")
    : [];
  let start = 0;
  let numStr = "";

  for (let i = 0, ln = formatArray.length; i <= ln; i++) {
    const part = formatArray[i] || "";

    //if i is the last fragment take the index of end of the value
    //For case like +1 (911) 911 91 91 having pattern +1 (###) ### ## ##
    const index = i === ln ? val.length : val.indexOf(part, start);

    /* in any case if we don't find the pattern part in the value assume the val as numeric string
    This will be also in case if user has started typing, in any other case it will not be -1
    unless wrong prop value is provided */
    if (index === -1) {
      numStr = val;
      break;
    } else {
      numStr += val.substring(start, index);
      start = index + part.length;
    }
  }

  return (numStr.match(/\d/g) || []).join("");
};

const removeFormatting = (
  val: string,
  format?: string,
  decimalScale?: number,
  decimalSeparator: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[]
) => {
  if (!val) return val;

  if (!format) {
    val = removePrefixAndSuffix(val, format);
    val = getFloatString(
      val,
      decimalScale,
      decimalSeparator,
      thousandSeparator,
      allowedDecimalSeparators,
      format
    );
  } else if (typeof format === "string") {
    val = removePatternFormatting(val, format);
  } else {
    val = (val.match(/\d/g) || []).join("");
  }

  return val;
};

const formatNumString = (
  numStr: string = "",
  mask: string,
  format?: string,
  allowEmptyFormatting: ?boolean,
  decimalScale?: number,
  fixedDecimalScale?: boolean,
  prefix?: string,
  suffix?: string,
  allowNegative: boolean,
  thousandsGroupStyle: "thousand" | "lakh" | "wan",
  decimalSeparator: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[]
) => {
  let formattedValue = numStr;

  if (numStr === "" && !allowEmptyFormatting) {
    formattedValue = "";
  } else if (numStr === "-" && !format) {
    formattedValue = "-";
  } else if (typeof format === "string") {
    formattedValue = formatWithPattern(formattedValue, mask, format);
    /*} else if (typeof format === "function") {
    formattedValue = format(formattedValue);*/
  } else {
    formattedValue = formatAsNumber(
      formattedValue,
      decimalScale,
      fixedDecimalScale,
      prefix,
      suffix,
      allowNegative,
      thousandsGroupStyle,
      decimalSeparator,
      thousandSeparator,
      allowedDecimalSeparators
    );
  }

  return formattedValue;
};

const formatNegation = (value: string = "", allowNegative: boolean) => {
  const negationRegex = new RegExp("(-)");
  const doubleNegationRegex = new RegExp("(-)(.)*(-)");

  // Check number has '-' value
  const hasNegation = negationRegex.test(value);

  // Check number has 2 or more '-' values
  const removeNegation = doubleNegationRegex.test(value);

  //remove negation
  value = value.replace(/-/g, "");

  if (hasNegation && !removeNegation && allowNegative) {
    value = "-" + value;
  }

  return value;
};

const formatInput = (
  value: string = "",
  mask: string,
  format?: string,
  prefix?: string,
  suffix?: string,
  allowNegative: boolean,
  decimalScale?: number,
  decimalSeparator: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[],
  allowEmptyFormatting?: boolean,
  fixedDecimalScale?: boolean,
  thousandsGroupStyle: "thousand" | "lakh" | "wan"
) => {
  //format negation only if we are formatting as number
  if (!format) {
    value = removePrefixAndSuffix(value, format, prefix, suffix);
    value = formatNegation(value, allowNegative);
  }

  //remove formatting from number
  value = removeFormatting(
    value,
    format,
    decimalScale,
    decimalSeparator,
    thousandSeparator,
    allowedDecimalSeparators
  );

  return formatNumString(
    value,
    mask,
    format,
    allowEmptyFormatting,
    decimalScale,
    fixedDecimalScale,
    prefix,
    suffix,
    allowNegative,
    thousandsGroupStyle,
    decimalSeparator,
    thousandSeparator,
    allowedDecimalSeparators
  );
};

const formatValueProp = (
  //defaultValue: string | number,
  value: string | number,
  mask: string,
  format?: string,
  prefix?: string,
  suffix?: string,
  allowNegative: boolean,
  decimalScale?: number,
  fixedDecimalScale?: boolean,
  allowEmptyFormatting?: boolean,
  isNumericString?: boolean,
  decimalSeparator: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[],
  thousandsGroupStyle: "thousand" | "lakh" | "wan"
) => {
  let parsedValue = value || "";

  const isNonNumericFalsy = !value && value !== 0;

  if (isNonNumericFalsy && allowEmptyFormatting) {
    parsedValue = "";
  }

  // if value is not defined return empty string
  if (isNonNumericFalsy && !allowEmptyFormatting) return "";

  if (typeof value === "number") {
    parsedValue = parsedValue.toString();
    isNumericString = true;
  }

  //change infinity value to empty string
  if (parsedValue === "Infinity" && isNumericString) {
    parsedValue = "";
  }

  //round the number based on decimalScale
  //format only if non formatted value is provided
  if (isNumericString && !format && typeof decimalScale === "number") {
    parsedValue = numberUtils.roundToPrecision(
      parsedValue.toString(),
      decimalScale,
      fixedDecimalScale
    );
  }

  const formattedValue = isNumericString
    ? formatNumString(
        parsedValue.toString(),
        mask,
        format,
        allowEmptyFormatting,
        decimalScale,
        fixedDecimalScale,
        prefix,
        suffix,
        allowNegative,
        thousandsGroupStyle,
        decimalSeparator,
        thousandSeparator,
        allowedDecimalSeparators
      )
    : formatInput(
        parsedValue.toString(),
        mask,
        format,
        prefix,
        suffix,
        allowNegative,
        decimalScale,
        decimalSeparator,
        thousandSeparator,
        allowedDecimalSeparators,
        allowEmptyFormatting,
        fixedDecimalScale,
        thousandsGroupStyle
      );

  return formattedValue;
};

const isCharacterAFormat = (
  caretPos: number,
  value: string,
  format?: string,
  prefix?: string,
  suffix?: string,
  decimalScale?: number,
  fixedDecimalScale?: boolean,
  numDecimalSeparator: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[]
) => {
  const { decimalSeparator } = getSeparators(
    numDecimalSeparator,
    thousandSeparator,
    allowedDecimalSeparators
  );

  //check within format pattern
  if (typeof format === "string" && format[caretPos] !== "#") return true;

  //check in number format
  if (
    !format &&
    ((prefix && caretPos < prefix.length) ||
      (suffix && caretPos >= value.length - suffix.length) ||
      (decimalScale &&
        fixedDecimalScale &&
        value[caretPos] === decimalSeparator))
  ) {
    return true;
  }

  return false;
};

const checkIfFormatGotDeleted = (
  start: number,
  end: number,
  value: string,
  format?: string,
  prefix?: string,
  suffix?: string,
  decimalScale?: number,
  fixedDecimalScale?: boolean,
  decimalSeparator: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[]
) => {
  for (let i = start; i < end; i++) {
    if (
      isCharacterAFormat(
        i,
        value,
        format,
        prefix,
        suffix,
        decimalScale,
        fixedDecimalScale,
        decimalSeparator,
        thousandSeparator,
        allowedDecimalSeparators
      )
    )
      return true;
  }
  return false;
};

/**
 * This will check if any formatting got removed by the delete or backspace and reset the value
 * It will also work as fallback if android chome keyDown handler does not work
 **/
const correctInputValue = (
  caretPos: number,
  lastValue: string,
  value: string,
  inputValueAsString: string,
  prefix?: string,
  suffix?: string,
  format?: string,
  decimalScale?: number,
  fixedDecimalScale?: boolean,
  numDecimalSeparator: string,
  numThousandSeparator?: string | boolean,
  numAllowedDecimalSeparators?: string[],
  selectionBeforeInput: { selectionStart: number, selectionEnd: number },
  allowNegative: boolean
) => {
  const { allowedDecimalSeparators, decimalSeparator } = getSeparators(
    numDecimalSeparator,
    numThousandSeparator,
    numAllowedDecimalSeparators
  );
  const lastNumStr = inputValueAsString || "";
  const { selectionStart, selectionEnd } = selectionBeforeInput;
  const { start, end } = inputUtils.findChangedIndex(lastValue, value);

  /** Check for any allowed decimal separator is added in the numeric format and replace it with decimal separator */
  if (
    !format &&
    start === end &&
    allowedDecimalSeparators.indexOf(value[selectionStart]) !== -1
  ) {
    return (
      value.substr(0, selectionStart) +
      decimalSeparator +
      value.substr(selectionStart + 1, value.length)
    );
  }

  /* don't do anyhting if something got added,
    or if value is empty string (when whole input is cleared)
    or whole input is replace with a number
  */
  const leftBound = !!format ? 0 : prefix ? prefix.length : 0;
  const rightBound =
    lastValue.length - (!!format ? 0 : suffix ? suffix.length : 0);
  if (
    value.length > lastValue.length ||
    !value.length ||
    start === end ||
    (selectionStart === 0 && selectionEnd === lastValue.length) ||
    (selectionStart === leftBound && selectionEnd === rightBound)
  ) {
    return value;
  }

  //if format got deleted reset the value to last value
  if (
    checkIfFormatGotDeleted(
      start,
      end,
      lastValue,
      format,
      prefix,
      suffix,
      decimalScale,
      fixedDecimalScale,
      decimalSeparator,
      numThousandSeparator,
      allowedDecimalSeparators
    )
  ) {
    value = lastValue;
  }

  //for numbers check if beforeDecimal got deleted and there is nothing after decimal,
  //clear all numbers in such case while keeping the - sign
  if (!format) {
    const numericString = removeFormatting(
      value,
      format,
      decimalScale,
      numDecimalSeparator,
      numThousandSeparator,
      numAllowedDecimalSeparators
    );
    let { beforeDecimal, afterDecimal, addNegation } = numberUtils.splitDecimal(
      numericString,
      allowNegative
    ); // eslint-disable-line prefer-const

    //clear only if something got deleted
    const isBeforeDecimalPoint = caretPos < value.indexOf(decimalSeparator) + 1;
    if (
      numericString.length < lastNumStr.length &&
      isBeforeDecimalPoint &&
      beforeDecimal === "" &&
      !parseFloat(afterDecimal)
    ) {
      return addNegation ? "-" : "";
    }
  }

  return value;
};

export default {
  getSeparators,
  formatWithPattern,
  getNumberRegex,
  getFloatString,
  removePatternFormatting,
  removePrefixAndSuffix,
  removeFormatting,
  formatNumString,
  formatNegation,
  formatInput,
  formatValueProp,
  correctInputValue,
};
