import styled, { css } from "styled-components";

const cellStyles = css<{ onClick?: (any) => any }>`
  text-align: left;
  vertical-align: middle;
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.global.padding.xsmall}
    ${(props) => props.theme.global.padding.xsmall};
  min-width: 5em;
  ${(props) => props.onClick && "cursor: pointer;"}
`;

export const Th = styled.div`
  ${cellStyles}
  color: ${(props) => props.theme.global.colors.table.title};
  text-transform: uppercase;
  font-size: 0.833em;
`;

export const Td = styled.div`
  ${cellStyles};
  word-break: break-all;
`;
