import React, { useCallback } from "react";
import { isArray, isNil, isEmpty, isObject, merge } from "lodash-es";

import FieldTitleLabel from "../../components/FieldTitleLabel";
import FieldsetGrouping from "../../groupings/FieldsetGrouping";
import TableGrouping from "../../groupings/TableGrouping";
import TabsGrouping from "../../groupings/TabsGrouping";
import formikUtils from "../../utils/formik";
import { getComponentsFromSchema } from "../../utils/jsonSchema";
import typeUtils from "../../utils/type";

import type {
  JSONSchema,
  UISchema,
  FormData,
  ContentTypesACLRules,
  Tenant,
  Entry,
  FixMeLater,
  Locale,
} from "../../types";
import { ContentType } from "../../models/ContentType";
import EntryModel from "../../models/Entry";

type FieldMetadata = {
  name: string;
  schema: JSONSchema;
  uiSchema: UISchema;
  parentSchema: JSONSchema;
  parentUISchema: UISchema;
  keySuffix: string;
  values: FormData;
  errors: Object;
  isOnline: boolean;
  tenantId?: Tenant["uid"];
  contentTypeId?: ContentType["uid"];
  entryId?: EntryModel["uid"] | null;
};

type Props = {
  id: string;
  name?: string;
  onBlur?: (event: any) => any;
  onChange?: (event: any) => any;
  value?: FixMeLater;
  schema?: JSONSchema;
  uiSchema?: UISchema;
  defaultValue?: string;
  autoFocus?: boolean;
  required?: boolean;
  readOnly?: boolean;
  isOnline?: boolean;
  tenantId?: Tenant["uid"];
  contentTypeId?: ContentType["uid"];
  entryId?: EntryModel["uid"] | null;
  contentTypes?: ContentType[];
  contentTypesACL?: ContentTypesACLRules;
  language: Locale;
  languages: Locale[];
};

const ObjectWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue = "{}",
  schema = {},
  uiSchema = {},
  autoFocus = false,
  required = false,
  readOnly = false,
  isOnline = false,
  tenantId,
  contentTypeId,
  entryId,
  contentTypes,
  contentTypesACL,
  language,
}: Props) => {
  let innerValue = {};
  try {
    const parsedValue = JSON.parse(value || defaultValue);
    innerValue = parsedValue;
  } catch {}

  console.log("\tObjectWidget", { id, name, value, innerValue });

  const handleChange = useCallback(
    (childName: string, schemaType: any, eventOrValue: any) => {
      if (readOnly) return;
      let value = eventOrValue;
      const eventTarget = (isObject(eventOrValue) as FixMeLater)
        ? eventOrValue.currentTarget || eventOrValue.target
        : null;
      if (eventTarget != null) {
        value =
          eventTarget.type === "checkbox"
            ? eventTarget.checked
            : eventTarget.value;
        if (eventTarget.files != null && eventTarget.files.length > 0) {
          value = Array.from(eventTarget.files);
        }
      }
      const targetValueType = [
        "number",
        "integer",
        "boolean",
        "array",
      ].includes(schemaType)
        ? schemaType
        : "string";
      value = typeUtils.coerceToType(targetValueType, value);
      const changeEvent = formikUtils.generateEvent(
        id,
        name || id,
        merge({}, innerValue, { [childName]: value }),
        "objectWidgetChange"
      );

      console.log("\t\tObjectWidget onChange", {
        childName,
        schemaType,
        eventOrValue,
        changeEvent,
      });

      onChange && onChange(changeEvent);
    },
    [onChange, readOnly, innerValue, id, name]
  );

  const renderMutipleFields = ({
    name,
    schema,
    uiSchema,
    parentSchema,
    parentUISchema,
    keySuffix,
    values,
    errors,
    isOnline,
    tenantId,
    contentTypeId,
    entryId,
  }: FieldMetadata): any => {
    console.log("\t\tObjectWidget - Multiple Field", {
      id,
      name,
      value,
      innerValue,
      schema,
    });
    if (schema.type === "object") {
      if (isNil(schema.properties) || isEmpty(schema.properties)) {
        console.warn(`Schema: Empty properties for "${name}" object`);
        return null;
      }
      /*
       * The first aggregate field has an empty `name` so we don't need a suffix for the childs
       */
      let childsSuffix = "";
      const divider = ".";
      if (name !== "") {
        childsSuffix = `${keySuffix}${name}${divider}`;
      }

      const childProperties = Object.keys(schema.properties);
      const uiOrder = isArray(uiSchema["ui:propertyOrder"])
        ? uiSchema["ui:propertyOrder"]
        : [];
      const sortedChildProperties = [
        ...uiOrder.filter((property) => childProperties.includes(property)),
        ...childProperties.filter((property) => !uiOrder.includes(property)),
      ];
      const objectChildProperties = sortedChildProperties.filter(
        (prop) => schema.properties[prop].type === "object"
      );
      const nonObjectChildProperties = sortedChildProperties.filter(
        (prop) => schema.properties[prop].type !== "object"
      );

      const renderChildProperty = (childName: string) => {
        const childSchema = schema.properties[childName] || {};
        const childUISchema = uiSchema[childName] || {};
        const childValues =
          values && values[childName]
            ? values[childName]
            : childSchema.type === "string"
            ? ""
            : {};
        const childErrors = errors[childName] || {};
        return renderField({
          name: childName,
          schema: childSchema,
          uiSchema: childUISchema,
          parentSchema: schema,
          parentUISchema: uiSchema,
          keySuffix: childsSuffix,
          values: childValues,
          errors: childErrors,
          isOnline,
          tenantId,
          contentTypeId,
          entryId,
        });
      };

      // Table grouping (of all children)
      if (uiSchema["ui:widget"] === "table") {
        return (
          <TableGrouping
            title={schema.title}
            cells={sortedChildProperties}
            renderCell={renderChildProperty}
            getCellKey={(childProperty) => `${keySuffix}${childProperty}`}
            rowLength={uiSchema["ui:numItemsPerRow"]}
            rowLabels={uiSchema["ui:rowNames"]}
            columnLabels={uiSchema["ui:columnNames"]}
            readOnly={schema.readOnly}
          />
        );
      }

      // Tabs grouping (of object children)
      if (uiSchema["ui:widget"] === "tabs") {
        return (
          <React.Fragment key={`${keySuffix}${name}`}>
            {nonObjectChildProperties.map(renderChildProperty)}
            {objectChildProperties.length > 0 && (
              <TabsGrouping
                tabs={objectChildProperties}
                renderTab={renderChildProperty}
                getTabKey={(childProperty) => `${keySuffix}${childProperty}`}
                getTabTitle={(childProperty) =>
                  (schema.properties[childProperty] || {}).title ||
                  childProperty
                }
                getTabState={(childProperty) => "inProgress"}
                readOnly={schema.readOnly}
                orientation={uiSchema["ui:tabOrientation"]}
              />
            )}
          </React.Fragment>
        );
      }

      // Use no grouping for schema's root object or if parent was a Tabs grouping
      if (name === "" || parentUISchema["ui:widget"] === "tabs") {
        return sortedChildProperties.map((childProperty) => (
          <React.Fragment key={`${keySuffix}${childProperty}`}>
            {renderChildProperty(childProperty)}
          </React.Fragment>
        ));
      }

      // Fieldset grouping (of all children)
      return (
        <FieldsetGrouping
          title={name !== "" ? schema.title : undefined}
          elevated={name !== ""}
          items={sortedChildProperties}
          renderItem={renderChildProperty}
          getItemKey={(childProperty) => `${keySuffix}${childProperty}`}
          readOnly={schema.readOnly}
          direction={"horizontal"}
        />
      );
    }
  };

  const renderSingleField = ({
    name,
    schema,
    uiSchema,
    parentSchema,
    parentUISchema,
    keySuffix,
    values,
    errors,
    isOnline,
    tenantId,
    contentTypeId,
    entryId,
  }: FieldMetadata): any => {
    console.log("\t\tObjectWidget - Single Field", {
      id,
      name,
      value,
      innerValue,
      schema,
    });
    const fieldComponentId = `${keySuffix}${name}`;
    const {
      fieldComponent,
      fieldComponentProps,
      fieldValidation,
      widgetComponent: WidgetComponent,
      widgetComponentProps,
      widgetSchema,
      widgetUISchema,
    } = getComponentsFromSchema(
      name,
      schema,
      uiSchema,
      parentSchema,
      contentTypes,
      contentTypesACL
    );
    if (fieldComponent == null || WidgetComponent == null) return null;
    if (readOnly) {
      widgetComponentProps.readOnly = true;
    }

    return (
      <div>
        <FieldTitleLabel
          title={uiSchema["ui:title"] || schema.title[language] || name}
          description={uiSchema["ui:description"] || schema.description}
          required={required}
          htmlFor={id}
        />
        <WidgetComponent
          {...fieldComponentProps}
          {...widgetComponentProps}
          key={fieldComponentId}
          id={fieldComponentId}
          name={fieldComponentId}
          onBlur={onBlur}
          onChange={handleChange.bind(this, name, schema.type)}
          value={values}
          schema={widgetSchema}
          uiSchema={widgetUISchema}
          // validate={fieldValidation}
          isOnline={isOnline}
          tenantId={tenantId}
          contentTypeId={contentTypeId}
          entryId={entryId}
          language={language}
        />
      </div>
    );
  };

  const renderField = ({
    name,
    schema,
    uiSchema,
    parentSchema,
    parentUISchema,
    keySuffix,
    values,
    errors,
    isOnline,
    tenantId,
    contentTypeId,
    entryId,
  }: FieldMetadata): React.ElementType<any>[] => {
    if (schema.type === "object") {
      return renderMutipleFields({
        name,
        schema,
        uiSchema,
        parentSchema,
        parentUISchema,
        keySuffix,
        values,
        errors,
        isOnline,
        tenantId,
        contentTypeId,
        entryId,
      });
    } else {
      return renderSingleField({
        name,
        schema,
        uiSchema,
        parentSchema,
        parentUISchema,
        keySuffix,
        values,
        errors,
        isOnline,
        tenantId,
        contentTypeId,
        entryId,
      });
    }
  };

  return renderMutipleFields({
    name: id.replace(/\[\d+\]$/, ""),
    schema,
    uiSchema,
    parentSchema: {},
    parentUISchema: {},
    keySuffix: "",
    values: value,
    errors: {},
    isOnline,
    tenantId,
    contentTypeId,
    entryId,
  });
};

ObjectWidget.mapSchemaToProps = (schema: FixMeLater, uiSchema: FixMeLater) => {
  let props: FixMeLater = {};
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.readOnly = schema.readOnly;
  return props;
};

export default ObjectWidget;

/*

schema {
  "type": "array",
  "items": {
    "type": "object",
    "properties": {
      < CHILD OBJECTS >
    }
  },
  "title": {
    "it": "Giorni di chiusura"
  }
}

*/
