import { useLocation } from "react-router";
import navigationUtils from "../utils/navigation";
import { useStores } from "./useStores";
import EntryModel from "../models/Entry";

export const useCurrentEntry = (): EntryModel | null => {
  const location = useLocation();
  const { entry } = useStores();

  const entryId = navigationUtils.fromRoutes.entryId(location.pathname);
  return entry.entries.find((entry) => entry.uid === entryId) || null;
};
