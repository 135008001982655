import styled from "styled-components";

export const StyledToolbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const StyledMarkerContainer = styled.div<{
  height: number;
  width: number;
}>`
  position: absolute;
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.width}px`};
  border-radius: 4px;
  background-color: ${(props) => props.theme.global.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledDeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
