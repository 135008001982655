/* @flow */
import React from "react";

import StyledWidgetReadOnlyEmptyValue from "./styled";
import { useTranslation } from "react-i18next";

const WidgetReadOnlyEmptyValue = () => {
  const { t } = useTranslation();
  return (
    <StyledWidgetReadOnlyEmptyValue>
      {t("components.widgetReadOnlyEmptyValue.label")}
    </StyledWidgetReadOnlyEmptyValue>
  );
};

export default WidgetReadOnlyEmptyValue;
