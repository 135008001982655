import React from "react";

import StyledCard from "./styled";
import { DynamicIcon } from "../Icon";

import type { DynamicIcon as TypeDynamicIcon } from "../../types";

type CardProps = {
  icon?: TypeDynamicIcon;
  title: string;
  logo?: string;
  onClick: () => any;
};

export const Card = ({
  icon,
  logo,
  title,
  onClick,
  ...othersProps
}: CardProps) => (
  <StyledCard.CardContainer onClick={onClick} {...othersProps}>
    {(icon || logo) && (
      <StyledCard.IconWrapper>
        {logo && <img src={logo} alt={title} />}
        {icon && !logo && (
          <DynamicIcon
            lib={icon.library}
            iconName={icon.name}
            size={"xlarge"}
          />
        )}
      </StyledCard.IconWrapper>
    )}
    <StyledCard.Title>{title}</StyledCard.Title>
  </StyledCard.CardContainer>
);
