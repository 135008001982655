export const getFirstDayOfMonth = () => {
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  return new Date(y, m, 1);
};

export const getLastDayOfMonth = () => {
  let date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth();
  return new Date(y, m + 1, 0);
};
