import { useLocation } from "react-router";
import { Tenant } from "../types";
import { useStores } from "./useStores";
import navigationUtils from "../utils/navigation";

export const useCurrentTenant = (): Tenant | null => {
  const location = useLocation();
  const { tenant } = useStores();

  const tenantId = navigationUtils.fromRoutes.tenantId(location.pathname);
  return tenant.tenants.find((tenant) => tenant.uid === tenantId);
};
