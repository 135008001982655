import React, { useCallback, useEffect, useState } from "react";
import Uppy from "@uppy/core";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import ITLocale from "@uppy/locales/lib/it_IT";
import { DashboardModal } from "@uppy/react";
import { isString } from "lodash-es";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import { inject, Observer } from "mobx-react";
import LocalStorageUpload from "../../utils/uppy/localStorageUploadPlugin";

import { getIsAudioSrc, getIsVideoSrc, getIsDocumentSrc } from "../../utils";
import keys from "../../config/keys";
import StyledFile from "./styled";
import { useModal } from "../../components/Modal";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import Audio from "../../components/Audio";
import Image from "../../components/Image";
import Video from "../../components/Video";
import Document from "../../components/Document";
import strings from "../../config/strings";

import fileUtils from "../../utils/file";
import cacheUtils from "../../utils/cache";

import type { Uppy as TypeUppy } from "@uppy/core";
import type { Stores, Tenant, Entry, FixMeLater } from "../../types";
import { ContentType } from "../../models/ContentType";
import EntryModel from "../../models/Entry";

const LOCAL_STORAGE_KEY_PREFIX = "upload_cache_";

type ModalFileUploaderProps = {
  id: string;
  isMultiple: boolean;
  onUploadCompleted: (result: any) => any;
  disabled?: boolean;
  allowedFileTypes: string[];
  tenantId: Tenant["uid"];
  contentTypeId: ContentType["uid"];
  entryId: EntryModel["uid"];
  isOnline: boolean;
};

type ModalFileUploaderState = {
  isModalOpen: boolean;
};

class ModalFileUploader extends React.Component<
  ModalFileUploaderProps,
  ModalFileUploaderState
> {
  state = {
    isModalOpen: false,
  };
  uppy: TypeUppy | null = null;

  constructor(props) {
    super(props);

    const { isMultiple = false, disabled = false, allowedFileTypes } = props;

    this.uppy = Uppy({
      autoProceed: false,
      allowMultipleUploads: true,
      restrictions: {
        maxNumberOfFiles: isMultiple ? null : 1,
        allowedFileTypes,
      },
      locale: ITLocale,
      onBeforeUpload: (files: any) => {
        if (disabled) {
          return false;
        }
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOnline !== this.props.isOnline) {
      this.handleConnectionState();
    }
  }

  componentDidMount() {
    if (this.uppy) {
      // Register callback on Upload completed
      this.uppy.on("complete", this.handleUploadCompleted);
      this.handleConnectionState();
    }
  }

  handleConnectionState = () => {
    if (this.uppy) {
      try {
        if (this.props.isOnline) {
          this.uppy.iteratePlugins((plugin) => {
            if (plugin.id === "LocalStorageUpload") {
              // $FlowFixMe
              this.uppy.removePlugin(plugin);
            }
          });
          // $FlowFixMe
          this.uppy.use(AwsS3Multipart, {
            companionUrl: keys.PRESIGN_FILE_S3_URL,
          });
        } else {
          this.uppy.iteratePlugins((plugin) => {
            if (plugin.id === "AwsS3Multipart") {
              // $FlowFixMe
              this.uppy.removePlugin(plugin);
            }
          });
          // $FlowFixMe
          this.uppy.use(LocalStorageUpload, {
            fieldId: this.props.id,
            entryId: this.props.entryId,
            localStorageKey: `${LOCAL_STORAGE_KEY_PREFIX}${this.props.entryId}_${this.props.id}`,
          });
        }
      } catch (e) {}
    }
  };

  handleUploadCompleted = (result) => {
    this.setModalOpen(false);
    this.props.onUploadCompleted(result);
  };

  componentWillUnmount() {
    this.uppy && this.uppy.close();
  }

  setModalOpen = (isOpen) => {
    this.setState({
      isModalOpen: isOpen,
    });
  };

  render() {
    const { id, disabled = false } = this.props;
    return (
      <div>
        <DashboardModal
          id={`modal-file-uploader-${id}`}
          uppy={this.uppy}
          closeModalOnClickOutside
          open={this.state.isModalOpen}
          onRequestClose={() => this.setModalOpen(false)}
        />
        <StyledFile.ButtonUpload
          type={"button"}
          disabled={disabled}
          onClick={() => this.setModalOpen(true)}
        >
          <Icon type={"upload"} size={"small"} />
          <span>{strings.widgets.file.upload}</span>
        </StyledFile.ButtonUpload>
      </div>
    );
  }
}

type FileSrc = string;

const getFilesSrcValue = (
  value?: FileSrc[],
  defaultValue?: FileSrc | FileSrc[]
) => {
  let initialValue = value != null ? value : defaultValue;
  if (!Array.isArray(initialValue)) {
    initialValue = initialValue == null ? [] : [initialValue];
  }
  return initialValue;
};

type Props = {
  id: string;
  name?: string;
  onBlur?: (event: any) => any;
  onChange?: (files: any) => any;
  value?: FileSrc[];
  defaultValue?: FileSrc[];
  autoFocus?: boolean;
  isMultiple?: boolean;
  readOnly?: boolean;
  allowedFileTypes: string[];
  isOnline?: boolean;
  tenantId: Tenant["uid"];
  contentTypeId: ContentType["uid"];
  entryId: EntryModel["uid"];
  appIsOnline?: boolean;
};

const FileWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue,
  autoFocus = false,
  isMultiple = false,
  readOnly = false,
  allowedFileTypes,
  // handleCacheUpload,
  tenantId,
  contentTypeId,
  entryId,
  appIsOnline = true,
}: Props) => {
  const { openModal } = useModal();
  const [localFiles, setLocalFiles] = useState([]);

  useEffect(() => {
    const rawCacheData = cacheUtils.getItem(
      `${LOCAL_STORAGE_KEY_PREFIX}${entryId}_${id}`
    );
    if (rawCacheData) {
      setLocalFiles(rawCacheData[id]);
    }
  }, [setLocalFiles]);

  const handleThumbClick = useCallback(
    (Component) => {
      openModal({
        content: Component,
      });
    },
    [openModal]
  );

  const renderFilePreview = (
    fileSrc: any,
    fileId: string,
    isCached: boolean = false
  ) => {
    if (fileSrc.length === 0) {
      return null;
    }
    let FileComponent = Image;
    let fileComponentProps = {
      src: fileSrc,
      alt: undefined,
    };
    if (getIsAudioSrc(fileSrc)) {
      FileComponent = Audio;
    } else if (getIsVideoSrc(fileSrc)) {
      FileComponent = Video;
    } else if (getIsDocumentSrc(fileSrc)) {
      FileComponent = Document;
    } else {
      fileComponentProps.alt = name || id;
    }
    return (
      <StyledFile.ThumbContainer
        style={{ position: "relative" }}
        key={String(fileId)}
      >
        <StyledFile.ThumbContent
          onClick={() =>
            !getIsDocumentSrc(fileSrc) &&
            handleThumbClick(<FileComponent {...fileComponentProps} />)
          }
        >
          <FileComponent {...fileComponentProps} />
        </StyledFile.ThumbContent>
        <StyledFile.ThumbAction>
          {!readOnly && (
            <StyledFile.ThumbRemoveButton
              type={"button"}
              onClick={() => handleDeleteFile(fileSrc, fileId, isCached)}
            >
              <Icon type={"close"} size={"xsmall"} color={"white"} />
            </StyledFile.ThumbRemoveButton>
          )}
        </StyledFile.ThumbAction>
        {isCached && (
          <StyledFile.CacheIconContainer>
            <Icon type={"sdStorage"} size={"small"} />
          </StyledFile.CacheIconContainer>
        )}
      </StyledFile.ThumbContainer>
    );
  };

  const handleDeleteFile = useCallback(
    (src: string, fileId: string, isCached: boolean) => {
      if (isCached) {
        const localFileList = localFiles.filter(
          (localFile) => localFile.id !== fileId
        );
        setLocalFiles(localFileList);
        if (localFileList.length === 0) {
          cacheUtils.removeItem(`${LOCAL_STORAGE_KEY_PREFIX}${entryId}_${id}`);
        } else {
          cacheUtils.setItem(
            `${LOCAL_STORAGE_KEY_PREFIX}${entryId}_${id}`,
            localFileList
          );
        }
      } else if (value) {
        const newFileUrlList = value.filter((fileSrc) => fileSrc !== src);
        if (newFileUrlList.length === 0) {
          newFileUrlList.push("");
        }

        // Create fake event handled by Formik
        const event = {
          target: {
            id,
            name,
            type: "file",
            files: newFileUrlList,
          },
        };
        onChange && onChange(event);
      }
    },
    [value, localFiles, setLocalFiles, onChange]
  );

  const handleUploadCompleted = useCallback(
    (result) => {
      const fileUrlList = value || [],
        localFileList = localFiles;
      let filesOnCache = false;

      // For each file uploaded, extract the public URL
      result.successful.forEach((fileMeta) => {
        if (fileMeta.response.uploadURL) {
          fileUrlList.push(fileMeta.uploadURL.split("?").shift());
        } else {
          localFileList.push(fileMeta.response);
          filesOnCache = true;
        }
      });

      if (filesOnCache) {
        setLocalFiles(localFileList);
        //handleCacheUpload(tenantId, contentTypeId, entryId, id, fileUrlList);
      }

      // Create fake event handled by Formik
      const event = {
        target: {
          id,
          name,
          type: "file",
          files: fileUrlList,
        },
      };
      onChange && onChange(event);
    },
    [value, localFiles, onChange, setLocalFiles]
  );

  const isDisabledUpload =
    (((value &&
      Array.isArray(value) &&
      value.filter((src) => src.length > 0).length > 0) ||
      (localFiles && localFiles.length > 0)) &&
      !isMultiple) ||
    readOnly;

  // TODO: Remove, for debug purpose in production
  if (value && !Array.isArray(value)) {
    console.log("FileWidget", value);
  }

  return (
    <StyledFile.Container>
      <input type={"hidden"} id={id} name={name} />

      <StyledFile.FilePreviewContainer readOnly={readOnly}>
        {value && value.length > 0 && Array.isArray(value)
          ? value.map((fileSrc) => renderFilePreview(fileSrc, fileSrc))
          : value && value.length > 0
          ? renderFilePreview(value, value)
          : null}
        {localFiles &&
          localFiles.map((data) => renderFilePreview(data.src, data.id, true))}
        {(!value ||
          (Array.isArray(value) &&
            value.filter((src) => src.length > 0).length === 0)) &&
          (!localFiles || (localFiles && localFiles.length === 0)) &&
          isDisabledUpload && (
            <StyledFile.FilePlaceholder>
              <Icon type={"noFile"} size={"small"} />
              <span>{strings.widgets.file.noFileUploaded}</span>
            </StyledFile.FilePlaceholder>
          )}
        {!isDisabledUpload && (
          <ModalFileUploader
            id={id}
            onUploadCompleted={handleUploadCompleted}
            isMultiple={isMultiple}
            disabled={isDisabledUpload}
            allowedFileTypes={allowedFileTypes}
            tenantId={tenantId}
            contentTypeId={contentTypeId}
            entryId={entryId}
            isOnline={appIsOnline}
          />
        )}
      </StyledFile.FilePreviewContainer>
    </StyledFile.Container>
  );
};

FileWidget.mapSchemaToProps = (schema: FixMeLater, uiSchema: FixMeLater) => {
  let props: FixMeLater = {};
  props.defaultValue = Array.isArray(uiSchema["ui:emptyValue"])
    ? uiSchema["ui:emptyValue"]
    : [];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.readOnly = schema.readOnly;
  return props;
};

export default FileWidget;

/*

schema: {
  "type": "array",
  "items": {
    "type": "string",
    "format": "data-url"
  },
  "order": 6,
  "title": {
    "it": "..."
  },
  "uniqueItems": true
},

ui-schema: {
  "ui:widget":"data-url",
  "ui:allowedFileTypes":["image/*"]
}

*/
