import React, { useMemo } from "react";

export type AlertPosition =
  | "topLeft"
  | "topCenter"
  | "topRight"
  | "middleLeft"
  | "middle"
  | "middleRight"
  | "bottomLeft"
  | "bottomCenter"
  | "bottomRight";

export const getStyles = (position: AlertPosition) => {
  const initialStyles = { position: "fixed" };
  switch (position) {
    case "topLeft":
      return {
        top: 0,
        left: 0,
        ...initialStyles,
      };
    case "topRight":
      return {
        top: 0,
        right: 0,
        ...initialStyles,
      };
    case "middleLeft":
      return {
        bottom: "50%",
        left: 0,
        ...initialStyles,
      };
    case "middle": {
      return {
        bottom: "50%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        ...initialStyles,
      };
    }
    case "middleRight":
      return {
        bottom: "50%",
        right: 0,
        ...initialStyles,
      };

    case "bottomLeft":
      return {
        bottom: 0,
        left: 0,
        ...initialStyles,
      };
    case "bottomCenter":
      return {
        bottom: 0,
        left: "50%",
        transform: "translate(-50%, 0%)",
        ...initialStyles,
      };
    case "bottomRight":
      return {
        right: 0,
        bottom: 0,
        ...initialStyles,
      };
    case "topCenter":
    default:
      return {
        top: 0,
        left: "50%",
        transform: "translate(-50%, 0%)",
        ...initialStyles,
      };
  }
};

type AlertWrapperProps = {
  children?: any;
  position?: AlertPosition;
  style?: any;
};

const AlertWrapper = ({
  children,
  position = "topRight",
  style = {},
  ...props
}: AlertWrapperProps) => {
  const styles = useMemo(() => getStyles(position), [position]);

  return (
    <div style={{ ...styles, ...style }} {...props}>
      {children}
    </div>
  );
};

export default AlertWrapper;
