import { observer } from "mobx-react";
import React, { useContext, useEffect } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  useLocation,
} from "react-router-dom";
import { ScreenContainer } from "../../components/ScreenContainer";
import { useStores } from "../../hooks/useStores";
import { Sidebar } from "../Sidebar";
import navigationUtils from "../../utils/navigation";
import { TenantList } from "../TenantList";
import { ProfileScreen } from "../Profile";
import { ContentTypeListScreen } from "../ContentTypeList";
import { Spinner } from "../../components/Spinner";
import { ContentTypeDetailScreen } from "../ContentTypeDetail";
import { EntryDetailScreen } from "../EntryDetail";
import { AbilityContext } from "../../context/can";
import { TenantSettings } from "../TenantSettings";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

type Props = {};

export const CmsScreen: React.FC<Props> = observer(() => {
  const { auth, tenant, app } = useStores();
  const ability = useContext(AbilityContext);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    if (auth.isLoggedIn) {
      tenant.fetchTenants();
    }
  }, []);

  useEffect(() => {
    const tenantId = navigationUtils.fromRoutes.tenantId(location.pathname);

    if (tenantId) {
      const currentTenant = tenant.tenants.find(
        (tenant) => tenant.uid === tenantId
      );
      if (currentTenant) {
        ability.update(currentTenant.role.acls.rules);
        tenant.initialize();
        app.setApplicationConfiguration(currentTenant.configuration);
      }
    }
  }, [location, tenant, ability, app]);

  if (!auth.isLoggedIn) {
    return <Redirect to={navigationUtils.routes.auth.login()} />;
  }

  // useEffect(() => {
  //   resetStores();
  //   setApplicationConfiguration();
  //   fetchTenants();
  // }, [resetStores, fetchTenants, setApplicationConfiguration]);

  // const handleTenantClick = useCallback(
  //   (tenantName) => {
  //     navigationUtils.goTo(
  //       { history, location },
  //       navigationUtils.routes.contentType.list(tenantName)
  //     );
  //   },
  //   [history, location]
  // );

  // if (tenants && tenants.length === 1) {
  //   return (
  //     <Redirect to={navigationUtils.routes.contentType.list(tenants[0].name)} />
  //   );
  // }

  return (
    <ScreenContainer>
      <Sidebar />
      {tenant.isFetching ? (
        <Spinner size={150} />
      ) : (
        <>
          <Route
            path={navigationUtils.routes.user.profile()}
            component={ProfileScreen}
          />
          <Route
            exact
            path={navigationUtils.routes.entry.findRoute}
            component={EntryDetailScreen}
          />
          <Route
            exact
            path={navigationUtils.routes.entry.listRoute}
            component={ContentTypeDetailScreen}
          />
          <Route
            exact
            path={navigationUtils.routes.contentType.listRoute()}
            component={ContentTypeListScreen}
          />
          <Route
            exact
            path={navigationUtils.routes.tenant.list()}
            component={TenantList}
          />
          <Route
            exact
            path={navigationUtils.routes.tenant.settingsRoute}
            component={TenantSettings}
          />
          <Route exact path={navigationUtils.routes.cms.main()}>
            <Redirect to={navigationUtils.routes.tenant.list()} />
          </Route>
        </>
      )}
    </ScreenContainer>
  );
});
