import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import keys from "../../config/keys";
import { useAlert } from "../../components/Alert";
import type { AlertSeverity } from "../../components/Alert";
import type { Stores, Timestamp } from "../../types";
import { useStores } from "../../hooks/useStores";

type OwnProps = {};

type AlertEvent = {
  id: string;
  severity: AlertSeverity;
  message: string;
  createdAt: Timestamp;
  expiresIn: number;
};

// type StoresProps = {
//   alertEvents: AlertEvent[];
// };

// type Props = OwnProps & StoresProps;

// const mapStoresToProps = (stores: Stores): StoresProps => ({
//   alertEvents: stores.log.logs.map(
//     ({ id, severity, message, createdAt, payload }) => ({
//       id,
//       severity,
//       message:
//         keys.IS_DEV && payload ? `${message}: ${payload.message}` : message,
//       createdAt,
//       expiresIn: 5000,
//     })
//   ),
// });

let previousEventIds = [];

const AlertRebound = observer(() => {
  const { log } = useStores();
  const addAlert = useAlert();
  useEffect(() => {
    log.logs.forEach((alertEvent) => {
      const expiresIn = 5000;
      if (
        !(
          expiresIn != null && Date.now() - alertEvent.createdAt >= expiresIn
        ) &&
        !previousEventIds.includes(alertEvent.id)
      ) {
        previousEventIds = [...previousEventIds, alertEvent.id];
        addAlert(alertEvent.message, alertEvent.severity);
        setTimeout(() => {
          previousEventIds = previousEventIds.filter(
            (id) => id !== alertEvent.id
          );
        }, expiresIn);
      }
    });
  }, [log, addAlert]);

  return <React.Fragment />;
});

export default AlertRebound;
