/* https://github.com/react-bootstrap/react-bootstrap/blob/master/src/DropdownItem.js */
import React, { useContext } from "react";
import useEventCallback from "@restart/hooks/useEventCallback";

import StyledDropdownItem from "./styled";
import SelectableContext, { makeEventKey } from "../Dropdown/SelectableContext";

interface BsPrefixAndClassNameOnlyProps {
  bsPrefix?: string;
  className?: string;
}

interface BsPrefixProps<As extends React.ElementType = React.ElementType>
  extends BsPrefixAndClassNameOnlyProps {
  as?: As;
}
type SelectCallback = (
  eventKey: string | null,
  e: React.SyntheticEvent<unknown>,
) => void;
type BsPrefixPropsWithChildren<
  As extends React.ElementType = React.ElementType
> = React.PropsWithChildren<BsPrefixProps<As>>;

interface DropdownItemProps extends BsPrefixPropsWithChildren {
  active?: boolean;
  disabled?: boolean;
  eventKey?: string;
  href?: string;
  onClick?: React.MouseEventHandler<this>;
  onSelect?: SelectCallback;
}

type Props = {
  /**
   * Highlight the menu item as active.
   */
  active?: boolean,

  /**
   * Disable the menu item, making it unselectable.
   */
  disabled?: boolean,

  /**
   * Value passed to the `onSelect` handler, useful for identifying the selected menu item.
   */
  eventKey?: any,

  /**
   * HTML `href` attribute corresponding to `a.href`.
   */
  href?: string,

  /**
   * Callback fired when the menu item is clicked.
   */
  onClick?: (e: any) => any,

  /**
   * Callback fired when the menu item is selected.
   */
  onSelect?: (eventKey: any, event: Object) => any,

  as?: any,

  children?: any,
};

const DropdownItem = React.forwardRef(
  (
    {
      className,
      children,
      eventKey,
      disabled = false,
      href,
      onClick,
      onSelect,
      active: propActive,
      as: Component = StyledDropdownItem,
      ...props
    }: DropdownItemProps,
    ref
  ) => {
    const onSelectCtx = useContext(SelectableContext);

    // @ts-ignore
    const key = makeEventKey(eventKey, href);

    const active = propActive;
    const handleClick = useEventCallback((event) => {
      if (disabled) return;
      if (onClick) onClick(event);
      if (onSelectCtx) onSelectCtx(key, event);
      if (onSelect) onSelect(key, event);
    });

    return (
      <Component
        {...props}
        ref={ref}
        href={href}
        disabled={disabled}
        className={`${active ? "active" : ""} ${disabled ? "disabled" : ""}`}
        onClick={handleClick}
      >
        {children}
      </Component>
    );
  }
);

export default DropdownItem;
