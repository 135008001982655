import styled from "styled-components";
import { transparentize } from "polished";
import BackgroundImage from "../../assets/img/ipad-820272_1920_mod_neg.jpg";
import Link from "../Link/styled";
import Tips from "../Tips/styled";

const MOBILE_FORM_WIDTH = "280px";

const StyledContainer = styled.div`
  height: 100vh;
  width: 100%;
  background: url(${BackgroundImage})
    ${(props) =>
      transparentize(0.05, props.theme.global.colors.sidebar.backgroundColor)};
  background-blend-mode: overlay;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;

  position: relative;

  @media (max-width: ${(props) => props.theme.global.breakpoints.large.value}) {
    & ${Tips.Container} {
      display: none;
    }
  }
`;

const StyledAuthFormContainer = styled.div`
  margin: auto;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => props.theme.global.colors.white};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);

  @media (min-width: ${(props) => props.theme.global.breakpoints.large.value}) {
    height: 100%;
    max-width: calc(5% + ${MOBILE_FORM_WIDTH});
    margin-right: 0;
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => props.theme.global.padding.large};

  & ${Link} {
    margin-top: ${(props) => props.theme.global.padding.medium};
    text-align: center;
  }
`;

const StyledAuthFormTitle = styled.h2`
  font-size: ${(props) => props.theme.global.typography.h2.fontSize};
  font-weight: ${(props) => props.theme.global.typography.h2.fontWeight};
  margin-bottom: ${(props) => props.theme.global.padding.medium};
`;

const StyledLogoWrapper = styled.div`
  img {
    width: 3.25rem;
    margin-bottom: ${(props) => props.theme.global.padding.medium};
  }
`;

export default {
  Container: StyledContainer,
  AuthFormContainer: StyledAuthFormContainer,
  ContentWrapper: StyledContentWrapper,
  AuthFormTitle: StyledAuthFormTitle,
  LogoWrapper: StyledLogoWrapper,
};
