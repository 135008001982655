import React from "react";
import StyledTips from "./styled";

import type { Tip as TypeTip } from "../../types";

type Props = {
  title: string,
  tips: TypeTip[],
};

export const Tips = ({ title, tips }: Props) => {
  const randomTipIndex = Math.floor(Math.random() * tips.length);

  return (
    <StyledTips.Container>
      <StyledTips.Title>{title}</StyledTips.Title>
      <StyledTips.TipImageWrapper>
        <StyledTips.TipImage
          src={tips[randomTipIndex].imageURL}
          alt={tips[randomTipIndex].description}
        />
      </StyledTips.TipImageWrapper>
      <StyledTips.TipDescription>
        {tips[randomTipIndex].description}
      </StyledTips.TipDescription>
    </StyledTips.Container>
  );
};

