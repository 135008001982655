/* @flow */
import styled from "styled-components";

const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;

const PopoverCover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

const ColorPreview = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
`;

export default {
  Swatch,
  Popover,
  PopoverCover,
  ColorPreview,
};
