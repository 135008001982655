import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { TransitionGroup } from "react-transition-group";

import { uuidv4 } from "../../utils/string";
import AlertTransition from "./Transition";
import AlertWrapper from "./Wrapper";
import StyledAlert from "./styled";

import type { AlertTransitionType } from "./Transition";
import type { AlertPosition } from "./Wrapper";
import type { Timestamp } from "../../types";

export type AlertSeverity = "info" | "success" | "warning" | "error";

type AlertEvent = {
  id: string;
  severity?: AlertSeverity;
  message: string;
  createdAt: Timestamp;
  expiresIn?: number;
};

type AlertContextType = (message: string, severity?: AlertSeverity) => any;

const AlertContext = React.createContext(
  (message: string, severity?: AlertSeverity) => {}
);

type AlertComponentProps = {
  severity?: AlertEvent["severity"];
  message: AlertEvent["message"];
  onClose?: () => any;
};

const AlertComponent = ({
  severity = "info",
  message,
  onClose,
  ...props
}: AlertComponentProps) => {
  return (
    <StyledAlert.Wrapper
      severity={severity}
      onClick={onClose}
      data-e2e={`alert-${severity}`}
      {...props}
    >
      <StyledAlert.Icon severity={severity} />
      {message}
    </StyledAlert.Wrapper>
  );
};

type AlertProviderProps = {
  children?: any;
  position?: AlertPosition;
  transition?: AlertTransitionType;
  expiresIn?: number;
};

let timerIds = [];

const Provider = ({
  children,
  position,
  transition,
  expiresIn = 5000,
  ...props
}: AlertProviderProps) => {
  const [alertEvents, setAlertEvents] = useState([]);
  const rootNode = useRef(null);
  useEffect(() => {
    return () => {
      timerIds.forEach(clearTimeout);
    };
  }, []);
  useEffect(() => {
    rootNode.current = document.createElement("div");
    document.body && document.body.appendChild(rootNode.current);
    return () => {
      rootNode.current &&
        document.body &&
        document.body.removeChild(rootNode.current);
    };
  }, []);
  const removeAlert = useCallback(
    (id: AlertEvent["id"]) => {
      setAlertEvents((events) => events.filter((event) => event.id !== id));
    },
    [setAlertEvents]
  );
  const addAlert = useCallback(
    (message: AlertEvent["message"], severity?: AlertSeverity) => {
      setAlertEvents((events) => {
        const alertEvent = {
          id: uuidv4(),
          severity: severity || "info",
          message,
          createdAt: Date.now(),
          expiresIn,
        };
        const isNewEvent = !events.find(({ id }) => id === alertEvent.id);
        if (isNewEvent && alertEvent.expiresIn != null) {
          const timerId = setTimeout(() => {
            removeAlert(alertEvent.id);
            timerIds = timerIds.filter((id) => id !== timerId);
          }, alertEvent.expiresIn);
          timerIds = [...timerIds, timerId];
          return [...events, alertEvent];
        }
        return events;
      });
    },
    [expiresIn, setAlertEvents, removeAlert]
  );

  return (
    <AlertContext.Provider value={addAlert}>
      {children}
      {rootNode &&
        rootNode.current &&
        createPortal(
          <AlertWrapper position={position} style={{}} {...props}>
            <TransitionGroup>
              {alertEvents.map(({ id, severity, message }) => (
                <AlertTransition type={transition} key={id}>
                  {/* ... appear={true}> */}
                  <AlertComponent
                    severity={severity}
                    message={message}
                    onClose={() => removeAlert(id)}
                  />
                </AlertTransition>
              ))}
            </TransitionGroup>
          </AlertWrapper>,
          rootNode.current
        )}
    </AlertContext.Provider>
  );
};

const useAlert = () => useContext(AlertContext);

export { Provider, useAlert, AlertComponent };
