import React, { useCallback } from "react";
import _ from "lodash-es";

import Input from "../../components/Input";
import WidgetReadOnlyEmptyValue from "../../components/WidgetReadOnlyEmptyValue";
import { FixMeLater, Locale } from "../../types";
import styled from "styled-components";

type Props = {
  id: string;
  name?: string;
  onBlur?: (event: any) => any;
  onChange?: (event: any) => any;
  value?: string | { string: string };
  type?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  isOnline?: boolean;
  placeholder?: string;
  localized: boolean;
  language?: Locale;
  languages?: Locale[];
};

const TextWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  type = "text",
  defaultValue = "",
  autoFocus = false,
  readOnly = false,
  isOnline,
  placeholder = "",
  localized,
  language,
  languages,
}: Props) => {
  const handleChange = useCallback(
    (event: any) => {
      if (readOnly) return;
      onChange && onChange(event);
    },
    [onChange, readOnly]
  );

  const handleChangeLocalizedValue = useCallback(
    (event, locale) => {
      if (readOnly) return;

      const customEvent = {
        target: {
          value: value || {},
        },
      };

      customEvent.target.value[locale] = event.target.value;

      onChange && onChange(customEvent);
    },
    [onChange, value, readOnly]
  );

  if (readOnly && !localized) {
    return <div>{value || defaultValue || <WidgetReadOnlyEmptyValue />}</div>;
  } else if (readOnly && localized) {
    return (
      <div>
        {value ? value[language] : defaultValue || <WidgetReadOnlyEmptyValue />}
      </div>
    );
  }

  if (localized) {
    return (
      <div>
        {languages.map((language) => (
          <div>
            <LanguageBadge>{language}</LanguageBadge>
            <Input
              type={type}
              id={`${id}-${language}`}
              name={`${name}-${language}`}
              onBlur={onBlur}
              onChange={(e) => handleChangeLocalizedValue(e, language)}
              value={value != null ? value[language] : defaultValue}
              autoFocus={autoFocus}
              readOnly={readOnly || !isOnline}
              placeholder={placeholder}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div>
      <Input
        type={type}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        value={value != null ? value.toString() : defaultValue}
        autoFocus={autoFocus}
        readOnly={readOnly || !isOnline}
        placeholder={placeholder}
      />
    </div>
  );
};

TextWidget.mapSchemaToProps = (schema: FixMeLater, uiSchema: FixMeLater) => {
  let props: FixMeLater = {};
  props.type = uiSchema["ui:widget"] === "password" ? "password" : "text";
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.readOnly = _.isNil(uiSchema["ui:readOnly"])
    ? schema.readOnly
    : uiSchema["ui:readOnly"];
  props.placeholder = uiSchema["ui:placeholder"];
  props.localized = !!schema.localized;
  return props;
};

export default TextWidget;

const LanguageBadge = styled.span`
  box-sizing: border-box;
  min-width: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: rgb(240, 242, 247);
  color: rgb(72, 84, 130);
  line-height: 1rem;
  font-size: 0.75rem;
  font-weight: 400;
  display: inline-block;
  padding: 0.15rem 0.5rem;
  margin-right: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
`;

/*

schema:  {
  "type": "string",
  "order": 1,
  "title": {
    "it": "Nome"
  },
  "localized": true
},

ui-schema: {
  "ui:widget": "text" | "password",
  "ui:emptyValue": ...
  "ui:readOnly": true | false,
  "ui:placeholder": ....
}

*/
