import React from "react";

import StyledButton from "./styled";
import { Spinner } from "../Spinner";
import Icon from "../Icon";

import type { Icon as TypeIcon } from "../../types";

type Props = {
  children?: any;
  model?: "primary" | "secondary";
  color?: string;
  size?: "small" | "medium" | "large";
  loading?: boolean;
  block?: boolean;
  icon?: TypeIcon;
  onClick?: (event: any) => any;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
};

const Button = ({
  loading,
  icon,
  children,
  model,
  onClick,
  disabled,
  type = "button",
  ...otherProps
}: Props) => {
  const ButtonComponent =
    model === "secondary"
      ? StyledButton.SecondaryButton
      : StyledButton.PrimaryButton;
  return (
    <ButtonComponent
      type={type}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {loading && <Spinner type={"spin"} />}
      {!loading && icon && <Icon type={icon} />}
      <span>{!loading && children}</span>
    </ButtonComponent>
  );
};

export default Button;
