/* https://github.com/react-bootstrap/react-bootstrap/blob/master/src/DropdownToggle.tsx */
import React, { useCallback } from "react";
import { useDropdownToggle } from "react-overlays/DropdownToggle";
import useMergedRefs from "@restart/hooks/useMergedRefs";
import invariant from 'invariant';

import StyleDropdownToggle from "./styled";

function useWrappedRefWithWarning(ref, componentName) {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const warningRef = useCallback(
    (refValue) => {
      invariant(
        refValue == null || !refValue.isReactComponent,
        `${componentName} injected a ref to a provided \`as\` component that resolved to a component instance instead of a DOM element. ` +
          'Use `React.forwardRef` to provide the injected ref to the class component as a prop in order to pass it directly to a DOM element',
      );
    },
    [componentName],
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMergedRefs(warningRef, ref);
}

type Props = {
  /**
   * An html id attribute, necessary for assistive technologies, such as screen readers.
   */
  id: string,

  split?: boolean,

  as?: any,

  className?: string,
  children?: any,
};

const DropdownToggle = React.forwardRef<Props, HTMLDivElement>(
  (
    {
      split,
      className,
      children,
      as: Component = StyleDropdownToggle,
      ...props
    }: Props,
    ref
  ) => {
    const [toggleProps, { toggle }] = useDropdownToggle();

    toggleProps.ref = useMergedRefs(
      toggleProps.ref,
      // @ts-ignore
      useWrappedRefWithWarning(ref, 'DropdownToggle'),
    );

    // This intentionally forwards size and variant (if set) to the
    // underlying component, to allow it to render size and style variants.
    return (
      <Component
        onClick={toggle}
        className={`${className ? className : ""}  ${split ? `split` : ""}`}
        {...toggleProps}
        {...props}
      >
        {children}
      </Component>
    );
  }
);

export default DropdownToggle;
