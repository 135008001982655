export const createBlobLinkAndDownload = (blob: Blob, fileName?: string) => {
  //  Create blob link to download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;

  link.setAttribute("target", "_blank");
  if (fileName) {
    link.setAttribute("download", `${fileName}.pdf`);
  }

  //  Append to html page
  document.body.appendChild(link);
  // Force download
  link.click();
  // Clean up and remove the link
  link.parentNode?.removeChild(link);
};
