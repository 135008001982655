import styled, { css } from "styled-components";

const horizontalSequenceStyle = css`
  display: flex;
`;

const verticalSequenceStyle = css`
  & > * {
    margin-bottom: ${(props) => props.theme.global.padding.medium};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Sequence = styled.div<{type?: string}>`
  ${(props) =>
    props.type === "horizontal"
      ? horizontalSequenceStyle
      : verticalSequenceStyle}
`;

export default Sequence;
