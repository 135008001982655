import { validate } from "./validation";
import { get, isString, isUndefined, omitBy, pick } from "lodash-es";

import Field from "../../components/Field";
import EntryModel from "../../models/Entry";
import CheckboxWidget from "../../widgets/CheckboxWidget";
import DateTimeWidget from "../../widgets/DateTimeWidget";
import DateWidget from "../../widgets/DateWidget";
import FieldRelationWidget from "../../widgets/FieldRelationWidget";
import FileWidget from "../../widgets/FileWidget";
import HiddenWidget from "../../widgets/HiddenWidget";
import NumberWidget from "../../widgets/NumberWidget";
import ObjectWidget from "../../widgets/ObjectWidget";
import RadioWidget from "../../widgets/RadioWidget";
import RangeWidget from "../../widgets/RangeWidget";
import SelectWidget from "../../widgets/SelectWidget";
import TelWidget from "../../widgets/TelWidget";
import TextWidget from "../../widgets/TextWidget";
import TextareaWidget from "../../widgets/TextareaWidget";
import TimeWidget from "../../widgets/TimeWidget";
import ColorWidget from "../../widgets/ColorWidget";

import type {
  JSONSchema,
  UISchema,
  ContentTypesACLRules,
  FilterableProperty,
  FixMeLater,
  Locale,
} from "../../types";
import { ContentType } from "../../models/ContentType";
import RolePickerWidget from "../../widgets/RolePickerWidget";
import ImageMapWidget from "../../widgets/ImageMapWidget";

// {
//   [schema["type"]: {
//     [uiSchema["ui:widget"] || schema["format"]]: Widget
//   }
// }
const widgetMap = {
  boolean: {
    default: CheckboxWidget,
    checkbox: CheckboxWidget,
    radio: RadioWidget,
    select: SelectWidget,
    // hidden: HiddenWidget
  },
  string: {
    default: TextWidget,
    text: TextWidget,
    password: TextWidget,
    tel: TelWidget,
    // email: EmailWidget,
    // regex: RegexWidget,
    // uuid: UuidWidget,
    hostname: TextWidget,
    ipv4: TextWidget,
    ipv6: TextWidget,
    // uri: URLWidget,
    // "uri-reference": URLWidget,
    // "uri-template": URLWidget,
    // url: URLWidget,
    "data-url": FileWidget,
    radio: RadioWidget,
    "radio-color": RadioWidget,
    select: SelectWidget,
    textarea: TextareaWidget,
    // hidden: HiddenWidget,
    date: DateWidget,
    time: TimeWidget,
    datetime: DateTimeWidget,
    "date-time": DateTimeWidget,
    "field-relation": FieldRelationWidget,
    "role-picker": RolePickerWidget,
    // "alt-date": AltDateWidget,
    // "alt-datetime": AltDateTimeWidget,
    // color: ColorWidget,
    // file: FileWidget,
    // "json-pointer": JsonWidget,
    // "relative-json-pointer": JsonWidget,
  },
  number: {
    default: NumberWidget,
    text: TextWidget,
    select: SelectWidget,
    // updown: "UpDownWidget",
    range: RangeWidget,
    radio: RadioWidget,
    "field-relation": FieldRelationWidget,
    // hidden: "HiddenWidget"
  },
  integer: {
    default: NumberWidget,
    text: TextWidget,
    select: SelectWidget,
    // updown: "UpDownWidget",
    range: RangeWidget,
    radio: RadioWidget,
    // hidden: "HiddenWidget"
  },
  array: {
    default: SelectWidget, // TODO: Checkbox
    object: ObjectWidget,
    select: SelectWidget,
    checkbox: SelectWidget,
    "image-map": ImageMapWidget,
    // checkboxes: "CheckboxesWidget",
    // files: "FileWidget",
    // hidden: "HiddenWidget"
  },
  object: {
    default: ObjectWidget,
  },
  single_file: {
    default: TextWidget, // UploadWidget
  },
  content_relation: {
    default: TextWidget, // ?
  },
  hidden: {
    default: HiddenWidget,
  },
  color: {
    default: ColorWidget,
  },
};

export const guessType = (value: any) => {
  if (Array.isArray(value)) {
    return "array";
  } else if (typeof value === "string") {
    return "string";
  } else if (value == null) {
    return "null";
  } else if (typeof value === "boolean") {
    return "boolean";
  } else if (!isNaN(value)) {
    return "number";
  } else if (typeof value === "object") {
    return "object";
  }
  // Default to string if we can't figure it out
  return "string";
};

export const getSchemaType = (schema: JSONSchema): string => {
  let { type } = schema;
  if (!type && schema.const) {
    return guessType(schema.const);
  }
  if (!type && schema.enum) {
    return "string";
  }
  if (type instanceof Array && type.length === 2 && type.includes("null")) {
    return type.find((type) => type !== "null");
  }
  return type;
};

export const getUISchemaType = (uiSchema: UISchema): string | undefined => {
  const uiType = uiSchema["ui:widget"];
  return uiType;
};

const getWidget = (schema: JSONSchema, uiSchema: UISchema): FixMeLater => {
  //return type: React.ComponentType<any, any>
  const type = getSchemaType(schema);
  let uiType = getUISchemaType(uiSchema);
  if (uiType == null && schema.enum != null) {
    uiType = "select";
  }
  if (!type || !widgetMap[type]) {
    return null;
  }
  let widget = null;
  if (type === "string" && schema.format != null) {
    widget = widgetMap[type][schema.format] || null;
  }
  if (uiType != null) {
    widget = widgetMap[type][uiType];
  }
  if (widget == null) {
    widget = widgetMap[type]["default"];
  }
  return widget || null;
};

const getFieldComponentPropsFromSchema = (
  name: string,
  schema: JSONSchema,
  uiSchema: UISchema
) => {
  return omitBy(pick(schema, ["readOnly"]), isUndefined);
};

const getWidgetSchema = (
  name: string,
  schema: JSONSchema,
  uiSchema: UISchema
): JSONSchema => {
  const type = getSchemaType(schema);
  return (
    (type === "array" && (uiSchema["ui:widget"] || "") !== "image-map"
      ? schema.items || schema
      : schema) || {}
  );
};

const getWidgetUISchema = (
  name: string,
  schema: JSONSchema,
  uiSchema: UISchema
): UISchema => {
  const type = getSchemaType(schema);
  if (type !== "array") {
    return uiSchema || {};
  }
  if (get(schema, "items.type") === "object") {
    return uiSchema.items || uiSchema || {};
  }
  return uiSchema.items || uiSchema || {};
};

const getWidgetComponentPropsFromSchema = (
  name: string,
  schema: JSONSchema,
  uiSchema: UISchema,
  language: Locale
) => {
  const widget = getWidget(schema, uiSchema);
  const mapSchemaToProps =
    // $FlowFixMe
    widget != null && widget.mapSchemaToProps
      ? widget.mapSchemaToProps
      : (schema, uiSchema, language) => ({});
  const props = mapSchemaToProps(schema, uiSchema, language) || {};
  return omitBy(props, isUndefined);
};

const getFieldComponentPropsFromParentSchema = (
  childName: string,
  parentSchema: JSONSchema
) => {
  const required = !!(
    parentSchema.required &&
    parentSchema.required.includes(childName.split(/\./).pop())
  );
  return omitBy(
    {
      required,
    },
    isUndefined
  );
};

const getWidgetComponentPropsFromParentSchema = (
  childName: string,
  parentSchema: JSONSchema
) => {
  return omitBy(pick(parentSchema, ["readOnly"]), isUndefined);
};

export const getComponentsFromSchema = (
  name: string,
  schema: JSONSchema,
  uiSchema: UISchema,
  parentSchema: JSONSchema,
  contentTypes?: ContentType[],
  contentTypesACL?: ContentTypesACLRules,
  language?: Locale
) => {
  const schemaType = getSchemaType(schema);
  const fieldName = name;
  const fieldSchema = schema || {};
  const fieldUISchema = uiSchema || {};
  const widgetSchema = getWidgetSchema(name, schema, uiSchema);
  const widgetUISchema = getWidgetUISchema(name, schema, uiSchema);
  const WidgetComponent = getWidget(widgetSchema, widgetUISchema);
  const FieldComponent = Field;
  const fieldType = schemaType === "array" ? "array" : "single";

  const widgetComponentProps: FixMeLater = {
    ...getWidgetComponentPropsFromParentSchema(name, parentSchema),
    ...getWidgetComponentPropsFromSchema(
      name,
      widgetSchema,
      widgetUISchema,
      language
    ),
  };

  let fieldComponentProps: FixMeLater = {
    type: fieldType,
    schema: fieldSchema,
    uiSchema: fieldUISchema,
    ...getFieldComponentPropsFromParentSchema(name, parentSchema),
    ...getFieldComponentPropsFromSchema(name, schema, uiSchema),
  };

  if (WidgetComponent === SelectWidget && fieldSchema.uniqueItems === true) {
    fieldComponentProps.type = "single";
    widgetComponentProps.isMultiple = true;
    widgetComponentProps.uniqueItems = true;
  }
  if (
    fieldType === "array" &&
    WidgetComponent === FileWidget &&
    fieldSchema.uniqueItems === true
  ) {
    fieldComponentProps.type = "single";
    widgetComponentProps.isMultiple =
      !fieldUISchema["ui:maxNumberOfFiles"] ||
      (fieldUISchema["ui:maxNumberOfFiles"] &&
        fieldUISchema["ui:maxNumberOfFiles"] > 1);
    widgetComponentProps.uniqueItems = true;
    widgetComponentProps.allowedFileTypes = fieldUISchema[
      "ui:allowedFileTypes"
    ] || ["application/octet-stream"];
  }
  if ([FieldRelationWidget, ObjectWidget].includes(WidgetComponent)) {
    widgetComponentProps.contentTypes = contentTypes;
    widgetComponentProps.contentTypesACL = contentTypesACL;
  }

  const fieldValidation = (value: any) => {
    const widgetValidationError =
      WidgetComponent != null &&
      // $FlowFixMe
      typeof WidgetComponent.validation === "function" &&
      WidgetComponent.validation(value, schema, uiSchema, parentSchema);
    if (isString(widgetValidationError)) {
      return widgetValidationError;
    }
    return validate(value, name, schema, uiSchema, parentSchema);
  };

  if (!WidgetComponent) {
    console.warn(
      `No widget component found to render field ${name} with schema`,
      schema
    );
  }

  return {
    fieldComponent: FieldComponent,
    fieldComponentProps: fieldComponentProps,
    fieldValidation: fieldValidation,
    widgetComponent: WidgetComponent,
    widgetComponentProps: widgetComponentProps,
    widgetSchema,
    widgetUISchema,
  };
};

export const getChildPropertySchema = (
  schema: JSONSchema,
  path: string
): JSONSchema => {
  const steps = path.split(".").filter(Boolean);
  let childSchema = schema;
  steps.forEach((step) => {
    childSchema = get(childSchema, "properties", childSchema);
    childSchema = get(childSchema, step, {});
  });
  return childSchema;
};

export const getContentTypeFilterableProperties = (
  schema: JSONSchema,
  uiSchema: UISchema,
  entry?: EntryModel
): FilterableProperty[] => {
  const filterablePathnames = uiSchema["ui:filterable"] || [];
  return filterablePathnames.map((pathname) => {
    const propertySchema = getChildPropertySchema(schema, pathname);
    const propertyUISchema = get(uiSchema, pathname, {});
    let data = get(entry && entry.asJson, `data.${pathname}`);
    if (propertySchema.enum != null && propertySchema.enumNames != null) {
      const enumIndex = propertySchema.enum.findIndex(
        (key, index) => String(key) === String(data)
      );
      if (enumIndex !== -1 && enumIndex < propertySchema.enumNames.length) {
        data = propertySchema.enumNames[enumIndex];
      }
    }
    return {
      name: pathname,
      title: propertySchema != null ? propertySchema.title : pathname,
      data,
      schema: propertySchema,
      uiSchema: propertyUISchema,
    };
  });
};
