import styled from "styled-components";
import StyledButton from "../Button/styled";

const StyledInput = styled.input<{active?: boolean}>`
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: none;

  padding: ${(props) => props.theme.global.padding.xsmall};
  padding-right: ${(props) =>
    props.active
      ? `calc( ${props.theme.global.padding.medium} + ${props.theme.global.padding.xsmall} )`
      : "0em"};
  transition: border-color
    ${(props) => props.theme.global.animation.fade.duration} ease;
  border: ${(props) => props.theme.global.border.size.xsmall} solid
    ${(props) => props.theme.global.colors.border.light};
  border-radius: ${(props) => props.theme.global.border.radius.small};
  background-color: transparent;

  &:hover {
    border-color: ${(props) => props.theme.global.colors.border.hover.light};
  }
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.global.colors.brand};
  }
  &::-moz-focus-inner,
  &::-moz-focus-outer {
    border: none;
  }
  width: 100%;
  transition: width ${(props) => props.theme.global.animation.fade.duration}
    ease;

  @media (min-width: ${(props) =>
      props.theme.global.breakpoints.medium.value}) {
    width: ${(props) => (props.active ? "200px" : "100px")};
  }
`;

const StyledContainer = styled.div<{active?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${(props) => props.theme.global.colors.white};

  transition: border-color
    ${(props) => props.theme.global.animation.fade.duration} ease;
  border: ${(props) => props.theme.global.border.size.xsmall} solid
    ${(props) => props.theme.global.colors.border.normal};
  border-radius: ${(props) => props.theme.global.border.radius.small};
  box-shadow: ${(props) =>
    props.active ? props.theme.global.elevation.small : "none"};

  @media (max-width: ${(props) =>
      props.theme.global.breakpoints.medium.value}) {
    width: 100%;
  }

  ${StyledInput} {
    border: 0px;
    flex-grow: 1;
  }

  ${StyledButton.SecondaryButton} {
    position: absolute;
    right: calc(
      ${(props) => props.theme.global.padding.medium} +
        ${(props) => props.theme.global.padding.xxsmall}
    );
    top: 7px;
    border-right: 1px solid ${(props) => props.theme.global.colors.border.light};
    border-radius: 0px;
  }
`;

const StyledLabel = styled.label`
  padding: ${(props) => props.theme.global.padding.xsmall};
`;

export default {
  Input: StyledInput,
  Container: StyledContainer,
  Label: StyledLabel,
};
