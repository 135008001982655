export default {
  components: {
    form: {
      leavingDirtyFormWarning:
        "Hai modificato delle informazioni non ancora salvate, sei sicuro di procedere?",
    },
    widgetReadOnlyEmptyValue: "Campo non compilato",
  },
  screens: {
    auth: {
      username: "Username",
      password: "Password",
      submit: "Login",
      email: "Email",
      confirm: "Conferma",
      backToLogin: "Torna al login",
      lostPassword: "Ho dimenticato la password",
    },
    entryDetail: {
      save: "Salva",
    },
    entryList: {
      search: "Cerca",
    },
    userDetail: {
      email: "Email",
      role: "Ruolo",
    },
  },
  widgets: {
    file: {
      upload: "Carica",
      noFileUploaded: "Nessun file allegato",
    },
    fieldRelation: {
      emptyData: "Nessuna informazione selezionata",
    },
  },
};
