import React, { useCallback, useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { get } from "lodash-es";
import navigationUtils from "../../utils/navigation";
import Container from "../../components/Container";
import { EntryList } from "../../components/EntryList";
import DashboardContainer from "../../components/DashboardContainer";
import { getContentTypeFilterableProperties } from "../../utils/jsonSchema";

import type {
  ContentTypesACLRules,
  Entry,
  Stores,
  ItemAction,
  Tenant,
  User,
} from "../../types";
import { useStores } from "../../hooks/useStores";
import { useHistory, useLocation } from "react-router-dom";
import { ContentType } from "../../models/ContentType";
import { Spinner } from "../../components/Spinner";
import { useUserAbilities } from "../../hooks/useUserAbilities";

type Props = {};

export const ContentTypeDetailScreen: React.FC<Props> = observer(() => {
  // const initialize = async () => {
  //   if (tenantId) {
  //     const fetchs = [fetchContentTypes(tenantId)];

  //     if (contentTypeId) {
  //       fetchs.push(fetchEntries(tenantId, contentTypeId));
  //     }

  //     await Promise.all(fetchs);

  //     const tenantData = getTenant(tenantId);
  //     if (tenantData) {
  //       setTenant(tenantData);
  //       setApplicationConfiguration(tenantData.configuration);
  //     }

  //     setAuthToken(await storageServices.getItem("sessionToken"));
  //   }
  // };

  const { entry, tenant, auth } = useStores();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const tenantId = navigationUtils.fromRoutes.tenantId(location.pathname);
    const contentTypeId = navigationUtils.fromRoutes.contentTypeId(
      location.pathname
    );

    if (tenantId && contentTypeId) {
      entry.fetchEntries(tenantId, contentTypeId);
    }
  }, [location]);

  const tenantId = useMemo(
    () => navigationUtils.fromRoutes.tenantId(location.pathname),
    [location]
  );
  const contentTypeId = useMemo(
    () => navigationUtils.fromRoutes.contentTypeId(location.pathname),
    [location]
  );

  const currentTenant = useMemo(() => {
    return tenant.tenants.find((tenant) => tenant.uid === tenantId) || null;
  }, [tenant, tenantId]);

  const contentType = useMemo(() => {
    return (
      currentTenant?.contentTypes.find(
        (contentType) => contentType.uid === contentTypeId
      ) || null
    );
  }, [currentTenant, contentTypeId]);

  const userAbility = useUserAbilities();

  const handleAddClick = useCallback(() => {
    const customLink = get(
      contentType,
      "uiSchema['ui:links']['entry']['create']"
    );
    if (customLink) {
      history.push(customLink);
    } else {
      history.push(
        navigationUtils.routes.entry.create(tenantId, contentTypeId)
      );
    }
  }, [history, contentType, contentTypeId, tenantId]);

  const handleEditClick = useCallback(
    (entry) => {
      if (tenantId && contentTypeId) {
        history.push(
          navigationUtils.routes.entry.find(tenantId, contentTypeId, entry.uid)
        );
      }
    },
    [history, contentTypeId, tenantId]
  );

  const handleDeleteClick = useCallback(
    (delEntry) => {
      if (
        tenantId &&
        contentTypeId &&
        window.confirm(
          `Verrà cancellata la entry ${delEntry.uid} di content type ${contentTypeId}. Vuoi procedere?`
        )
      ) {
        entry.deleteEntry(tenantId, contentTypeId, delEntry.uid);
      }
    },
    [entry, contentTypeId, tenantId]
  );

  // const handleDocumentClick = useCallback(
  //   async (entryId) => {
  //     const entry = entries.find((entry) => entry.id === entryId);
  //     if (entry) {
  //       const entryData = getContentTypeFilterableProperties(
  //         get(contentType, "schema", {}),
  //         get(contentType, "uiSchema", {}),
  //         entry
  //       );

  //       await entry.export(entryData);
  //     }
  //   },
  //   [entries, contentType]
  // );

  const entryActionButtons: ItemAction[] = [
    {
      type: "update",
      action: handleEditClick,
      aclAction: "entries:read",
    },
    // {
    //   type: "print",
    //   action: handleDocumentClick,
    //   aclAction: "entries:print",
    // },
    {
      type: "delete",
      action: handleDeleteClick,
      aclAction: "entries:delete",
      disabled: false, //TODO: !isAppOnline,
    },
  ];

  let detailsComponent: JSX.Element | null = null;

  if (!contentType) {
    return <Spinner size={150} />;
  }

  if (
    contentType &&
    contentType.uiSchema &&
    contentType.uiSchema["ui:module"] === "bi"
  ) {
    detailsComponent = (
      <DashboardContainer
        contentType={contentType}
        authToken={auth.sessionToken}
        language={tenant.locale}
      />
    );
  } else {
    detailsComponent = (
      <EntryList
        contentTypeEntries={entry.entries}
        contentType={contentType}
        contentTypesACLRules={{}} //contentTypesACLRules
        entryActions={entryActionButtons}
        onAddButtonClick={handleAddClick}
        contentTypes={[]} //contentTypes
        user={auth.user}
        isAddButtonEnabled={true} // TODO: Is App Online?
        userAbility={userAbility}
        isLoading={tenant.isFetching || entry.isFetching}
        languages={currentTenant.configuration.languages}
      />
    );
  }

  return <Container>{detailsComponent}</Container>;
});
