import { makeAutoObservable } from "mobx";
import { merge } from "lodash-es";

import backendService from "../services/backend";
import FontModel from "../models/Font";
import baseTheme from "../themes/base";
import constants from "../config/constants";

import type { Theme, TenantConfiguration, Stores } from "../types";
import i18n from "../services/i18n";

export default class AppStore {
  stores: Stores;
  isInitialized: boolean = false;
  locale: string = i18n.language;
  fonts: FontModel[] = [];
  title: string = constants.APPLICATION_NAME;
  logo: string = constants.DEFAULT_ICON_URL;
  theme: Theme = baseTheme;

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  initialize = async (): Promise<void> => {
    try {
      this.setApplicationConfiguration();
    } catch (err) {
      this.stores.log.warning(`Errore nella inizializzazione AppStore`, err);
    }
    this.isInitialized = true;
  };

  // changeLocale = (locale: string) => {
  //   this.locale = locale;
  //   i18n.changeLanguage(locale);
  //   localStorage.setItem("i18nextLng", JSON.stringify(this.locale));
  // };

  destroy = async (): Promise<void> => {
    this.fonts = [];
    this.theme = baseTheme;
    this.title = constants.APPLICATION_NAME;
    this.logo = constants.DEFAULT_ICON_URL;
  };

  setApplicationConfiguration = async (
    tenantConfiguration?: TenantConfiguration
  ) => {
    this.title = tenantConfiguration
      ? tenantConfiguration.strings.title
      : constants.APPLICATION_NAME;

    this.logo =
      tenantConfiguration && tenantConfiguration.logo
        ? tenantConfiguration.logo
        : constants.DEFAULT_ICON_URL;

    this.theme = tenantConfiguration
      ? merge({ ...baseTheme }, tenantConfiguration.theme || {})
      : baseTheme;

    if (tenantConfiguration && tenantConfiguration.fonts) {
      const fonts = tenantConfiguration.fonts.map(
        (font) => new FontModel(font)
      );
      this.fonts = fonts;
      this.fonts.forEach((font) => font.load());
    }
  };

  get areFontsLoaded() {
    return this.fonts.length > 0 && this.fonts.every((font) => font.isLoaded);
  }
}
