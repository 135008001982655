import React from "react";
import { useTranslation } from "react-i18next";
import { ItemActions } from "../DashboardContainer";
import Dropdown from "../Dropdown";
import DropdownItem from "../DropdownItem";
import DropdownMenu from "../DropdownMenu";
import DropdownToggle from "../DropdownToggle";
import { DynamicIcon } from "../Icon";
import StyledDashboardItem from "./styled";

const ActionIcon = {
  EXPORT_PDF: "AiOutlineDownload",
};

type DashboardItemProps = {
  title: string;
  actions?: ItemActions[];
  item: any;
  doAction: (action: ItemActions, item: any) => void;
};

const DashboardItem: React.FC<DashboardItemProps> = ({
  children,
  title,
  actions,
  item,
  doAction,
}) => {
  const { t } = useTranslation();

  return (
    <StyledDashboardItem.Container>
      <StyledDashboardItem.Header>
        <StyledDashboardItem.HeadTitle>{title}</StyledDashboardItem.HeadTitle>
        <Dropdown drop="down" alignRight>
          <DropdownToggle variant="success" id={`dropdown-${title}`}>
            {/* @ts-ignore */}
            <DynamicIcon iconName="AiOutlineMore" lib="ai" />
          </DropdownToggle>
          <DropdownMenu>
            {actions.map((action) => (
              <DropdownItem key={action} onClick={() => doAction(action, item)}>
                <div style={{ display: "flex" }}>
                  <DynamicIcon
                    iconName={ActionIcon[action]}
                    lib="ai"
                    size="small"
                  />
                  {t(`components.dashboardItem.actions.${action}`)}
                </div>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </StyledDashboardItem.Header>
      <StyledDashboardItem.Body>{children}</StyledDashboardItem.Body>
    </StyledDashboardItem.Container>
  );
};

export default DashboardItem;
