import React, { useState } from "react";
import { get } from "lodash-es";

import { Can } from "@casl/react";
import EmptyState from "../EmptyState";
import Container from "../Container";
import Sequence from "../Sequence";
import DataTable from "../DataTable";
import EntryHeader from "../EntryHeader";
import EntryContent from "../EntryContent";
import Button from "../Button";
import SearchBar from "../SearchBar";
import _ from "lodash";
import EntryModel from "../../models/Entry";
import strings from "../../config/strings";
import { prettifyDate, fuzzyMatch } from "../../utils";
import {
  getContentTypeFilterableProperties,
  getComponentsFromSchema,
} from "../../utils/jsonSchema";

import type {
  ContentTypesACLRules,
  //ItemAction,
  //Entry,
  FilterableProperty,
  ItemAction,
  //FilterableProperty,
  JSONSchema,
  Locale,
  User,
} from "../../types";
import { useStores } from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { ContentType } from "../../models/ContentType";
import { Ability } from "@casl/ability";
import { VirtualizedTable } from "../VirtualizedTable";
import useDebounce from "../../hooks/useDebounce";

type EntryListProps = {
  contentTypeEntries: EntryModel[];
  contentType: ContentType | null;
  contentTypesACLRules: ContentTypesACLRules;
  entryActions: ItemAction[];
  isAddButtonEnabled?: boolean;
  onAddButtonClick?: () => any;
  contentTypes: ContentType[];
  user: User;
  userAbility: Ability | null;
  isLoading?: boolean;
  languages: Locale[];
};

const renderReadOnlyField = (
  key: string,
  property: FilterableProperty,
  parentSchema: JSONSchema,
  contentTypes: ContentType[],
  contentTypesACL: ContentTypesACLRules,
  language: Locale,
  languages: Locale[]
) => {
  const {
    widgetComponent: WidgetComponent,
    widgetComponentProps,
  } = getComponentsFromSchema(
    property.name,
    property.schema,
    property.uiSchema,
    parentSchema,
    contentTypes,
    contentTypesACL,
    language
  );
  if (WidgetComponent == null) return null;

  return (
    <WidgetComponent
      key={key}
      {...widgetComponentProps}
      id={property.name}
      name={property.name}
      value={property.data}
      readOnly={true}
      language={language}
      languages={languages}
    />
  );
};

export const EntryList: React.FC<EntryListProps> = ({
  contentTypeEntries,
  contentType,
  contentTypesACLRules,
  entryActions,
  isAddButtonEnabled = true,
  onAddButtonClick,
  contentTypes,
  user,
  userAbility,
  isLoading = false,
  languages,
}: EntryListProps) => {
  const { tenant } = useStores();
  const { t } = useTranslation();

  const [searchedText, setSearchedText] = useState("");
  const debouncedQuery = useDebounce(searchedText, 200);

  let filterablePropertiesByEntry: {
    string?: FilterableProperty[];
  } = {};
  contentTypeEntries.forEach((entry) => {
    filterablePropertiesByEntry[entry.uid] = getContentTypeFilterableProperties(
      get(contentType, "schema", {}),
      get(contentType, "uiSchema", {}),
      entry
    );
  });

  const filterableProperties: FilterableProperty[] = getContentTypeFilterableProperties(
    contentType != null ? contentType.schema : {},
    contentType != null ? contentType.uiSchema : {}
  );

  const filteredEntries = contentTypeEntries.filter(
    (entry) =>
      !debouncedQuery ||
      filterablePropertiesByEntry[entry.uid].some((property) =>
        // TODO: Improve when Data is localized
        fuzzyMatch(JSON.stringify(property.data), debouncedQuery)
      )
  );

  const sortedEntries = filteredEntries;

  const baseColumns = [
    {
      width: 60,
      flexGrow: 1,
      label: t("entries.createDate"),
      dataKey: "createdAt",
      render: (value: any, record: EntryModel, dataKey: string) =>
        prettifyDate(value),
    },
    // {
    //   width: 60,
    //   flexGrow: 1,
    //   label: t("entries.updateDate"),
    //   dataKey: "updatedAt",
    //   render: (value: any, record: EntryModel, dataKey: string) =>
    //     prettifyDate(value),
    // },
    {
      width: 40,
      flexGrow: 1,
      label: "",
      dataKey: "",
      render: (value: any, record: EntryModel, dataKey: string) => (
        <Sequence>
          {entryActions.map((entryAction) =>
            entryAction.aclAction ? (
              <Can
                I={entryAction.type}
                a={contentType?.slug}
                ability={userAbility}
                key={entryAction.type}
              >
                <Button
                  icon={entryAction.type}
                  model={"secondary"}
                  onClick={() => entryAction.action(record)}
                  disabled={entryAction.disabled ? entryAction.disabled : false}
                />
              </Can>
            ) : (
              <Button
                icon={entryAction.type}
                model={"secondary"}
                key={entryAction.type}
                onClick={() => entryAction.action(record)}
                disabled={entryAction.disabled ? entryAction.disabled : false}
              />
            )
          )}
        </Sequence>
      ),
    },
  ];

  const filtrableColumns = filterableProperties.map((col) => ({
    width: 80,
    flexGrow: 1,
    label: col.title[tenant.locale],
    dataKey: `data.${col.name}`,
    render: (value: any, record: EntryModel, dataKey: string) => {
      return renderReadOnlyField(
        record.uid,
        filterablePropertiesByEntry[record.uid].find(
          (property) => property.name === dataKey.split(".")[1]
        ),
        get(contentType, "schema", {}),
        contentTypes,
        contentTypesACLRules,
        tenant.locale,
        languages
      );
    },
  }));

  const columns = [...filtrableColumns, ...baseColumns];

  const data: EntryModel[] = sortedEntries;

  return (
    <Container>
      <EntryHeader.Header>
        <EntryHeader.Title
          icon={contentType && contentType.uiSchema["ui:icon"]}
          title={
            contentType && contentType.schema
              ? contentType.label[tenant.locale]
              : ""
          }
        />
        <EntryHeader.ActionContainer>
          <SearchBar
            placeholder={strings.screens.entryList.search}
            onChange={setSearchedText}
          />
          {onAddButtonClick && (
            <Can I={"write"} a={contentType?.slug} ability={userAbility}>
              <Button onClick={onAddButtonClick} disabled={!isAddButtonEnabled}>
                {t("entries.new")}
              </Button>
            </Can>
          )}
        </EntryHeader.ActionContainer>
      </EntryHeader.Header>
      <EntryContent>
        {!isLoading &&
          // <Can
          //   perform={"entries:list"}
          //   userRole={userRole}
          //   subject={contentType ? String(contentType.uid) : ""}
          //   acl={contentTypesACLRules}
          //   yes={() =>
          //     sortedEntries.length === 0 ? (
          //       <EmptyState reason={"noData"} />
          //     ) : (
          //       <DataTable columns={columns} data={data} />
          //     )
          //   }
          //   no={() => <EmptyState reason={"unauthorized"} />}
          // />
          (sortedEntries.length === 0 ? (
            <EmptyState reason={"noData"} />
          ) : (
            // <DataTable columns={columns} data={data} />
            <VirtualizedTable
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              cellDataGetter={({ columnData, dataKey, rowData }) =>
                _.get(rowData, dataKey)
              }
              columns={columns}
            />
          ))}
      </EntryContent>
    </Container>
  );
};
