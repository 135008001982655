import React, { useCallback, useEffect, useMemo, useState } from "react";

import StyledEntryList from "./styled";
import EntryHeader from "../EntryHeader";

import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import DashboardItem from "../DashboardItem";
import ChartRenderer from "../ChartRenderer";
import DashboardLayout from "./layout";

import keys from "../../config/keys";

import type { Locale } from "../../types";
import Container from "../Container";
import SelectWidget from "../../widgets/SelectWidget";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { getFirstDayOfMonth, getLastDayOfMonth } from "../../utils/date";
import { ContentType } from "../../models/ContentType";
import { DynamicIcon } from "../Icon";
import backendService from "../../services/backend";
import { prettifyDate } from "../../utils";
import ReactPDF from "@react-pdf/renderer";
import { TablePDF } from "../TablePDF";
import { formatColumnHeader } from "../../utils/chartRenderer";
import { createBlobLinkAndDownload } from "../../utils/download";

type DashboardContainerProps = {
  contentType: ContentType | null;
  authToken: string | null;
  language: Locale;
};

const defaultLayout = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 1,
  minH: 1,
});

export enum ItemActions {
  EXPORT_PDF = "EXPORT_PDF",
}

const DASHBOARD_ITEM_ACTIONS = {
  table: [ItemActions.EXPORT_PDF],
};

const DashboardContainer = ({
  contentType,
  authToken,
  language,
}: DashboardContainerProps) => {
  const [period, setPeriod] = useState<[Date, Date]>([
    getFirstDayOfMonth(),
    getLastDayOfMonth(),
  ]);
  const [activeDashboardKey, setActiveDashboardKey] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const cubejsApi = cubejs(authToken, {
    apiUrl: keys.STAZA_BI_ENDPOINT_URL,
  });

  const dashboards = useMemo(() => {
    return contentType?.schema
      ? Object.keys(contentType.schema.properties).map((key) => ({
          value: key,
          label: contentType.schema.properties[key].title[language],
        }))
      : [];
  }, [contentType, language]);

  const activeDashboard = useMemo(() => {
    return activeDashboardKey === null
      ? null
      : contentType?.schema.properties[activeDashboardKey];
  }, [contentType, activeDashboardKey]);

  const deserializeItem = useCallback(
    (i) => {
      const vizState = JSON.parse(i.vizState);

      if (activeDashboard?.timeDimension && period) {
        if (!vizState.query.timeDimensions) {
          vizState.query.timeDimensions = [];
        }

        vizState.query.timeDimensions.push({
          dimension: activeDashboard.timeDimension,
          // @ts-ignore
          dateRange: period.map((date) => prettifyDate(date)),
        });
      }

      if (activeFilters) {
        if (!vizState.query.filters) {
          vizState.query.filters = [];
        }
        vizState.query.filters = [...vizState.query.filters, ...activeFilters];
      }

      return {
        ...i,
        name: i.name[language],
        layout: JSON.parse(i.layout) || {},
        vizState,
      };
    },
    [language, activeDashboard, period, activeFilters]
  );

  const handleDoAction = useCallback(
    async (action: ItemActions, item: any) => {
      // https://cube.dev/docs/@cubejs-client-core#cubejs-api-load
      const resultSet = await cubejsApi.load(item.vizState.query);

      switch (action) {
        case ItemActions.EXPORT_PDF:
          const title = `${
            item.name
          } ${period[0].toDateString()}-${period[1].toDateString()}`;

          const headers = resultSet
            .tableColumns()
            .map(({ key }) => formatColumnHeader(key));
          const rows = resultSet.tablePivot().map((row) => Object.values(row));

          const blob = await ReactPDF.pdf(
            <TablePDF headers={headers} rows={rows} title={title} />
          ).toBlob();

          if (blob) {
            createBlobLinkAndDownload(blob);
          }
          break;
        default:
      }
    },
    [cubejsApi, period]
  );

  const mapDashboardItem = useCallback(
    (item) => {
      return (
        <div key={item.id} data-grid={defaultLayout(item)}>
          <DashboardItem
            key={item.id}
            item={item}
            title={item.name}
            actions={DASHBOARD_ITEM_ACTIONS[item.vizState?.chartType] || []}
            doAction={handleDoAction}
          >
            <ChartRenderer
              vizState={item.vizState}
              // timeDimension={activeDashboard?.timeDimension}
              // period={period}
              // filters={activeFilters}
            />
          </DashboardItem>
        </div>
      );
    },
    [period, activeDashboard, activeFilters]
  );

  const activeDashboardItems = useMemo(() => {
    let items = [];
    if (activeDashboard) {
      items = activeDashboard.configuration
        .map(deserializeItem)
        .map(mapDashboardItem);
    }
    return items;
  }, [activeDashboard, deserializeItem, mapDashboardItem]);

  useEffect(() => {
    if (activeDashboardKey === null && dashboards.length > 0) {
      setActiveDashboardKey(dashboards[0].value);
    }
  }, [dashboards, activeDashboardKey, setActiveDashboardKey]);

  useEffect(() => {
    if (activeDashboardKey) {
      // Check if dashboard has a default period
      const defaultPeriod =
        contentType.uiSchema[activeDashboardKey]?.defaultPeriod;

      if (defaultPeriod) {
        switch (defaultPeriod) {
          case "daily":
            setPeriod([new Date(), new Date()]);
            break;
          default:
        }
      }
    }
  }, [activeDashboardKey, contentType]);

  const handleChangeFilter = useCallback(
    (filterId, value) => {
      let updatedFilters = [...activeFilters];
      const filterData = activeDashboard.filters.find(
        (filter) => filter.id === filterId
      );
      if (filterData) {
        // Remove old filter by dimension
        updatedFilters = activeFilters.filter(
          (filter) => filter.dimension !== filterData.dimension
        );

        if (value) {
          updatedFilters.push({
            dimension: filterData.dimension,
            operator: filterData.operator,
            values: [value],
          });
        }
      }
      setActiveFilters(updatedFilters);
    },
    [activeFilters, setActiveFilters, activeDashboard]
  );

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Container>
        <EntryHeader.Header>
          <EntryHeader.Title
            icon={contentType && contentType.uiSchema["ui:icon"]}
            title={
              contentType && contentType.schema
                ? contentType.label[language]
                : ""
            }
          />
          <div style={{ minWidth: "200px", marginLeft: "1rem" }}>
            <SelectWidget
              options={dashboards}
              id={"dashboard-select"}
              onChange={setActiveDashboardKey}
              value={activeDashboardKey}
            />
          </div>
          <EntryHeader.ActionContainer>
            <div>
              {activeDashboard?.filters?.map((filter) => (
                <div style={{ minWidth: "200px", marginLeft: "1rem" }}>
                  <SelectWidget
                    optionsSource={
                      filter.entries
                        ? backendService.getEntries(
                            filter.entries.tenantId,
                            filter.entries.contentTypeId,
                            filter.entries.conditions
                          )
                        : undefined
                    }
                    optionValuesInterpolation={
                      filter.entries?.valuesInterpolation
                    }
                    optionLabelsInterpolation={
                      filter.entries?.labelsInterpolation
                    }
                    id={filter.id}
                    placeholder={filter.title[language]}
                    onChange={(value) => handleChangeFilter(filter.id, value)}
                    isClearable
                  />
                </div>
              ))}
            </div>
            {activeDashboard?.timeDimension && (
              <DateRangePicker
                onChange={setPeriod}
                value={period}
                clearIcon={null}
                calendarIcon={
                  <DynamicIcon lib={"ai"} iconName={"AiOutlineCalendar"} />
                }
              />
            )}
            {/* <Button icon={"syncAlt"} model={"secondary"} /> */}
          </EntryHeader.ActionContainer>
        </EntryHeader.Header>

        <StyledEntryList.ContentWrapper>
          <DashboardLayout>{activeDashboardItems}</DashboardLayout>
        </StyledEntryList.ContentWrapper>
      </Container>
    </CubeProvider>
  );
};
export default DashboardContainer;
