import React, { useCallback } from "react";

import Textarea from "../../components/Textarea";
import WidgetReadOnlyEmptyValue from "../../components/WidgetReadOnlyEmptyValue";
import { FixMeLater } from "../../types";

type Props = {
  id: string;
  name?: string;
  onBlur?: (event: any) => any;
  onChange?: (event: any) => any;
  value?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  isOnline?: boolean;
};

const TextareaWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue,
  autoFocus = false,
  readOnly = false,
  isOnline,
}: Props) => {
  const handleChange = useCallback(
    (event: any) => {
      if (readOnly) return;
      onChange && onChange(event);
    },
    [onChange, readOnly]
  );
  if (readOnly) {
    return <div>{value || defaultValue || <WidgetReadOnlyEmptyValue />}</div>;
  }
  return (
    <div>
      <Textarea
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        value={value != null ? value : defaultValue}
        autoFocus={autoFocus}
        readOnly={readOnly || !isOnline}
      />
    </div>
  );
};

TextareaWidget.mapSchemaToProps = (
  schema: FixMeLater,
  uiSchema: FixMeLater
) => {
  let props: FixMeLater = {};
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.readOnly = schema.readOnly;
  return props;
};

export default TextareaWidget;
