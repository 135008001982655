import styled from "styled-components";

const StyledTitleContaier = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const StyledActionContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: ${(props) => props.theme.global.padding.small};
  }

  @media (min-width: ${(props) =>
      props.theme.global.breakpoints.medium.value}) {
    & > * {
      margin-left: ${(props) => props.theme.global.padding.small};
    }
  }
`;

const StyledEntryHeader = styled.div<{bordered: boolean}>`
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.bordered &&
    `border-bottom: 1px solid ${props.theme.global.colors.border.normal};`}

  @media (min-width: ${(props) =>
    props.theme.global.breakpoints.medium.value}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center; 
    padding-top: ${(props) => props.theme.global.padding.medium};
    padding-bottom: ${(props) => props.theme.global.padding.small};
    padding-right: ${(props) => props.theme.global.padding.medium};
    padding-left: ${(props) => props.theme.global.padding.medium};
  }
`;

const StyledIconWrapper = styled.div`
  border-radius: ${(props) => props.theme.global.border.radius.medium};
  background-color: ${(props) => props.theme.global.colors.brand};
  padding: ${(props) => props.theme.global.padding.xxsmall};
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => props.theme.global.padding.xsmall};

  svg {
    width: 20px;
    color: ${(props) => props.theme.global.colors.white};
  }
`;

const StyledTitle = styled.h2`
  font-size: ${(props) => props.theme.global.typography.h2.fontSize};
  font-weight: ${(props) => props.theme.global.typography.h2.fontWeight};
  line-height: ${(props) => props.theme.global.typography.lineHeight.title};
`;

export default {
  TitleContaier: StyledTitleContaier,
  IconWrapper: StyledIconWrapper,
  Title: StyledTitle,
  ActionContainer: StyledActionContainer,
  EntryHeader: StyledEntryHeader,
};
