import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import {Root} from "./screens/Root";

const root = document.getElementById("root");

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);