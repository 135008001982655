import styled from "styled-components";

const StyledTitle = styled.h2`
  font-size: ${(props) => props.theme.global.typography.h2.fontSize};
  font-weight: ${(props) => props.theme.global.typography.h2.fontWeight};
  line-height: ${(props) => props.theme.global.typography.lineHeight.title};
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: ${(props) => props.theme.global.padding.small};
  }

  @media (min-width: ${(props) =>
      props.theme.global.breakpoints.medium.value}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${(props) => props.theme.global.padding.medium};
  }
`;

const StyledContentWrapper = styled.div`
  padding: ${(props) => props.theme.global.padding.medium};
  background: ${(props) => props.theme.global.colors.sidebar.backgroundColor};

  overflow-x: auto;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
`;

const StyledActionContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  & > *:not(:first-child) {
    margin-left: ${(props) => props.theme.global.padding.small};
  }

  @media (min-width: ${(props) =>
      props.theme.global.breakpoints.medium.value}) {
    & > * {
      margin-left: ${(props) => props.theme.global.padding.small};
    }
  }
`;

export default {
  Title: StyledTitle,
  Header: StyledHeader,
  ContentWrapper: StyledContentWrapper,
  ActionContainer: StyledActionContainer,
};
