import React from "react";

import StyledIcon from "./styled";
import type { Icon as TypeIcon } from "../../types";

import * as Ai from "react-icons/ai";
import { number } from "@storybook/addon-knobs";

type Props = {
  children?: any;
  type?: TypeIcon;
  onClick?: (event: any) => any;
  color?: string;
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge" | "xxlarge";
};

export const iconTypeToComponent = {
  add: StyledIcon.Add,
  edit: StyledIcon.Edit,
  update: StyledIcon.Edit,
  up: StyledIcon.ArrowUp,
  down: StyledIcon.ArrowDown,
  delete: StyledIcon.Delete,
  print: StyledIcon.Print,
  check: StyledIcon.Checkmark,
  circle: StyledIcon.Circle,
  pen: StyledIcon.Pen,
  alert: StyledIcon.Alert,
  close: StyledIcon.Close,
  upload: StyledIcon.FileUpload,
  noFile: StyledIcon.NoFile,
  menu: StyledIcon.Menu,
  angleDown: StyledIcon.AngleDown,
  angleUp: StyledIcon.AngleUp,
  angleLeft: StyledIcon.AngleLeft,
  angleRight: StyledIcon.AngleRight,
  customersBook: StyledIcon.RegAddressBook,
  customer: StyledIcon.RegAddressCard,
  users: StyledIcon.Users,
  workBook: StyledIcon.IosAlbums,
  thLarge: StyledIcon.ThLarge,
  inbox: StyledIcon.Inbox,
  paperclip: StyledIcon.Paperclip,
  search: StyledIcon.Search,
  plus: StyledIcon.Plus,
  signInAlt: StyledIcon.SignInAlt,
  read: StyledIcon.SignInAlt,
  regFile: StyledIcon.RegFile,
  syncAlt: StyledIcon.SyncAlt,
  sdStorage: StyledIcon.SdStorage,
  building: StyledIcon.Building,
  calendar: StyledIcon.Calendar,
  book: StyledIcon.Book,
  outlineAreaChart: StyledIcon.OutlineAreaChart,
  apps: StyledIcon.Apps,
  wifiOff: StyledIcon.WifiOff,
  user: StyledIcon.User,
  logout: StyledIcon.Logout,
};

const Icon = ({ children, type, onClick, color, size }: Props) => {
  if (!type) return null;
  const IconComponent = iconTypeToComponent[type];
  if (!IconComponent) return null;
  const iconSize =
    size === "xsmall"
      ? "0.65rem"
      : size === "small"
      ? "1rem"
      : size === "large"
      ? "2.5rem"
      : size === "xlarge"
      ? "3rem"
      : size === "xxlarge"
      ? "3.8rem"
      : "1.4rem";
  return <IconComponent color={color} size={iconSize} onClick={onClick} />;
};

export default Icon;

type DynamicIconProps = {
  lib: "ai";
  iconName: string;
  size?: string;
  color?: string;
};

export const DynamicIcon: React.FC<DynamicIconProps> = ({
  lib,
  iconName,
  size,
  color,
}) => {
  let iconComponent = null;
  const iconSize =
    size === "xsmall"
      ? "0.65rem"
      : size === "small"
      ? "1rem"
      : size === "large"
      ? "2.5rem"
      : size === "xlarge"
      ? "3rem"
      : size === "xxlarge"
      ? "3.8rem"
      : "1.4rem";

  if (lib === "ai") {
    iconComponent = React.createElement(Ai[iconName]);
  }

  return (
    <div style={{ fontSize: iconSize, color: color }}>{iconComponent}</div>
  );
};
