import styled from "styled-components";

const StyledDropdownMenu = styled.div<{show: boolean}>`
  background: yellow;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: ${(props) => (props.show ? "block " : "none")};
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.31) 0px 0px 1px;
`;

export default StyledDropdownMenu;
