import { useCallback, useState, useRef } from "react";
import { isNil, get } from "lodash-es";
import {
  ContentTypesACLRules,
  FixMeLater,
  JSONSchema,
  Locale,
  Tenant,
  UISchema,
  ImageMapMarker as TImageMapMarker,
} from "../../types";
import { ContentType } from "../../models/ContentType";
import EntryModel from "../../models/Entry";
import { uid } from "../../utils";
import { useModal } from "../../components/Modal";
import Form from "../../components/Form";
import { DraggableData } from "react-draggable";
import { ImageMapMarker } from "./ImageMapMarker";
import Button from "../../components/Button";
import { StyledDeleteButtonWrapper, StyledToolbar } from "./styled";
import { useTranslation } from "react-i18next";

type Props = {
  id: string;
  name?: string;
  onBlur?: (event: any) => any;
  onChange?: (event: any) => any;
  value?: any[];
  defaultValue?: string;
  readOnly?: boolean;
  isOnline?: boolean;
  mapSrc?: string;
  defaultTileSize: [number, number];
  schema?: JSONSchema;
  uiSchema?: UISchema;
  tenantId?: Tenant["uid"];
  contentTypeId?: ContentType["uid"];
  entryId?: EntryModel["uid"] | null;
  contentTypes?: ContentType[];
  contentTypesACL?: ContentTypesACLRules;
  language: Locale;
  languages: Locale[];
  areaShape?: "rect";
  mapWidth?: number;
  areaColor?: string;
  areaHoverColor?: string;
  markerValue: string;
};

const ImageMapWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue = "",
  readOnly = false,
  isOnline,
  mapSrc,
  defaultTileSize,
  schema,
  uiSchema,
  tenantId,
  contentTypeId,
  entryId,
  language,
  mapWidth,
  languages,
  markerValue,
}: Props) => {
  const imageMapRef = useRef();
  const { openModal, closeModal } = useModal();
  const { t } = useTranslation();
  const [imgH, setImgH] = useState(50);

  const handleDragItem = useCallback(
    (item: TImageMapMarker, data: DraggableData) => {
      const mapW = (imageMapRef.current as any).width,
        mapH = (imageMapRef.current as any).height;

      const values = (value || []).map((vI) =>
        vI.uid === item.uid
          ? {
              ...item,
              center: [data.x, data.y],
              centerPerc: [
                Math.round((Number(data.x) / mapW) * 100),
                Math.round((Number(data.y) / mapH) * 100),
              ],
            }
          : vI
      );
      onChange && onChange(values);
    },
    [onChange, value]
  );

  const handleSubmit = useCallback(
    (item: Partial<TImageMapMarker>, itemId?: TImageMapMarker["uid"]) => {
      console.log(item);
      let currentValues: TImageMapMarker[] = value ? [...value] : [];

      if (itemId) {
        currentValues = currentValues.map((i) =>
          i.uid === itemId ? { ...i, attributes: { ...item } } : i
        );
      } else {
        currentValues.push({
          uid: uid(),
          center: [0, 0],
          centerPerc: [0, 0],
          attributes: {
            ...item,
          },
        });
      }

      onChange && onChange(currentValues);
      closeModal();
    },
    [closeModal, onChange, value]
  );

  const handleOpenModal = useCallback(
    (Component, title: string) => {
      openModal({
        content: Component,
        closeOnClickOutside: false,
        title,
      });
    },
    [openModal]
  );

  const handleAdd = useCallback(() => {
    handleOpenModal(
      <div>
        <Form
          schema={{
            type: "object",
            properties: schema.items,
          }}
          uiSchema={uiSchema}
          formData={{}}
          hasSubmitButton
          shouldPreventLeavingDirtyForm={false}
          tenantId={tenantId}
          contentTypeId={contentTypeId}
          entryId={entryId}
          language={language}
          languages={languages}
          onSubmit={handleSubmit}
        />
      </div>,
      t("components.imageMapWidget.modalAddTitle")
    );
  }, [
    contentTypeId,
    entryId,
    handleOpenModal,
    handleSubmit,
    language,
    languages,
    schema.items,
    t,
    tenantId,
    uiSchema,
  ]);

  const handleDeleteItem = useCallback(
    (item: TImageMapMarker) => {
      const currentValues = ((value || []) as TImageMapMarker[]).filter(
        (i) => i.uid !== item.uid
      );

      onChange && onChange(currentValues);
      closeModal();
    },
    [closeModal, onChange, value]
  );

  const handleItemClick = useCallback(
    (item: TImageMapMarker) => {
      handleOpenModal(
        <div>
          <Form
            schema={{
              type: "object",
              properties: schema.items,
            }}
            uiSchema={uiSchema}
            formData={item?.attributes}
            hasSubmitButton
            shouldPreventLeavingDirtyForm={false}
            tenantId={tenantId}
            contentTypeId={contentTypeId}
            entryId={entryId}
            language={language}
            languages={languages}
            onSubmit={(values: any) => handleSubmit(values, item.uid)}
          />
          <StyledDeleteButtonWrapper>
            <Button
              model="secondary"
              type="button"
              onClick={() => handleDeleteItem(item)}
            >
              {t("components.imageMapWidget.deleteBtt")}
            </Button>
          </StyledDeleteButtonWrapper>
        </div>,
        t("components.imageMapWidget.modalUpdateTitle")
      );
    },
    [
      contentTypeId,
      entryId,
      handleDeleteItem,
      handleOpenModal,
      handleSubmit,
      language,
      languages,
      schema.items,
      t,
      tenantId,
      uiSchema,
    ]
  );

  return (
    <div>
      <StyledToolbar>
        <Button model="secondary" onClick={handleAdd}>
          {t("components.imageMapWidget.addMarker")}
        </Button>
      </StyledToolbar>
      <div style={{ width: "80vw", overflowX: "auto" }}>
        <div
          key={(imageMapRef.current as any)?.height}
          style={{
            position: "relative",
            height: imgH,
          }}
        >
          <img
            src={mapSrc}
            alt=""
            width={mapWidth}
            ref={imageMapRef}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              height: "auto",
            }}
            onLoad={() => setImgH((imageMapRef.current as any)?.clientHeight)}
          />
          {value &&
            value.map((item: TImageMapMarker) => (
              <ImageMapMarker
                key={item.uid}
                item={item}
                value={get(item, `attributes.${markerValue}`, item.uid)}
                tileSize={defaultTileSize}
                onDrag={handleDragItem}
                onClick={handleItemClick}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

ImageMapWidget.mapSchemaToProps = (
  schema: FixMeLater,
  uiSchema: FixMeLater
) => {
  let props: FixMeLater = {};
  props.mapSrc = uiSchema["ui:image-map-src"] || "";
  props.mapWidth = uiSchema["ui:image-map-width"] || 800;
  props.markerValue = uiSchema["ui:marker-value"];
  props.defaultTileSize = [40, 50]; // X, Y

  props.readOnly = isNil(uiSchema["ui:readOnly"])
    ? schema.readOnly
    : uiSchema["ui:readOnly"];
  return props;
};

export default ImageMapWidget;

/*
  SCHEMA

"image_map":{
    "type":"array",
    "title":{
        "it":"Disposizione"
    },
    "items": {
        "code": {
            "type": "string",
            "title": {
                "it":"Codice"
            },
            "order": 1
        },
        "center": {
          "type": "string",
          "title": {
              "it":"Posizione"
          },
          "order": 2
        },
        "type": {
          "enum": [
            "beach-umbrella",
            "chaise-longue"
          ],
          "type": "string",
          "title": {
            "it": "Tipologia"
          },
          "enumNames": [
            {
              "it": "Ombrellone"
            },
            {
              "it": "Lettino"
            }
          ],
          "order": 3
        }
    },
    "order": 2
}
*/

/*
  UI SCHEMA

"image_map":{
    "ui:widget":"image-map",
    "ui:propertyOrder": 2,
    "ui:image-map-src": "https://bucket.mostaz.app/public/mokai/mokai_beach_map_v1.png",
    "ui:image-map-width": 1400,
    "ui:marker-value": "code"
},

*/
