import { RefObject, SyntheticEvent, useEffect } from "react";

export const useClickOutside = (
  ref: RefObject<HTMLElement | null>,
  enabled: boolean = true,
  onClickOutside: (event: SyntheticEvent<any>) => void
) => {
  useEffect(() => {
    const handler = (event: SyntheticEvent<any>) => {
      const { current: el } = ref;
      // @ts-ignore
      el && !el.contains(event.target) && onClickOutside(event);
    };
    if (enabled) {
      // @ts-ignore
      document.addEventListener("click", handler);
    }
    return () => {
      // @ts-ignore
      document.removeEventListener("click", handler);
    };
  }, [ref, onClickOutside, enabled]);
};
