import React from "react";
import StyledRowGrouping from "./styled";

type Item = any;

type Props = {
  title?: string;
  elevated?: boolean;
  items: Item[];
  renderItem: (item: Item) => any;
  getItemKey: (item: Item) => string;
  readOnly?: boolean;
  customStyle?: {};
};

const RowGrouping = ({
  title,
  elevated,
  items,
  renderItem,
  getItemKey,
  readOnly = false,
  customStyle = {},
}: Props) => {
  return (
    <StyledRowGrouping.Container>
      {title && <StyledRowGrouping.Heading>{title}</StyledRowGrouping.Heading>}

      <StyledRowGrouping.RowContainer style={{ ...customStyle }}>
        {items.map((item) => (
          <React.Fragment key={getItemKey(item)}>
            {renderItem(item)}
          </React.Fragment>
        ))}
      </StyledRowGrouping.RowContainer>
    </StyledRowGrouping.Container>
  );
};

export default RowGrouping;
