import { useLocation } from "react-router";
import navigationUtils from "../utils/navigation";
import { useCurrentTenant } from "./useCurrentTenant";
import { ContentType } from "../models/ContentType";

export const useCurrentContentType = (): ContentType | null => {
  const location = useLocation();
  const currentTenant = useCurrentTenant();

  const contentTypeId = navigationUtils.fromRoutes.contentTypeId(
    location.pathname
  );
  return (
    currentTenant?.contentTypes.find(
      (contentType) => contentType.uid === contentTypeId
    ) || null
  );
};
