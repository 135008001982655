import it from "./it";
import en from "./en";
import de from "./de";

export default {
  it: {
    translation: it,
  },
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};
