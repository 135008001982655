import React, { useCallback } from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import navigationUtils from "../../utils/navigation";
import { useTranslation } from "react-i18next";
import { AuthLayout } from "../../components/AuthLayout";
import Form from "../../components/Form";
import { Link } from "../../components/Link";
import type { FormData, JSONSchema, Locale, UISchema } from "../../types";
import { useStores } from "../../hooks/useStores";
import i18n from "../../services/i18n";
import { useSnackbar } from "notistack";

export const LostPasswordScreen: React.FC = observer(() => {
  const { auth } = useStores();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  // const [shouldRedirectToReferrer, setShouldRedirectToReferrer] = useState(
  //   false
  // );
  // const { from } = location.state || { from: { pathname: "/" } };
  // if (shouldRedirectToReferrer) return <Redirect to={from} />;
  if (auth.isLoggedIn) {
    return <Redirect to={"/cms"} />;
  }

  const schema: JSONSchema = {
    title: "Recupero password",
    type: "object",
    required: ["email"],
    properties: {
      email: {
        title: { [i18n.language]: t("auth.lostPasswordLabel") },
        type: "string",
      },
    },
  };
  const uiSchema: UISchema = {
    email: {
      "ui:autofocus": true,
      "ui:placeholder": t("auth.enterEmail"),
    },
  };

  const handleSubmit = useCallback(
    async (values: FormData) => {
      try {
        await auth.forgotPassword(values.email);
        enqueueSnackbar(t("common.actions.actionCompleted"), {
          variant: "success",
        });
      } catch (err) {
        enqueueSnackbar(t("common.error"), {
          variant: "error",
        });
      }
    },
    [auth, enqueueSnackbar, t]
  );

  return (
    <AuthLayout title={schema.title}>
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={{}}
        onSubmit={handleSubmit}
        submitLabel={t("auth.sendRecoveryLink")}
        language={i18n.language as Locale}
        languages={[i18n.language as Locale]}
      />
      <Link
        href={navigationUtils.routes.auth.login()}
        text={t("auth.backToLogin")}
      />
    </AuthLayout>
  );
});
