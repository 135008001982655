/* @flow */
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding-top: ${(props) => props.theme.global.mobile.header.height};

  @media (min-width: ${(props) => props.theme.global.breakpoints.large.value}) {
    padding-top: 0;
  }
`;

export default StyledContainer;
