import keys from "../../config/keys";
import storageServices from "../storage";
import navigationUtils from "../../utils/navigation";
import { jsonToFormData } from "../../utils";
import backendUtils from "./utils";

import type {
  Tenant,
  Entry,
  FormData,
  User,
  UserRole,
  Organization,
  CustomAction,
} from "../../types";
import { ContentType } from "../../models/ContentType";

const callBackendEndpoint = async (
  method: string,
  endpoint: string,
  params: any = {}
) => {
  const prevSessionToken = await storageServices.getItem("sessionToken");
  let headers = {};
  if (prevSessionToken) {
    headers["Authorization"] = `Bearer ${prevSessionToken}`;
  }
  headers["Content-Type"] = "application/json";
  headers["Access-Control-Allow-Origin"] = "*";
  let parsedParams: any = JSON.stringify(params);

  // if (["POST", "PUT", "PATCH"].includes(method)) {
  //   delete headers["Content-Type"];
  //   parsedParams = jsonToFormData(params);
  // }

  const url = `${keys.BACKEND_API_URL}${endpoint}`;
  const response = await fetch(url, {
    method: method,
    headers: headers,
    body: method === "GET" || method === "HEAD" ? undefined : parsedParams,
  });
  let responseBody;
  let responseText;
  try {
    responseBody = await response.clone().json();
  } catch (err) {
    responseText = await response.clone().text();
  }

  // TODO: remove this when backend will support JSON responses in all APIs
  if (method === "DELETE") {
    responseText = null;
    responseBody = {};
  }

  if (
    !response.ok ||
    responseText != null ||
    responseBody == null ||
    responseBody.error
  ) {
    if (
      response.status === 401 &&
      window.location.pathname !== navigationUtils.routes.auth.login()
    ) {
      storageServices.removeItem("sessionToken");
      storageServices.removeItem("currentUser");
      navigationUtils.reloadCurrentPage();
    }
    const error =
      (responseBody && responseBody.error) || { message: responseText } || {};
    throw new Error(error.message || response.statusText || "Connection error");
  }
  const sessionToken = response.headers.get(`Authorization`);
  if (sessionToken) {
    storageServices.setItem(
      "sessionToken",
      sessionToken.replace(/(^Bearer\s*)?/i, "")
    );
  }
  return responseBody;
};

const getCustomersForm = async () => {
  return await callBackendEndpoint("GET", "customers");
};

/*
 * AUTH
 */

const getCurrentUser = async (): Promise<User | null> => {
  const sessionToken = await storageServices.getItem("sessionToken");
  //const currentUser: any = await storageServices.getItem("currentUser");
  if (!sessionToken) return null;
  const result = await callBackendEndpoint("GET", `user/me`);

  return backendUtils.toUser(result);
};

const updateUserProfile = async (user: User) => {
  const params = {
    nominative: user.nominative,
    email: user.email,
  };

  await callBackendEndpoint("POST", `users/${user.uid}`, params);
};

const login = async (email: User["email"], password: string) => {
  // delete old token
  storageServices.removeItem("sessionToken");
  const body = {
    email,
    password,
  };
  const result = await callBackendEndpoint("POST", "auth/basic", body);
  const user = null;
  console.log(result);
  await storageServices.setItem("sessionToken", result.token);
  //const user = backendUtils.toUser(result);
  //await storageServices.setItem("currentUser", user);
  return user;
};

const logout = async () => {
  storageServices.removeItem("sessionToken");
  storageServices.removeItem("currentUser");
};

const forgotPassword = async (email: User["email"]) => {
  const params = {
    email,
  };

  await callBackendEndpoint("POST", `auth/basic/lost-password`, params);
};

const resetPassword = async (
  email: User["email"],
  password: string,
  token: string
) => {
  const params = {
    email,
    password,
    token,
  };

  await callBackendEndpoint("POST", `auth/basic/change-password`, params);
};

const getTenants = async (): Promise<Tenant[]> => {
  const result = await callBackendEndpoint("GET", "tenant?relations=true");
  const tenants = result.map(backendUtils.toTenant);
  return tenants;
};

const getContentTypes = async (
  tenantId: Tenant["uid"]
): Promise<ContentType[]> => {
  const result = await callBackendEndpoint(
    "GET",
    `tenant/${tenantId}/content-type`
  );
  const contentTypes = result.map(backendUtils.toContentType);
  return contentTypes;
};

const getEntries = async (
  tenantId: Tenant["uid"],
  contentTypeId: ContentType["uid"],
  conditions?: string[]
): Promise<Entry[]> => {
  const result = await callBackendEndpoint(
    "GET",
    `tenant/${tenantId}/content-type/${contentTypeId}/entry${
      conditions ? `?conditions=${JSON.stringify(conditions)}` : ""
    }`
  );
  const entries = result.map(backendUtils.toEntry);
  return entries;
};

const getEntry = async (
  tenantId: Tenant["uid"],
  contentTypeId: ContentType["uid"],
  entryId: Entry["uid"]
): Promise<Entry> => {
  const result = await callBackendEndpoint(
    "GET",
    `tenant/${tenantId}/content-type/${contentTypeId}/entry/${entryId}`
  );
  const entry = backendUtils.toEntry(result);
  return entry;
};

const createEntry = async (
  tenantId: Tenant["uid"],
  contentTypeId: ContentType["uid"],
  formData: FormData
): Promise<Entry> => {
  const params = {
    content_type_id: contentTypeId,
    data: formData,
  };
  const result = await callBackendEndpoint(
    "POST",
    `tenant/${tenantId}/content-type/${contentTypeId}/entry`,
    params
  );
  const createdEntry = backendUtils.toEntry(result);
  return createdEntry;
};

const updateEntry = async (
  tenantId: Tenant["uid"],
  contentTypeId: ContentType["uid"],
  entryId: Entry["uid"],
  formData: FormData
): Promise<Entry> => {
  const params = {
    data: formData,
  };
  const result = await callBackendEndpoint(
    "PATCH",
    `tenant/${tenantId}/content-type/${contentTypeId}/entry/${entryId}`,
    params
  );
  const updatedEntry = backendUtils.toEntry(result);
  return updatedEntry;
};

const deleteEntry = async (
  tenantId: Tenant["uid"],
  contentTypeId: ContentType["uid"],
  entryId: Entry["uid"]
): Promise<void> => {
  await callBackendEndpoint(
    "DELETE",
    `tenant/${tenantId}/content-type/${contentTypeId}/entry/${entryId}`
  );
};

const getRoles = async (tenantId: Tenant["uid"]): Promise<UserRole[]> => {
  const result = await callBackendEndpoint("GET", `tenant/${tenantId}/role`);
  const roles = result.map(backendUtils.toUserRole);
  return roles;
};

const inviteUser = async (
  email: User["email"],
  role: UserRole["name"],
  tenantId: Tenant["id"] | Tenant["name"]
): Promise<void> => {
  await callBackendEndpoint(
    "GET",
    `invite?email=${encodeURIComponent(email)}&role=${encodeURIComponent(
      role
    )}&tenant=${encodeURIComponent(tenantId.toString())}`
  );
};

const getUserRelatedEntry = async (
  userId: User["uid"],
  tenantId: Tenant["uid"],
  contentTypeId: ContentType["uid"]
): Promise<Entry> => {
  const result = await callBackendEndpoint(
    "GET",
    `tenant/${tenantId}/content-type/${contentTypeId}/entry?relation=users&relation_id=${userId}`
  );
  console.log(result);
  const entry = backendUtils.toEntry(result[0]);
  return entry;
};

const getExportConfiguration = async (
  tenantId: Tenant["id"] | Tenant["name"],
  contentTypeId: ContentType["id"] | ContentType["name"]
): Promise<any> => {
  const result = await callBackendEndpoint(
    "GET",
    `tenants/${tenantId}/content_types/${contentTypeId}/exports`
  );
  return result;
};

const getOrganization = async (
  organizationId: Organization["uid"]
): Promise<Organization> => {
  const result = await callBackendEndpoint(
    "GET",
    `organization/${organizationId}`
  );
  return backendUtils.toOrganization(result);
};

const updateOrganization = async (
  organizationId: Organization["uid"],
  organization: Organization
): Promise<Organization> => {
  const payload = {
    name: organization.name,
    description: organization.description,
    contacts: {
      phone: organization.contacts?.phone,
      email: organization.contacts?.email,
      address1: organization.contacts?.address1,
      address2: organization.contacts?.address2,
      website: organization.contacts?.website,
    },
  };

  const result = await callBackendEndpoint(
    "PATCH",
    `organization/${organizationId}`,
    payload
  );

  return backendUtils.toOrganization(result);
};

const performAction = async (
  tenantId: Tenant["uid"],
  contentTypeId: ContentType["uid"],
  entryId: Entry["uid"],
  action: CustomAction
): Promise<boolean> => {
  await callBackendEndpoint(
    "GET",
    `tenant/${tenantId}/content-type/${contentTypeId}/action/${action}?entryId=${entryId}`
  );

  return true;
};

export default {
  getCurrentUser,
  updateUserProfile,
  login,
  logout,
  forgotPassword,
  resetPassword,
  getTenants,
  getCustomersForm,
  getContentTypes,
  getEntries,
  getEntry,
  createEntry,
  updateEntry,
  deleteEntry,
  getRoles,
  inviteUser,
  getUserRelatedEntry,
  getExportConfiguration,
  getOrganization,
  updateOrganization,
  performAction,
};
