import React from "react";
import StyledLink from "./styled";

type LinkProps = {
  href: string,
  text: string,
};

export const Link = ({ href, text, ...othersProps }: LinkProps) => (
  <StyledLink to={href} {...othersProps}>
    {text}
  </StyledLink>
);

