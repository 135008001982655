/* @flow */
import { useCallback } from "react";
import { useHistory } from "./useHistory";

export const useOrderedList = (initialList: ?Array<any>) => {
  const {
    state: list,
    set: setList,
    undo,
    redo,
    clear,
    canUndo,
    canRedo,
  } = useHistory(initialList || []);

  const addItem = useCallback(
    (item: any) => {
      // $FlowFixMe
      const newList = [...list, item];
      setList(newList);
      return newList;
    },
    [list, setList]
  );

  const removeItem = useCallback(
    (index: number) => {
      // $FlowFixMe
      if (index >= list.length) {
        return list;
      }
      // $FlowFixMe
      const newList = list.filter((v, i) => i !== index);
      setList(newList);
      return newList;
    },
    [list, setList]
  );

  const setItem = useCallback(
    (index: number, item: any) => {
      // $FlowFixMe
      if (index >= list.length) {
        return list;
      }
      // $FlowFixMe
      const newList = list.map((v, i) => (i !== index ? v : item));
      setList(newList);
      return newList;
    },
    [list, setList]
  );

  const moveItem = useCallback(
    (fromIndex: number, toIndex: number) => {
      // $FlowFixMe
      if (fromIndex >= list.length || toIndex >= list.length) {
        return list;
      }
      // $FlowFixMe
      const newList = [...list];
      newList.splice(toIndex, 0, newList.splice(fromIndex, 1)[0]);
      setList(newList);
      return newList;
    },
    [list, setList]
  );

  return {
    list,
    setList,
    undo,
    redo,
    clear,
    canUndo,
    canRedo,
    addItem,
    removeItem,
    setItem,
    moveItem,
  };
};
