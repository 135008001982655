import React from "react";

import StyledLabel from "./styled";

type Props = {
  children?: string;
};

const FieldErrorLabel = (props: Props) => {
  return <StyledLabel>{props.children}</StyledLabel>;
};

export default FieldErrorLabel;
