import styled, { css } from "styled-components";
import {
  IoMdCreate as EditIcon,
  IoMdTrash as DeleteIcon,
  IoMdArrowRoundUp as ArrowUpIcon,
  IoMdArrowRoundDown as ArrowDownIcon,
  IoMdPrint as PrintIcon,
  IoMdCheckmark as CheckmarkIcon,
  IoIosAlbums as IosAlbumsIcon,
  IoMdSync as SyncAltIcon,
  IoIosCalendar as CalendarIcon,
  IoIosBook as BookIcon,
  IoMdLogOut as LogoutIcon,
} from "react-icons/io";
import {
  FaPen as PenIcon,
  FaExclamation as AlertIcon,
  FaRegCircle as CircleIcon,
  FaAngleDown as AngleDownIcon,
  FaAngleUp as AngleUpIcon,
  FaAngleLeft as AngleLeftIcon,
  FaAngleRight as AngleRightIcon,
  FaRegAddressBook as RegAddressBookIcon,
  FaRegAddressCard as RegAddressCardIcon,
  FaUsers as UsersIcon,
  FaThLarge as ThLargeIcon,
  FaInbox as InboxIcon,
  FaPaperclip as PaperclipIcon,
  FaPlus as PlusIcon,
  FaSignInAlt as SignInAltIcon,
  FaRegFile as RegFileIcon,
  FaBuilding as BuildingIcon,
  FaUser as UserIcon,
} from "react-icons/fa";
import {
  MdFileUpload as FileUploadIcon,
  MdInsertDriveFile as NoFileIcon,
  MdSdStorage as SdStorageIcon,
  MdApps as AppsIcon,
  MdClose as CloseIcon,
} from "react-icons/md";
import {
  FiSearch as SearchIcon,
  FiWifiOff as WifiOff,
  FiMenu as MenuIcon,
} from "react-icons/fi";

import {
  AiOutlinePlus as AddIcon,
  AiOutlineAreaChart as OutlineAreaChart,
} from "react-icons/ai";

const baseIconStyle = css<{onClick?: ()=> any}>`
  position: relative;
  box-sizing: border-box;
  ${(props) => props.onClick && "cursor: pointer;"}
  color: ${(props) => props.theme.global.colors.text.light};
`;

const StyledAddIcon = styled(AddIcon)`
  ${baseIconStyle}
`;

const StyledEditIcon = styled(EditIcon)`
  ${baseIconStyle}
`;

const StyledArrowUpIcon = styled(ArrowUpIcon)`
  ${baseIconStyle}
`;

const StyledArrowDownIcon = styled(ArrowDownIcon)`
  ${baseIconStyle}
`;

const StyledDeleteIcon = styled(DeleteIcon)`
  ${baseIconStyle}
  color: #d9534f;
`;

const StyledPrintIcon = styled(PrintIcon)`
  ${baseIconStyle}
`;

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  ${baseIconStyle}
`;

const StyledCircleIcon = styled(CircleIcon)`
  ${baseIconStyle}
`;

const StyledPenIcon = styled(PenIcon)`
  ${baseIconStyle}
`;

const StyledAlertIcon = styled(AlertIcon)`
  ${baseIconStyle}
`;

const StyledCloseIcon = styled(CloseIcon)`
  ${baseIconStyle}
`;

const StyledFileUploadIcon = styled(FileUploadIcon)`
  ${baseIconStyle}
`;

const StyledNoFileIcon = styled(NoFileIcon)`
  ${baseIconStyle}
`;

const StyledMenuIcon = styled(MenuIcon)`
  ${baseIconStyle}
`;

const StyledAngleDownIcon = styled(AngleDownIcon)`
  ${baseIconStyle}
`;

const StyledAngleUpIcon = styled(AngleUpIcon)`
  ${baseIconStyle}
`;

const StyledAngleLeftIcon = styled(AngleLeftIcon)`
  ${baseIconStyle}
`;

const StyledAngleRightIcon = styled(AngleRightIcon)`
  ${baseIconStyle}
`;

const StyledRegAddressBookIcon = styled(RegAddressBookIcon)`
  ${baseIconStyle}
`;

const StyledRegAddressCardIcon = styled(RegAddressCardIcon)`
  ${baseIconStyle}
`;

const StyledUsersIcon = styled(UsersIcon)`
  ${baseIconStyle}
`;

const StyledIosAlbumsIcon = styled(IosAlbumsIcon)`
  ${baseIconStyle}
`;

const StyledThLargeIcon = styled(ThLargeIcon)`
  ${baseIconStyle}
`;

const StyledInboxIcon = styled(InboxIcon)`
  ${baseIconStyle}
`;

const StyledPaperclipIcon = styled(PaperclipIcon)`
  ${baseIconStyle}
`;

const StyledSearchIcon = styled(SearchIcon)`
  ${baseIconStyle}
`;

const StyledPlusIcon = styled(PlusIcon)`
  ${baseIconStyle}
`;

const StyledSignInAltIcon = styled(SignInAltIcon)`
  ${baseIconStyle}
`;

const StyledRegFileIcon = styled(RegFileIcon)`
  ${baseIconStyle}
`;

const StyledSyncAltIcon = styled(SyncAltIcon)`
  ${baseIconStyle}
`;

const StyledSdStorageIcon = styled(SdStorageIcon)`
  ${baseIconStyle}
`;

const StyledBuildingIcon = styled(BuildingIcon)`
  ${baseIconStyle}
`;

const StyledCalendarIcon = styled(CalendarIcon)`
  ${baseIconStyle}
`;

const StyledBookIcon = styled(BookIcon)`
  ${baseIconStyle}
`;

const StyledOutlineAreaChart = styled(OutlineAreaChart)`
  ${baseIconStyle}
`;

const StyledAppsIcon = styled(AppsIcon)`
  ${baseIconStyle}
`;

const StyledWifiOff = styled(WifiOff)`
  ${baseIconStyle}
`;

const StyledUserIcon = styled(UserIcon)`
  ${baseIconStyle}
`;

const StyledLogoutIcon = styled(LogoutIcon)`
  ${baseIconStyle}
`;

export default {
  Add: StyledAddIcon,
  Edit: StyledEditIcon,
  ArrowUp: StyledArrowUpIcon,
  ArrowDown: StyledArrowDownIcon,
  Delete: StyledDeleteIcon,
  Print: StyledPrintIcon,
  Checkmark: StyledCheckmarkIcon,
  Circle: StyledCircleIcon,
  Pen: StyledPenIcon,
  Alert: StyledAlertIcon,
  Close: StyledCloseIcon,
  FileUpload: StyledFileUploadIcon,
  NoFile: StyledNoFileIcon,
  Menu: StyledMenuIcon,
  AngleDown: StyledAngleDownIcon,
  AngleUp: StyledAngleUpIcon,
  AngleLeft: StyledAngleLeftIcon,
  AngleRight: StyledAngleRightIcon,
  RegAddressBook: StyledRegAddressBookIcon,
  RegAddressCard: StyledRegAddressCardIcon,
  Users: StyledUsersIcon,
  IosAlbums: StyledIosAlbumsIcon,
  ThLarge: StyledThLargeIcon,
  Inbox: StyledInboxIcon,
  Paperclip: StyledPaperclipIcon,
  Search: StyledSearchIcon,
  Plus: StyledPlusIcon,
  SignInAlt: StyledSignInAltIcon,
  RegFile: StyledRegFileIcon,
  SyncAlt: StyledSyncAltIcon,
  SdStorage: StyledSdStorageIcon,
  Building: StyledBuildingIcon,
  Calendar: StyledCalendarIcon,
  Book: StyledBookIcon,
  OutlineAreaChart: StyledOutlineAreaChart,
  Apps: StyledAppsIcon,
  WifiOff: StyledWifiOff,
  User: StyledUserIcon,
  Logout: StyledLogoutIcon,
};
