import React from "react";
import { useDropdownMenu, UseDropdownMenuOptions } from "react-overlays/DropdownMenu";
import useMergedRefs from "@restart/hooks/useMergedRefs";

import StyledDropdownMenu from "./styled";

type AlignDirection = 'left' | 'right';

type ResponsiveAlignProp =
  | { sm: AlignDirection }
  | { md: AlignDirection }
  | { lg: AlignDirection }
  | { xl: AlignDirection };

type AlignType = AlignDirection | ResponsiveAlignProp;

interface BsPrefixAndClassNameOnlyProps {
  bsPrefix?: string;
  className?: string;
}

interface BsPrefixProps<As extends React.ElementType = React.ElementType>
  extends BsPrefixAndClassNameOnlyProps {
  as?: As;
}
type SelectCallback = (
  eventKey: string | null,
  e: React.SyntheticEvent<unknown>,
) => void;
type BsPrefixPropsWithChildren<
  As extends React.ElementType = React.ElementType
> = React.PropsWithChildren<BsPrefixProps<As>>;

interface DropdownMenuProps extends BsPrefixPropsWithChildren {
  show?: boolean;
  renderOnMount?: boolean;
  flip?: boolean;
  align?: AlignType;
  alignRight?: boolean;
  onSelect?: SelectCallback;
  rootCloseEvent?: 'click' | 'mousedown';
  popperConfig?: UseDropdownMenuOptions['popperConfig'];
}

type Props = {
  /** Controls the visibility of the Dropdown menu  */
  show?: boolean,
  /** Have the dropdown switch to it's opposite placement when necessary to stay on screen. */
  flip?: boolean,
  /** Aligns the Dropdown menu to the right of it's container. */
  alignRight?: boolean,
  onSelect?: () => any,
  /**
   * Which event when fired outside the component will cause it to be closed
   *
   * *Note: For custom dropdown components, you will have to pass the
   * `rootCloseEvent` to `<RootCloseWrapper>` in your custom dropdown menu
   * component ([similarly to how it is implemented in `<Dropdown.Menu>`](https://github.com/react-bootstrap/react-bootstrap/blob/v0.31.5/src/DropdownMenu.js#L115-L119)).*
   */
  rootCloseEvent?: "click" | "mousedown",
  /**
   * Control the rendering of the DropdownMenu. All non-menu props
   * (listed here) are passed through to the `as` Component.
   *
   * If providing a custom, non DOM, component. the `show`, `close` and `alignRight` props
   * are also injected and should be handled appropriately.
   */
  as?: any,
  /**
   * A set of popper options and props passed directly to react-popper's Popper component.
   */
  popperConfig?: {},
  className?: string,
  style?: {},
  "x-placement"?: string,
};

const DropdownMenu = React.forwardRef(
  (
    {
      className,
      alignRight,
      rootCloseEvent,
      flip,
      popperConfig,
      show: showProps,
      as: Component = StyledDropdownMenu,
      ...props
    }: DropdownMenuProps,
    ref
  ) => {
    const {
      hasShown,
      placement,
      show,
      alignEnd,
      close,
      props: menuProps,
    } = useDropdownMenu({
      flip,
      popperConfig,
      rootCloseEvent,
      show: showProps,
      alignEnd: alignRight,
      usePopper: true,
    });

    menuProps.ref = useMergedRefs(menuProps.ref, ref);

    if (!hasShown) return null;

    // For custom components provide additional, non-DOM, props;
    if (typeof Component !== "string") {
      menuProps.show = show;
      menuProps.close = close;
      menuProps.alignRight = alignEnd;
    }
    // @ts-ignore
    let style = props.style;
    if (placement) {
      // we don't need the default popper style,
      // menus are display: none when not shown.
      style = { ...style, ...menuProps.style };

      props["x-placement"] = placement;
    }
    return (
      <Component
        {...props}
        {...menuProps}
        style={style}
        className={`${show ? "show" : ""} ${alignEnd ? `right` : ""}`}
      />
    );
  }
);

export default DropdownMenu;
