/* @flow */
import React from "react";

import StyledDocument from "./styled";
import Icon from "../Icon";

const baseURL = "https://docs.google.com/gview?url=";

type Props = {
  src: string,
  alt: string,
};

const Document = ({ src = "", alt }: Props) => {
  const ext = src.split(".").pop() || "file";
  return (
    <StyledDocument.Container>
      <StyledDocument.Link href={`${baseURL}${src}`} target={"_blank"}>
        <Icon type={"paperclip"} />
        <span>{ext}</span>
      </StyledDocument.Link>
    </StyledDocument.Container>
  );
};

export default Document;
