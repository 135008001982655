import React, { useCallback } from "react";
import { Formik, Form as FormikForm } from "formik";
import { isArray, isNil, isEmpty, isEqual } from "lodash-es";
import { Prompt } from "react-router-dom";

import strings from "../../config/strings";
import Field from "../Field";
import Button from "../Button";
import FieldsetGrouping from "../../groupings/FieldsetGrouping";
import TableGrouping from "../../groupings/TableGrouping";
import TabsGrouping from "../../groupings/TabsGrouping";
import RowGrouping from "../../groupings/RowGrouping";
import { getComponentsFromSchema } from "../../utils/jsonSchema";
import EntryModel from "../../models/Entry";

import StyledForm from "./styled";

import type {
  JSONSchema,
  UISchema,
  FormData,
  StageMetadata,
  StageStatus,
  ContentTypesACLRules,
  Tenant,
  Locale,
} from "../../types";
import { ContentType } from "../../models/ContentType";

type FieldMetadata = {
  name: string;
  schema: JSONSchema;
  uiSchema: UISchema;
  parentSchema: JSONSchema;
  parentUISchema: UISchema;
  keySuffix: string;
  values: FormData;
  errors: Object;
  isOnline: boolean;
  tenantId?: Tenant["uid"]; //$PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">;
  contentTypeId?: ContentType["uid"]; //$PropertyType<ContentType, "id">;
  entryId?: EntryModel["uid"] | null; //$PropertyType<EntryModel, "id"> | null;
  language: Locale;
  languages: Locale[];
};

type Props = {
  schema?: JSONSchema;
  uiSchema?: UISchema;
  formData?: FormData;
  stagesMetadata?: StageMetadata[];
  onSubmit?: (values: FormData) => any;
  submitLabel?: string;
  hasSubmitButton?: boolean;
  shouldPreventLeavingDirtyForm?: boolean;
  contentTypes?: ContentType[];
  contentTypesACL?: ContentTypesACLRules;
  disabled?: boolean;
  isOnline?: boolean;
  tenantId?: Tenant["uid"]; //$PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">;
  contentTypeId?: ContentType["uid"]; //$PropertyType<ContentType, "id">;
  entryId?: EntryModel["uid"] | null; //$PropertyType<EntryModel, "id"> | null;
  bindSubmitForm?: any;
  language: Locale;
  languages: Locale[];
};

const Form = ({
  schema = {},
  uiSchema = {},
  formData = {},
  stagesMetadata = [],
  onSubmit,
  submitLabel = "Submit",
  hasSubmitButton = true,
  shouldPreventLeavingDirtyForm = false,
  contentTypes,
  contentTypesACL,
  disabled = false,
  isOnline = true,
  tenantId,
  contentTypeId,
  entryId,
  bindSubmitForm,
  language,
  languages,
}: Props) => {
  const isStageChanged = (currentFormData: FormData, stageId: string) => {
    return !isEqual(formData[stageId], currentFormData[stageId]);
  };

  const isStageEmpty = (currentFormData: FormData, stageId: string) => {
    return currentFormData[stageId] == null;
  };

  const isStageCompleted = (currentFormData: FormData, stageId: string) => {
    const stageStatus = stagesMetadata.find((stage) => stage.name === stageId);
    return (
      stageStatus != null &&
      stageStatus.completed &&
      !isStageEmpty(currentFormData, stageId)
    );
  };

  const isStageInvalid = (currentFormErrors: Object, stageId: string) => {
    return currentFormErrors[stageId] != null;
  };

  const getCurrentStageStatuses = (
    currentFormData: FormData,
    currentFormErrors: Object
  ): { [stageId: string]: StageStatus } => {
    const stageStatuses = {};
    if (schema && schema.properties) {
      Object.keys(schema.properties).forEach((stageId) => {
        stageStatuses[stageId] = isStageInvalid(currentFormErrors, stageId)
          ? "error"
          : isStageChanged(currentFormData, stageId)
          ? "dirty"
          : isStageCompleted(currentFormData, stageId)
          ? "completed"
          : isStageEmpty(currentFormData, stageId)
          ? "empty"
          : "inProgress";
      });
    }
    return stageStatuses;
  };

  const renderMutipleFields = ({
    name,
    schema,
    uiSchema,
    parentSchema,
    parentUISchema,
    keySuffix,
    values,
    errors,
    isOnline,
    tenantId,
    contentTypeId,
    entryId,
    language,
    languages,
  }: FieldMetadata): any => {
    if (schema.type === "object") {
      if (isNil(schema.properties) || isEmpty(schema.properties)) {
        console.warn(`Schema: Empty properties for "${name}" object`);
        return null;
      }
      /*
       * The first aggregate field has an empty `name` so we don't need a suffix for the childs
       */
      let childsSuffix = "";
      const divider = ".";
      if (name !== "") {
        childsSuffix = `${keySuffix}${name}${divider}`;
      }

      const childProperties = Object.keys(schema.properties);
      const uiOrder = isArray(uiSchema["ui:propertyOrder"])
        ? uiSchema["ui:propertyOrder"]
        : [];
      const sortedChildProperties = [
        ...uiOrder.filter((property) => childProperties.includes(property)),
        ...childProperties.filter((property) => !uiOrder.includes(property)),
      ];
      const objectChildProperties = sortedChildProperties.filter(
        (prop) => schema.properties[prop].type === "object"
      );
      const nonObjectChildProperties = sortedChildProperties.filter(
        (prop) => schema.properties[prop].type !== "object"
      );

      const renderChildProperty = (childName: string) => {
        const childSchema = schema.properties[childName] || {};
        const childUISchema = uiSchema[childName] || {};
        const childValues = values[childName] || {};
        const childErrors = errors[childName] || {};
        return renderField({
          name: childName,
          schema: childSchema,
          uiSchema: childUISchema,
          parentSchema: schema,
          parentUISchema: uiSchema,
          keySuffix: childsSuffix,
          values: childValues,
          errors: childErrors,
          isOnline,
          tenantId,
          contentTypeId,
          entryId,
          language,
          languages,
        });
      };

      // Table grouping (of all children)
      if (uiSchema["ui:widget"] === "table") {
        return (
          <TableGrouping
            title={schema.title}
            cells={sortedChildProperties}
            renderCell={renderChildProperty}
            getCellKey={(childProperty) => `${keySuffix}${childProperty}`}
            rowLength={uiSchema["ui:numItemsPerRow"]}
            rowLabels={uiSchema["ui:rowNames"]}
            columnLabels={uiSchema["ui:columnNames"]}
            readOnly={schema.readOnly}
            customStyle={uiSchema["ui:style"]}
          />
        );
      }

      // Tabs grouping (of object children)
      if (uiSchema["ui:widget"] === "tabs") {
        const currentStageStatuses = getCurrentStageStatuses(values, errors);

        return (
          <React.Fragment key={`${keySuffix}${name}`}>
            {nonObjectChildProperties.map(renderChildProperty)}
            {objectChildProperties.length > 0 && (
              <TabsGrouping
                tabs={objectChildProperties}
                renderTab={renderChildProperty}
                getTabKey={(childProperty) => `${keySuffix}${childProperty}`}
                getTabTitle={(childProperty) =>
                  (schema.properties[childProperty] || {}).title ||
                  childProperty
                }
                getTabState={(childProperty) =>
                  currentStageStatuses[childProperty]
                }
                readOnly={schema.readOnly}
                orientation={uiSchema["ui:tabOrientation"]}
              />
            )}
          </React.Fragment>
        );
      }

      // Row grouping (of all children)
      if (uiSchema["ui:widget"] === "row") {
        return (
          <RowGrouping
            title={name !== "" ? schema.title : undefined}
            elevated={name !== ""}
            items={sortedChildProperties}
            renderItem={renderChildProperty}
            getItemKey={(childProperty) => `${keySuffix}${childProperty}`}
            readOnly={schema.readOnly}
            customStyle={uiSchema["ui:style"]}
          />
        );
      }

      // Use no grouping for schema's root object or if parent was a Tabs grouping
      if (name === "" || parentUISchema["ui:widget"] === "tabs") {
        return sortedChildProperties.map((childProperty) => (
          <React.Fragment key={`${keySuffix}${childProperty}`}>
            {renderChildProperty(childProperty)}
          </React.Fragment>
        ));
      }

      // Fieldset grouping (of all children)
      return (
        <FieldsetGrouping
          title={name !== "" ? schema.title : undefined}
          elevated={name !== ""}
          items={sortedChildProperties}
          renderItem={renderChildProperty}
          getItemKey={(childProperty) => `${keySuffix}${childProperty}`}
          readOnly={schema.readOnly}
        />
      );
    }
  };

  const renderSingleField = ({
    name,
    schema,
    uiSchema,
    parentSchema,
    parentUISchema,
    keySuffix,
    values,
    errors,
    isOnline,
    tenantId,
    contentTypeId,
    entryId,
    language,
    languages,
  }: FieldMetadata): any => {
    const fieldComponentId = `${keySuffix}${name}`;
    const {
      fieldComponent,
      fieldComponentProps,
      fieldValidation,
      widgetComponent,
      widgetComponentProps,
      widgetSchema,
      widgetUISchema,
    } = getComponentsFromSchema(
      name,
      schema,
      uiSchema,
      parentSchema,
      contentTypes,
      contentTypesACL,
      language
    );
    if (fieldComponent == null || widgetComponent == null) return null;
    return (
      // @ts-ignore
      <Field
        {...fieldComponentProps}
        key={fieldComponentId}
        id={fieldComponentId}
        name={name}
        widgetComponent={widgetComponent}
        widgetComponentProps={widgetComponentProps}
        validate={fieldValidation}
        isOnline={isOnline}
        tenantId={tenantId}
        contentTypeId={contentTypeId}
        entryId={entryId}
        widgetSchema={widgetSchema}
        widgetUISchema={widgetUISchema}
        language={language}
        languages={languages}
      />
    );
  };

  const renderField = ({
    name,
    schema,
    uiSchema,
    parentSchema,
    parentUISchema,
    keySuffix,
    values,
    errors,
    isOnline,
    tenantId,
    contentTypeId,
    entryId,
  }: FieldMetadata): JSX.Element[] => {
    if (schema.type === "object") {
      return renderMutipleFields({
        name,
        schema,
        uiSchema,
        parentSchema,
        parentUISchema,
        keySuffix,
        values,
        errors,
        isOnline,
        tenantId,
        contentTypeId,
        entryId,
        language,
        languages,
      });
    } else {
      return renderSingleField({
        name,
        schema,
        uiSchema,
        parentSchema,
        parentUISchema,
        keySuffix,
        values,
        errors,
        isOnline,
        tenantId,
        contentTypeId,
        entryId,
        language,
        languages,
      });
    }
  };

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      if (onSubmit) {
        await onSubmit(values);
      }
      setSubmitting(false);
    },
    [onSubmit]
  );

  const handleValidate = useCallback((values) => {
    let errors = {};
    return errors;
  }, []);

  return (
    <StyledForm.Wrapper>
      <Formik
        enableReinitialize
        // TODO: use a robust way to rerender the form on schema change
        key={schema.title}
        initialValues={formData}
        validate={handleValidate}
        onSubmit={handleSubmit}
      >
        {({
          dirty,
          errors,
          isSubmitting,
          values,
          handleSubmit,
          submitForm,
        }) => {
          bindSubmitForm && bindSubmitForm(submitForm);

          return (
            <FormikForm style={{ width: "100%", height: "100%" }}>
              {shouldPreventLeavingDirtyForm && (
                <Prompt
                  when={dirty}
                  message={strings.components.form.leavingDirtyFormWarning}
                />
              )}
              <fieldset disabled={disabled} style={{ height: "100%" }}>
                <StyledForm.Content>
                  {renderField({
                    name: "",
                    schema,
                    uiSchema,
                    parentSchema: {},
                    parentUISchema: {},
                    keySuffix: "",
                    values,
                    errors,
                    isOnline,
                    tenantId,
                    contentTypeId,
                    entryId,
                    language,
                    languages,
                  })}
                  {hasSubmitButton && (
                    <Button
                      type={"submit"}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      block
                    >
                      {submitLabel}
                    </Button>
                  )}
                </StyledForm.Content>
              </fieldset>
            </FormikForm>
          );
        }}
      </Formik>
    </StyledForm.Wrapper>
  );
};

export default Form;
