import React, { useCallback } from "react";
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import { Td, Th } from "./styled";

type ColumnData = {
  dataKey: string;
  label: string;
  numeric?: boolean;
  width: number;
  render?: (value: any, row: any, dataKey: string) => any;
  flexGrow?: number;
};
type Row = {
  index: number;
};

type VirtualizedTableProps = {
  columns: ColumnData[];
  headerHeight?: number;
  rowHeight?: number;
  onRowClick?: () => void;
  rowCount: number;
  rowGetter: (row: Row) => any;
  cellDataGetter: ({
    columnData,
    dataKey,
    rowData,
  }: {
    columnData: any;
    dataKey: string;
    rowData: any;
  }) => any;
};

export const VirtualizedTable: React.FC<VirtualizedTableProps> = ({
  columns,
  headerHeight = 48,
  rowHeight = 48,
  cellDataGetter,
  ...tableProps
}) => {
  const headerRenderer = ({
    label,
    columnIndex,
  }: TableHeaderProps & { columnIndex: number }) => {
    return <Th>{label}</Th>;
  };

  const cellRenderer: TableCellRenderer = ({
    cellData,
    columnIndex,
    rowData,
    dataKey,
    columnData,
  }) => {
    return (
      <Td
        style={{ height: rowHeight }}
        // align={
        //   (columnIndex != null && columns[columnIndex].numeric) || false
        //     ? "right"
        //     : "left"
        // }
      >
        {columnIndex != null && columns[columnIndex].render
          ? // @ts-ignore
            columns[columnIndex].render(cellData, rowData, dataKey)
          : cellData}
      </Td>
    );
  };

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          height={height}
          width={width}
          rowHeight={rowHeight!}
          gridStyle={{
            direction: "inherit",
          }}
          headerHeight={headerHeight!}
          //className={classes.table}
          {...tableProps}
          //rowClassName={this.getRowClassName}
        >
          {columns.map(({ dataKey, ...other }, index) => {
            return (
              <Column
                key={dataKey}
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    ...headerProps,
                    columnIndex: index,
                  })
                }
                //className={classes.flexContainer}
                cellDataGetter={cellDataGetter}
                cellRenderer={cellRenderer}
                dataKey={dataKey}
                {...other}
              />
            );
          })}
        </Table>
      )}
    </AutoSizer>
  );
};
