/* @flow */
import React from "react";

import StyledVideo from "./styled";

type Props = {
  src?: any,
  controls?: boolean,
};

const Video = ({ src, controls = true }: Props) => {
  return (
    <StyledVideo controls={controls}>
      <source src={src} type="video/mp4" />
    </StyledVideo>
  );
};

export default Video;
