const en = {
  auth: {
    login: "Login",
    username: "Username",
    password: "Password",
    submitLogin: "Login",
    email: "Email",
    confirm: "Confirm",
    backToLogin: "Back to login",
    lostPassword: "Lost password",
    lostPasswordLabel: "We will send the recovery link to",
    enterEmail: "Enter your email",
    sendRecoveryLink: "Send recovery address",
    newPassword: "New password",
    logout: "Logout",
  },
  common: {
    tenants: "Products",
    profile: "Profile",
    new: "New",
    edit: "Edit",
    save: "Save",
    email: "Email",
    role: "Role",
    actions: {
      actionCompleted: "Operation successful",
      createCompleted: "Creation successful",
      updateCompleted: "Update successful",
      deleteCompleted: "Delete successful",
    },
    error: "Something went wrong. Please try again",
  },
  tenantList: {
    title: "Your products",
  },
  contentTypeList: {
    title: "Home",
  },
  emptyState: {
    noData: "No data",
    unauthorized: "Unauthorized",
  },
  offlineBanner: {
    message: "Offline mode",
  },
  updatePWASnackbar: {
    message:
      "E' disponibile una nuova versione dell'app. Clicca qui per aggiornare.",
  },
  tips: {
    title: "With Staza you can",
    tip1: "manage your customers' folders",
    tip2: "easily integrate with other external products",
    tip3: "check the progress of the practices",
    tip4: "consult your documents anywhere",
    tip5: "digitize business documents",
    tip6: "consult customized reports",
    tip7: "export your documents in custom formats",
  },
  user: {
    profile: "Profile",
    name: "Name",
    surname: "Surname",
    nominative: "Nominative",
    email: "Email",
  },
  entries: {
    createDate: "Create date",
    updateDate: "Edit date",
    new: "New",
    actions: {
      "reset-credentials": "Send credentials",
    },
  },
  components: {
    widgetReadOnlyEmptyValue: {
      label: "Empty field",
    },
    imageMapWidget: {
      addMarker: "Add",
      deleteBtt: "Delete",
      modalAddTitle: "Add marker",
      modalUpdateTitle: "Update marker",
    },
    dashboardItem: {
      actions: {
        EXPORT_PDF: "Download PDF",
      },
    },
  },
  settings: {
    title: "Settings",
    lang: "Language",
  },
  languages: {
    it: "Italiano",
    en: "English",
    de: "Deutsche",
  },
};

export default en;
