/* @flow */

const generateEvent = (id: string, name: string, value: any, type: string) => ({
  target: {
    id,
    name,
    type,
    value,
  },
});

export default {
  generateEvent,
};
