import {
  isArray,
  isBoolean,
  isDate,
  isError,
  isFunction,
  isNil,
  isNull,
  isNumber,
  isPlainObject,
  isRegExp,
  isString,
  isSymbol,
  isUndefined,
} from "lodash-es";

const coerceToBoolean = (value: any): boolean => {
  if (isBoolean(value)) {
    return value;
  }
  if (String(value).trim().toLowerCase() === "true") {
    return true;
  }
  if (String(value).trim().toLowerCase() === "false") {
    return false;
  }
  return Boolean(value);
};

const coerceToInteger = (value: any): number => {
  return parseInt(value, 10);
};

const coerceToSameType = (typed: any, untyped: any): any => {
  if (isUndefined(typed)) return undefined;
  if (isNull(typed)) return null;
  if (isBoolean(typed)) return coerceToBoolean(untyped);
  if (isNumber(typed)) return Number(untyped);
  if (isString(typed)) return String(untyped);
  if (isArray(typed))
    return isArray(untyped) ? untyped : isNil ? [] : [untyped];
  if (isFunction(typed)) return isFunction(untyped) ? untyped : () => untyped;
  if (isRegExp(typed)) return RegExp(untyped);
  if (isError(typed)) return Error(untyped);
  if (isDate(typed)) return new Date(untyped);
  if (isSymbol(typed)) return Symbol(untyped);
  if (isPlainObject(typed)) return Object.assign({}, untyped);
  return null;
};

const coerceToType = (
  type:
    | "undefined"
    | "null"
    | "array"
    | "string"
    | "boolean"
    | "number"
    | "integer"
    | "function"
    | "regexp"
    | "error"
    | "date"
    | "symbol"
    | "object",
  value: any
) => {
  switch (type) {
    case "undefined":
      return undefined;
    case "null":
      return null;
    case "array":
      return coerceToSameType([], value);
    case "string":
      return coerceToSameType("", value);
    case "boolean":
      return coerceToSameType(true, value);
    case "number":
      return coerceToSameType(42, value);
    case "integer":
      return coerceToInteger(value);
    case "function":
      return coerceToSameType(function () {}, value);
    case "regexp":
      return coerceToSameType(RegExp(/./), value);
    case "error":
      return coerceToSameType(Error(), value);
    case "date":
      return coerceToSameType(Date(), value);
    case "symbol":
      return coerceToSameType(Symbol(), value);
    case "object":
      return coerceToSameType({}, value);
    default:
      return value;
  }
};

export default {
  coerceToBoolean,
  coerceToInteger,
  coerceToSameType,
  coerceToType,
};
