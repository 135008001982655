import styled from "styled-components";

export const Row = styled.div`
  flex-flow: row wrap;
  display: flex;
  height: 100%;
  justify-content: center;
  align-content: center;
`;

export const Statistc = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 2.5rem;
  font-variant: tabular-nums;
  line-height: 3;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
`;
