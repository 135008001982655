import React, { ContextType, useCallback, useEffect, useState } from "react";
import { inject } from "mobx-react";
import { eq, isNil, isEmpty, get, isObject } from "lodash-es";

import StyledFieldRelation from "./styled";
import { useModal } from "../../components/Modal";
import Button from "../../components/Button";
import { EntryList } from "../../components/EntryList";
import Form from "../../components/Form";
import { Spinner } from "../../components/Spinner";
import { getChildPropertySchema } from "../../utils/jsonSchema";
import formikUtils from "../../utils/formik";
import strings from "../../config/strings";
import EntryModel from "../../models/Entry";

import backendService from "../../services/backend";

import type {
  ContentTypesACLRules,
  EntryFilter,
  Tenant,
  Stores,
  User,
  FixMeLater,
  FilterOperator,
  Locale,
  UserRole,
} from "../../types";
import { ContentType } from "../../models/ContentType";
import { useStores } from "../../hooks/useStores";

type Props = {
  id: string;
  name?: string;
  onBlur?: (event: any) => any;
  onChange?: (event: any) => any;
  relatedTenantId: Tenant["uid"];
  relatedContentTypeId: ContentType["uid"];
  relatedContentTypeSlug: ContentType["slug"];
  value?: string;
  defaultValue?: string;
  readOnly?: boolean;
  contentTypes?: ContentType[];
  contentTypesACL: ContentTypesACLRules;
  interpolation: { key: string; localized?: boolean }[];
  filters: EntryFilter[];
  isOnline?: boolean;
  tenantId?: Tenant["uid"];
  contentTypeId?: ContentType["uid"];
  entryId?: EntryModel["uid"] | null;
  defaultTarget?: string;
  language: Locale;
  languages: Locale[];
};

const mapFilterOperatorToFunction = {
  "=": eq,
};

const FieldRelationWidget = ({
  id,
  name,
  onBlur,
  onChange,
  relatedTenantId,
  relatedContentTypeId,
  value,
  readOnly = false,
  contentTypes = [],
  contentTypesACL,
  interpolation,
  filters,
  isOnline,
  tenantId,
  contentTypeId,
  entryId,
  defaultTarget,
  language,
  languages,
}: Props) => {
  const { tenant, auth } = useStores();
  const [isFetching, setIsFetching] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [relatedEntries, setRelatedEntries] = useState([]);
  const [isFetchingSearch, setIsFetchingSearch] = useState(false);
  const { openModal, closeModal } = useModal();
  const [currentValue, setCurrentValue] = useState(null);
  const relatedContentType = contentTypes.find(
    (contentType) => contentType.uid === relatedContentTypeId
  );
  const [userRole, setUserRole] = useState<UserRole>(null);

  useEffect(() => {
    if (isObject(value)) {
      setCurrentValue(value);

      onChange &&
        onChange(
          //@ts-ignore
          formikUtils.generateEvent(id, name || id, value.uid, "fieldRelation")
        );
    }
  }, [value, onChange, id, name]);

  useEffect(() => {
    let isSubscribed = true;

    const currentTenant = tenant.tenants.find(
      (tenant) => tenant.uid === tenantId
    );
    if (currentTenant) {
      setUserRole(currentTenant.role);
    }

    let relatedEntryId = value;
    let initCurrentUser = false;

    if (!relatedEntryId && defaultTarget === "currentUser") {
      relatedEntryId = auth.user ? auth.user.uid : "0";
      initCurrentUser = true;

      setIsFetching(true);
      backendService
        .getUserRelatedEntry(
          relatedEntryId,
          relatedTenantId,
          relatedContentTypeId
        )
        .then((response) => {
          if (isSubscribed) {
            setIsFetching(false);
            setSelectedEntry(response);

            initCurrentUser &&
              onChange &&
              onChange(
                formikUtils.generateEvent(
                  id,
                  name || id,
                  response.uid,
                  "fieldRelation"
                )
              );
          }
        })
        .catch(() => {
          setIsFetching(false);
        });
    } /*else if (
      relatedEntryId &&
      typeof relatedEntryId !== "object" &&
      Number(relatedEntryId) !== 0
    ) {
      setIsFetching(true);
      backendService
        .getEntry(relatedTenantId, relatedContentTypeId, relatedEntryId)
        .then((response) => {
          if (isSubscribed) {
            setIsFetching(false);
            setSelectedEntry(response);

            onChange &&
              onChange(
                formikUtils.generateEvent(
                  id,
                  name || id,
                  relatedEntryId,
                  "fieldRelation"
                )
              );
          }
        })
        .catch(() => {
          setIsFetching(false);
        });
    }*/
    return () => {
      isSubscribed = false;
    };
  }, [
    value,
    relatedContentTypeId,
    relatedTenantId,
    tenantId,
    defaultTarget,
    id,
    name,
    onChange,
    auth,
    tenant,
  ]);

  const handleOpenModal = useCallback(
    (Component) => {
      openModal({
        content: Component,
      });
    },
    [openModal]
  );
  const handleSearchSelection = useCallback(
    (selectedEntry) => {
      console.log(selectedEntry);
      // Create fake event handled by Formik
      onChange &&
        onChange(
          formikUtils.generateEvent(
            id,
            name || id,
            selectedEntry.uid,
            "fieldRelation"
          )
        );
      setCurrentValue(selectedEntry.data);
      closeModal();
    },
    [onChange, closeModal, id, name]
  );
  const handleCleanEntry = useCallback(() => {
    setSelectedEntry(null);
    onChange &&
      onChange(
        formikUtils.generateEvent(id, name || id, null, "fieldRelation")
      );
  }, [onChange, id, name]);

  const handleSearch = useCallback(async () => {
    setIsFetchingSearch(true);
    let entries = relatedEntries;
    if (entries.length === 0) {
      let remoteEntries = await backendService.getEntries(
        relatedTenantId,
        relatedContentTypeId
      );
      remoteEntries
        .filter((entry) => {
          if (filters.length > 0) {
            return filters.reduce((result, filter) => {
              return (
                result &&
                mapFilterOperatorToFunction[filter.operator](
                  getChildPropertySchema(entry.data, filter.field_key),
                  filter.value
                )
              );
            }, true);
          }
          return true;
        })
        .forEach((entry) => {
          let entryModel = new EntryModel(entry.uid);
          entryModel.updateFromJson(entry);
          entries.push(entryModel);
        });
      setRelatedEntries(entries);
    }
    setIsFetchingSearch(false);

    handleOpenModal(
      <div style={{ height: "400px" }}>
        <EntryList
          contentTypeEntries={entries}
          contentType={relatedContentType ? relatedContentType : null}
          contentTypesACLRules={contentTypesACL}
          contentTypes={contentTypes}
          entryActions={[
            {
              type: "read",
              action: handleSearchSelection,
              aclAction: "entries:read",
            },
          ]}
          isAddButtonEnabled={false}
          user={auth.user}
          userAbility={userRole.acls}
          languages={languages}
          //userRole={userRole}
        />
      </div>
    );
  }, [
    relatedEntries,
    setRelatedEntries,
    userRole,
    contentTypes,
    contentTypesACL,
    filters,
    handleOpenModal,
    handleSearchSelection,
    relatedContentType,
    relatedContentTypeId,
    relatedTenantId,
    setIsFetchingSearch,
    auth,
    languages,
  ]);

  if (isFetching) {
    return (
      <StyledFieldRelation.Container>
        <Spinner type={"spin"} relativeStyle={true} />
      </StyledFieldRelation.Container>
    );
  }

  return (
    <StyledFieldRelation.Container>
      {!currentValue && (
        <StyledFieldRelation.EmptyFieldPlaceholder>
          {strings.widgets.fieldRelation.emptyData}
        </StyledFieldRelation.EmptyFieldPlaceholder>
      )}

      {currentValue && (
        <StyledFieldRelation.FieldValue
          onClick={() =>
            handleOpenModal(
              <Form
                schema={relatedContentType ? relatedContentType.schema : null}
                uiSchema={
                  relatedContentType ? relatedContentType.uiSchema : null
                }
                formData={selectedEntry.data}
                hasSubmitButton={false}
                shouldPreventLeavingDirtyForm={false}
                disabled={true}
                tenantId={tenantId}
                contentTypeId={contentTypeId}
                entryId={entryId}
                language={language}
                languages={languages}
              />
            )
          }
        >
          {interpolation
            .map((interpolationPath) =>
              interpolationPath.localized
                ? get(currentValue, interpolationPath.key)[language]
                : currentValue[interpolationPath.key]
            )
            .filter((pathValue) => !isNil(pathValue) && !isEmpty(pathValue))
            .join(" ")}
        </StyledFieldRelation.FieldValue>
      )}

      <StyledFieldRelation.ActionGroupContainer>
        {selectedEntry && !readOnly && isOnline && (
          <Button
            model={"secondary"}
            icon={"delete"}
            onClick={handleCleanEntry}
          />
        )}

        {!selectedEntry && !readOnly && (
          <Button
            icon={"search"}
            model={"secondary"}
            onClick={handleSearch}
            loading={isFetchingSearch}
          />
        )}
      </StyledFieldRelation.ActionGroupContainer>
    </StyledFieldRelation.Container>
  );
};

FieldRelationWidget.mapSchemaToProps = (
  schema: FixMeLater,
  uiSchema: FixMeLater
) => {
  let props: FixMeLater = {};
  props.relatedContentTypeId = schema.contentTypeId;
  props.relatedTenantId = schema.tenantId;
  props.relatedContentTypeSlug = schema.contentTypeSlug;
  props.readOnly = isNil(uiSchema["ui:enabled"])
    ? schema.readOnly
    : !uiSchema["ui:enabled"];
  props.interpolation = uiSchema["ui:interpolation"] || [];
  props.filters = uiSchema["ui:filters"] || [];
  props.defaultTarget = uiSchema["ui:defaultTarget"] || null;
  return props;
};

export default FieldRelationWidget;

/* 

schema: {
  "type": "string",
  "order": 5,
  "title": {
    "it": "Categoria"
  },
  "tenantId": "50b2c73d-1ab3-4281-8a85-802030ba7425",
  "contentTypeId": "e76fc3bd-decb-4b2c-8b23-537cc0187bf4"
}

ui schema: {
  "ui:widget":"field-relation",
  "ui:interpolation":[{"key":"name","localized":true}]
}


*/
