import styled from "styled-components";

const IconWrapper = styled.div`
  border: 0;
  border-radius: 20px;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px #c1c7d0;
  background-color: ${(props) => props.theme.global.colors.white};

  img {
    width: 48px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: color 0.25s ease-in-out, border-color 0.25s ease-in-out;
  color: ${(props) => props.theme.global.colors.secondaryColor};
  cursor: pointer;

  ${IconWrapper} svg {
    fill: ${(props) => props.theme.global.colors.secondaryColor};
    transition: fill 0.25s ease-in-out;
  }

  &:hover {
    color: ${(props) => props.theme.global.colors.brand};
    ${IconWrapper} svg {
      fill: ${(props) => props.theme.global.colors.brand};
    }
  }
`;

const Title = styled.div`
  padding-top: 1em;
  text-align: center;
  font-weight: ${(props) => props.theme.global.typography.h5.fontWeight};
`;

export default {
  CardContainer,
  IconWrapper,
  Title,
};
