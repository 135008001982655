import { Plugin } from "@uppy/core";
import RateLimitedQueue from "@uppy/utils/lib/RateLimitedQueue";
import settle from "@uppy/utils/lib/settle";

class LocalStorageUpload extends Plugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.type = "uploader";
    this.id = this.opts.id || "LocalStorageUpload";
    this.title = "LocalStorageUpload";

    // set default options
    const defaultOptions = {
      resume: true,
      autoRetry: true,
      useFastRemoteRetry: true,
      limit: 0,
      retryDelays: [0, 1000, 3000, 5000],
    };

    // merge default options with the ones set by user
    this.opts = Object.assign({}, defaultOptions, opts);

    // Simultaneous upload limiting is shared across all uploads with this plugin.
    this.limitUploads = new RateLimitedQueue(this.opts.limit);
  }

  upload = (entryId, fieldId, file, current, total) => {
    // Create a new localStorage upload
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.data);
      reader.onload = () => {
        const rawCacheData =
          JSON.parse(localStorage.getItem(this.opts.localStorageKey)) || [];

        rawCacheData.push({
          id: file.id,
          name: file.name,
          type: file.type,
          src: reader.result,
        });

        localStorage.setItem(
          this.opts.localStorageKey,
          JSON.stringify(rawCacheData)
        );

        this.uppy.emit("upload-success", file, {
          destination: "localstorage",
          entryId,
          fieldId,
          fileId: file.id,
          src: reader.result,
        });
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  };

  uploadFiles = (files) => {
    const actions = files.map((file, i) => {
      const current = parseInt(i, 10) + 1;
      const total = files.length;

      if (file.error) {
        return () => Promise.reject(new Error(file.error));
      } else {
        this.uppy.emit("upload-started", file);
        return () =>
          this.upload(
            this.opts.entryId,
            this.opts.fieldId,
            file,
            current,
            total
          );
      }
    });

    const promises = actions.map((action) => {
      const limitedAction = this.limitUploads(action);
      return limitedAction();
    });

    return settle(promises);
  };

  handleUpload = (fileIDs) => {
    if (fileIDs.length === 0) {
      this.uppy.log("LocalStorageUpload: no files to upload!");
      return Promise.resolve();
    }

    this.uppy.log("LocalStorageUploading is uploading...");
    const filesToUpload = fileIDs.map((fileID) => this.uppy.getFile(fileID));

    return this.uploadFiles(filesToUpload).then(() => null);
  };

  install = () => {
    this.uppy.addUploader(this.handleUpload);

    this.uppy.on("reset-progress", this.handleResetProgress);

    if (this.opts.autoRetry) {
      this.uppy.on("back-online", this.uppy.retryAll);
    }
  };

  uninstall = () => {
    const uploader = this.uppy.getPlugin("LocalStorageUpload");
    this.uppy.removeUploader(this.handleUpload);
  };
}

export default LocalStorageUpload;
