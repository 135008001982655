/* @flow */
import React, { useCallback, useState } from "react";
import _ from "lodash-es";
import { MaterialPicker } from "react-color";

import StyledColorWidget from "./styled";

type Props = {|
  id: string,
  name?: string,
  onChange?: (event: any) => any,
  value?: {},
  defaultValue?: {},
  readOnly?: boolean,
  isOnline?: boolean,
|};

const ColorWidget = ({
  id,
  name,
  onChange,
  value,
  defaultValue = {},
  readOnly = false,
  isOnline,
}: Props) => {
  const [isColorPickerVisble, setIsColorPickerVisible] = useState(false);
  const [color, setColor] = useState(
    typeof value === "object" ? value : value && JSON.parse(value)
  );

  const handleChange = useCallback(
    (event: any) => {
      if (readOnly) return;
      setColor(event);
      onChange && onChange(JSON.stringify(event));
    },
    [onChange, readOnly, setColor]
  );

  const handleOpenColorPicker = useCallback(
    () => !readOnly && setIsColorPickerVisible(true),
    [setIsColorPickerVisible, readOnly]
  );
  const handleCloseColorPicker = useCallback(
    () => setIsColorPickerVisible(false),
    [setIsColorPickerVisible]
  );

  return (
    <div>
      <StyledColorWidget.Swatch onClick={handleOpenColorPicker}>
        <StyledColorWidget.ColorPreview
          style={{
            background:
              color && color.rgb
                ? `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
                : "#fff",
          }}
        />
      </StyledColorWidget.Swatch>
      {isColorPickerVisble ? (
        <StyledColorWidget.Popover>
          <StyledColorWidget.PopoverCover onClick={handleCloseColorPicker} />
          <MaterialPicker color={color} onChange={handleChange} />
        </StyledColorWidget.Popover>
      ) : null}
    </div>
  );
};

ColorWidget.mapSchemaToProps = (schema: Object, uiSchema: Object) => {
  let props = {};
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.readOnly = _.isNil(uiSchema["ui:readOnly"])
    ? schema.readOnly
    : uiSchema["ui:readOnly"];
  return props;
};

export default ColorWidget;
