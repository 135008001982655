import React, { useCallback } from "react";
import dayjs from "dayjs";

import Input from "../../components/Input";
import WidgetReadOnlyEmptyValue from "../../components/WidgetReadOnlyEmptyValue";
import { FixMeLater } from "../../types";

type Props = {
  id: string;
  name?: string;
  onBlur?: (event: any) => any;
  onChange?: (event: any) => any;
  value?: string;
  defaultValue?: string;
  min?: string;
  max?: string;
  step?: number;
  readOnly?: boolean;
  isOnline?: boolean;
};

const DateTimeWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue,
  min,
  max,
  step = 1, // or 60 to step minutes
  readOnly = false,
  isOnline,
}: Props) => {
  const handleChange = useCallback(
    (event: any) => {
      if (readOnly) return;
      onChange && onChange(event);
    },
    [onChange, readOnly]
  );

  if (readOnly) {
    return <div>{value || defaultValue || <WidgetReadOnlyEmptyValue />}</div>;
  }

  return (
    <div>
      <Input
        type={"datetime-local"}
        pattern={"[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}"}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        value={value != null ? value : defaultValue}
        min={min}
        max={max}
        step={step}
        readOnly={readOnly || !isOnline}
      />
    </div>
  );
};

DateTimeWidget.mapSchemaToProps = (
  schema: FixMeLater,
  uiSchema: FixMeLater
) => {
  let props: FixMeLater = {};
  const defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.defaultValue =
    defaultValue === "current"
      ? dayjs().format("YYYY-MM-DDTHH:mm:ss")
      : defaultValue;
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.min = schema.minimum;
  props.max = schema.maximum;
  props.step = schema.multipleOf;
  props.readOnly = schema.readOnly;
  return props;
};

export default DateTimeWidget;
