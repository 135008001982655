import React, { useCallback, useEffect, useMemo } from "react";
import { inject, observer, Observer } from "mobx-react";
import { useTranslation, withTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import type { TFunction } from "react-i18next";

import StyledContentTypeList from "./styled";
import navigationUtils from "../../utils/navigation";
import Container from "../../components/Container";
import { Spinner } from "../../components/Spinner";
import EntryHeader from "../../components/EntryHeader";
import { Card } from "../../components/Card";

import type { Stores, Tenant } from "../../types";
import { useStores } from "../../hooks/useStores";
import { ContentType } from "../../models/ContentType";

// type OwnProps = {
//   history: RouterHistory,
//   location: RouterLocation,
//   match: RouterMatch,
//   t: TFunction,
// };

// type StoresProps = {
//   tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
//   contentTypes: ContentType[],
//   isLoading: boolean,
//   fetchTenants: () => Promise<any>,
//   fetchContentTypes: (
//     tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
//   ) => Promise<any>,
//   getTenant: (
//     tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
//   ) => Tenant | null,
//   setApplicationConfiguration: (configuration: Object) => any,
// };

// type Props = OwnProps & StoresProps;

// const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
//   const tenantId = props.match.params.tenantId;

//   return {
//     tenantId,
//     contentTypes: stores.contentType.contentTypes,
//     isLoading: stores.tenant.isLoading || stores.contentType.isLoading,
//     fetchTenants: stores.tenant.fetchTenants,
//     fetchContentTypes: stores.contentType.fetchContentTypes,
//     getTenant: stores.tenant.getTenant,
//     setApplicationConfiguration: stores.app.setApplicationConfiguration,
//   };
// };

export const ContentTypeListScreen = observer(() => {
  const location = useLocation();
  const history = useHistory();
  const { tenant, app } = useStores();
  const { t } = useTranslation();
  // const {
  //   tenantId,
  //   contentTypes,
  //   history,
  //   location,
  //   isLoading,
  //   fetchTenants,
  //   fetchContentTypes,
  //   setApplicationConfiguration,
  //   getTenant,
  //   t,
  // } = props;

  // const initialize = async () => {
  //   const tenantId = navigationUtils.fromRoutes.tenantId(location.pathname);

  //   if (tenantId) {
  //     await contentType.fetchContentTypes(tenantId);

  //     // const tenant = getTenant(tenantId);
  //     // if (tenant) {
  //     //   setApplicationConfiguration(tenant.configuration);
  //     // }
  //   }
  // };

  // useEffect(() => {
  //   initialize();
  // }, []);

  const contentTypes: ContentType[] = useMemo(() => {
    const tenantId = navigationUtils.fromRoutes.tenantId(location.pathname);

    let contentTypes: ContentType[] = [];

    if (tenantId) {
      const currentTenant = tenant.tenants.find(
        (tenant) => tenant.uid === tenantId
      );
      if (currentTenant) {
        contentTypes = currentTenant.contentTypes;
      }
    }

    return contentTypes;
  }, [location, tenant]);

  const handleContentTypeClick = useCallback(
    (contentTypeId) => {
      const tenantId = navigationUtils.fromRoutes.tenantId(location.pathname);

      if (tenantId) {
        history.push(
          navigationUtils.routes.entry.list(tenantId, contentTypeId)
        );
      }
    },
    [history, location]
  );

  return (
    <Container>
      <EntryHeader.Header>
        <StyledContentTypeList.Title>
          {t("contentTypeList.title")}
        </StyledContentTypeList.Title>
      </EntryHeader.Header>
      {/* {contentType.isLoading && <Spinner size={150} />} */}
      <StyledContentTypeList.CardsContainer>
        {contentTypes.map((contentType, index) => (
          <Card
            key={contentType.uid}
            icon={
              contentType.uiSchema ? contentType.uiSchema["ui:icon"] : "thLarge"
            }
            title={contentType.label[tenant.locale]}
            onClick={() => handleContentTypeClick(contentType.uid)}
          />
        ))}
      </StyledContentTypeList.CardsContainer>
    </Container>
  );
});
