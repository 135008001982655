import styled from "styled-components";

const StyledContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  background: ${(props) => props.theme.global.colors.white};
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${(props) => props.theme.global.padding.medium};
  overflow: hidden;
  white-space: nowrap;
  font-size: ${(props) => props.theme.global.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.global.typography.h5.fontWeight};
  min-height: 3rem;
  border-bottom: 1px solid
    ${(props) => props.theme.global.colors.sidebar.backgroundColor};
`;

const StyledHeadTitle = styled.div`
  flex: 1 1;
  padding: 1rem 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledBody = styled.div`
  padding: 1.5rem;
  display: flex;
  overflow-y: auto;
`;

export default {
  Container: StyledContainer,
  Header: StyledHeader,
  HeadTitle: StyledHeadTitle,
  Body: StyledBody,
};
