import React from "react";

import StyledEntryHeader from "./styled";

import { DynamicIcon } from "../Icon";

import type { DynamicIcon as TypeDynamicIcon } from "../../types";

type TitleProps = {
  icon?: TypeDynamicIcon;
  title: string;
};

type ActionContainerProps = {
  children?: any;
};

type HeaderProps = {
  children?: any;
  bordered?: boolean;
};

const Title = ({ icon, title }: TitleProps) => (
  <StyledEntryHeader.TitleContaier>
    {icon && (
      <StyledEntryHeader.IconWrapper>
        <DynamicIcon lib={icon.library} iconName={icon.name} />
      </StyledEntryHeader.IconWrapper>
    )}
    <StyledEntryHeader.Title>{title}</StyledEntryHeader.Title>
  </StyledEntryHeader.TitleContaier>
);

const ActionContainer = ({ children }: ActionContainerProps) => (
  <StyledEntryHeader.ActionContainer>
    {children}
  </StyledEntryHeader.ActionContainer>
);

const Header = ({ children, bordered = false, ...otherProps }: HeaderProps) => (
  <StyledEntryHeader.EntryHeader bordered={bordered} {...otherProps}>
    {children}
  </StyledEntryHeader.EntryHeader>
);
export default { Title, ActionContainer, Header };
