/* @flow */
import styled, { keyframes } from "styled-components";

const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const StyledImage = styled.img`
  box-sizing: border-box;
  width: 100%;
  height: ${(props) => (props.hasLoaded ? "100%" : "0")};
  padding-top: ${(props) => (props.hasLoaded ? "0" : "100%")};
  object-fit: contain;
  overflow: hidden;
`;

const StyledThumbImage = styled.img`
  margin: auto;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  max-width: 100%;
  max-height: 100%;
`;

const pulseAnimation = keyframes`
  from {
    transform: scale(0.0);
  }

  to {
    transform: scale(1.0);
    opacity: 0;
  }`;

const StyledSpinner = styled.div`
  display: inline-block;
  position: absolute;
  top: 20%;
  left: 30%;
  width: 40%;
  height: 0;
  padding-top: 40%;
  border-radius: 100%;
  background-color: ${(props) => props.theme.global.colors.brand};
  animation: fadein 0.5s, ${pulseAnimation} 0.8s infinite ease-in-out;
`;

export default {
  Container: StyledContainer,
  ThumbImage: StyledThumbImage,
  Image: StyledImage,
  Spinner: StyledSpinner,
};
