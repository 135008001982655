const de = {
  auth: {
    login: "Login",
    username: "Username",
    password: "Password",
    submitLogin: "Login",
    email: "Email",
    confirm: "Bestätigen",
    backToLogin: "Zurück zur Anmeldung",
    lostPassword: "Passwort verloren",
    lostPasswordLabel: "Wir senden den Wiederherstellungslink an",
    enterEmail: "Geben sie ihre E-Mail Adresse ein",
    sendRecoveryLink: "Wiederherstellungsadresse senden",
    newPassword: "Neues Passwort",
    logout: "Logout",
  },
  common: {
    tenants: "Produkte",
    profile: "Profil",
    new: "Neu",
    edit: "Bearbeiten",
    save: "Speichern",
    email: "E-Mail",
    role: "Rolle",
    actions: {
      actionCompleted: "Operation erfolgreich",
      createCompleted: "Aktualisierung erfolgreich",
      updateCompleted: "Aktualisierung erfolgreich",
      deleteCompleted: "Erfolgreich löschen",
    },
    error: "Etwas ist schief gelaufen. Bitte versuche es erneut",
  },
  tenantList: {
    title: "Deine Produkte",
  },
  contentTypeList: {
    title: "Home",
  },
  emptyState: {
    noData: "Keine Daten",
    unauthorized: "Nicht autorisiert",
  },
  offlineBanner: {
    message: "Offline-Modus",
  },
  updatePWASnackbar: {
    message:
      "E' disponibile una nuova versione dell'app. Clicca qui per aggiornare.",
  },
  tips: {
    title: "With Staza you can",
    tip1: "manage your customers' folders",
    tip2: "easily integrate with other external products",
    tip3: "check the progress of the practices",
    tip4: "consult your documents anywhere",
    tip5: "digitize business documents",
    tip6: "consult customized reports",
    tip7: "export your documents in custom formats",
  },
  user: {
    profile: "Profil",
    name: "Name",
    surname: "Nachname",
    nominative: "Nominativ",
    email: "E-Mail",
  },
  entries: {
    createDate: "Erstellungsdatum",
    updateDate: "Datum bearbeiten",
    new: "Neu",
    actions: {
      "reset-credentials": "Anmeldeinformationen senden",
    },
  },
  components: {
    widgetReadOnlyEmptyValue: {
      label: "Leeres Feld",
    },
    imageMapWidget: {
      addMarker: "Add",
      deleteBtt: "Delete",
      modalAddTitle: "Add marker",
      modalUpdateTitle: "Update marker",
    },
    dashboardItem: {
      actions: {
        EXPORT_PDF: "Download PDF",
      },
    },
  },
  settings: {
    title: "die Einstellungen",
    lang: "Sprache",
  },
  languages: {
    it: "Italiano",
    en: "English",
    de: "Deutsche",
  },
};

export default de;
