const it = {
  auth: {
    login: "Login",
    username: "Username",
    password: "Password",
    submitLogin: "Login",
    email: "Email",
    confirm: "Conferma",
    backToLogin: "Torna al login",
    lostPassword: "Ho dimenticato la password",
    lostPasswordLabel: "Invieremo il link di recupero a",
    enterEmail: "Inserisci email",
    sendRecoveryLink: "Invia indirizzo di recupero",
    newPassword: "Nuova password",
    logout: "Logout",
  },
  common: {
    tenants: "Prodotti",
    profile: "Profilo",
    new: "Nuovo",
    edit: "Modifica",
    save: "Salva",
    email: "Email",
    role: "Ruolo",
    select: "Seleziona",
    actions: {
      actionCompleted: "Operazione eseguita correttamente",
      createCompleted: "Creazione eseguita correttamente",
      updateCompleted: "Aggiornamento eseguito correttamente",
      deleteCompleted: "Cancellazione eseguita correttamente",
    },
    error: "Qualcosa è andato storto. Per favore riprova",
  },
  tenantList: {
    title: "I tuoi prodotti",
  },
  contentTypeList: {
    title: "Home",
  },
  emptyState: {
    noData: "Nessun dato presente",
    unauthorized: "Non possiedi i permessi necessari per questa operazione",
  },
  offlineBanner: {
    message: "Applicazione offline",
  },
  updatePWASnackbar: {
    message:
      "E' disponibile una nuova versione dell'app. Clicca qui per aggiornare.",
  },
  tips: {
    title: "Con Staza puoi",
    tip1: "gestire le cartelle dei tuoi clienti",
    tip2: "integrarti facilmente con altri prodotti esterni",
    tip3: "verificare lo stato avanzamento delle pratiche",
    tip4: "consultare ovunque i tuoi documenti",
    tip5: "digitalizzare i documenti aziendali",
    tip6: "consultare report personalizzati",
    tip7: "esportare i tuoi documenti in formati personalizzati",
  },
  user: {
    profile: "Profilo",
    name: "Nome",
    surname: "Cognome",
    nominative: "Nominativo",
    email: "Email",
  },
  entries: {
    createDate: "Data creazione",
    updateDate: "Data modifica",
    new: "Nuovo",
    actions: {
      "reset-credentials": "Invia credenziali",
    },
  },
  components: {
    widgetReadOnlyEmptyValue: {
      label: "Campo non compilato",
    },
    imageMapWidget: {
      addMarker: "Aggiungi",
      deleteBtt: "Elimina",
      modalAddTitle: "Aggiungi marker",
      modalUpdateTitle: "Modifica marker",
    },
    dashboardItem: {
      actions: {
        EXPORT_PDF: "Scarica PDF",
      },
    },
  },
  settings: {
    title: "Impostazioni",
    general: {
      title: "Generali",
      lang: "Lingua",
    },
    organization: {
      title: "Organizzazione",
      description: "Descrizione",
      phone: "Telefono",
      email: "Email",
      address1: "Indirizzo Linea 1",
      address2: "Indirizzo Linea 2",
      website: "Sito web",
    },
  },
  languages: {
    it: "Italiano",
    en: "English",
    de: "Deutsche",
  },
};

export default it;
