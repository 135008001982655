import styled from "styled-components";

const Title = styled.h2`
  font-size: ${(props) => props.theme.global.typography.h2.fontSize};
  font-weight: ${(props) => props.theme.global.typography.h2.fontWeight};
  line-height: ${(props) => props.theme.global.typography.lineHeight.title};
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export default {
  Title,
  CardsContainer,
};
