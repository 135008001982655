import styled from "styled-components";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Heading = styled.h5`
  display: block;
  position: relative;
  box-sizing: border-box;
  font-weight: ${(props) => props.theme.global.typography.h5.fontWeight};
  font-size: ${(props) => props.theme.global.typography.h5.fontSize};
  line-height: ${(props) => props.theme.global.typography.lineHeight.title};
  color: ${(props) => props.theme.global.colors.text.light};
  padding-bottom: ${(props) => props.theme.global.padding.medium};
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;
`;

export default {
  Container,
  Heading,
  RowContainer,
};
