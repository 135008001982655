/* @flow */
import React, { useEffect, useCallback } from "react";

type Props = {|
  id: string,
  name?: string,
  onChange?: (event: any) => any,
  type?: string,
  value?: string,
  defaultValue?: string,
|};

const HiddenWidget = ({
  id,
  name,
  onChange,
  value,
  type = "hidden",
  defaultValue = "",
}: Props) => {
  const handleChange = useCallback(
    (event: any) => {
      onChange && onChange(event);
    },
    [onChange]
  );

  useEffect(() => {
    if (!value && onChange) {
      // Set initial value with setTimeout function to avoid conflict with formik dirty props
      setTimeout(() => {
        onChange({
          target: {
            id,
            name,
            type,
            value: value != null ? value : defaultValue,
          },
        });
      }, 200);
    }
  }, []);

  return (
    <div>
      <input
        type={type}
        id={id}
        name={name}
        onChange={handleChange}
        value={value != null ? value : defaultValue}
      />
    </div>
  );
};

HiddenWidget.mapSchemaToProps = (schema: Object, uiSchema: Object) => {
  let props = {};
  props.defaultValue = schema.default || uiSchema["value"];
  return props;
};

export default HiddenWidget;
