import styled, { css } from "styled-components";

const readOnlyStyle = css`
  cursor: not-allowed;
`;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: ${(props) => props.theme.global.padding.small};
`;

const ButtonUpload = styled.button<{ readOnly?: boolean }>`
  outline: none;
  display: inline-block;
  position: relative;
  cursor: pointer;
  height: 120px;
  width: 120px;
  background: transparent;
  color: ${(props) => props.theme.global.colors.secondaryText.light};
  border: 1px dashed ${(props) => props.theme.global.colors.border.normal.light};
  border-radius: ${(props) => props.theme.global.border.radius.small};
  font-size: 2.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  opacity: 0.55;

  &:hover {
    opacity: 0.75;
    box-shadow: none;
  }

  &:focus {
    opacity: 0.75;
    box-shadow: none;
  }

  & span {
    font-size: 0.8rem;
  }

  ${(props) => props.readOnly && readOnlyStyle}
`;

const FilePreviewContainer = styled.div<{ readOnly?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 800px;
  ${(props) => props.readOnly && readOnlyStyle}
`;

const FilePlaceholder = styled.div`
  height: 120px;
  width: 180px;
  display: block;
  color: ${(props) => props.theme.global.colors.secondaryText.light};
  margin-bottom: ${(props) => props.theme.global.padding.small};
  margin-right: ${(props) => props.theme.global.padding.small};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2.5em;
  opacity: 0.55;

  & span {
    margin-top: 0.5em;
    font-size: 0.8rem;
  }
`;

const ThumbContainer = styled.div`
  position: relative;
  cursor: pointer;
  height: 120px;
  width: 180px;
  display: block;
  border: ${(props) => props.theme.global.border.size.xsmall} solid
    ${(props) => props.theme.global.colors.border.normal.light};
  margin-bottom: ${(props) => props.theme.global.padding.small};
  margin-right: ${(props) => props.theme.global.padding.small};
`;

const ThumbContent = styled.div`
  height: 100%;
  width: 100%;
`;

const ThumbAction = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
`;

const ThumbRemoveButton = styled.button`
  outline: none;
  font-size: inherit;
  line-height: 1;
  margin: 0;
  border: 0;
  color: inherit;
  cursor: pointer;
  color: white;
  width: 20px;
  height: 20px;
  padding: 1px;
  opacity: 0.75;
  background-color: ${(props) => props.theme.global.colors.black};
  border-radius: 10px;
  padding-top: 2px;
`;

const CacheIconContainer = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
`;

export default {
  Container,
  ButtonUpload,
  FilePreviewContainer,
  FilePlaceholder,
  ThumbContainer,
  ThumbAction,
  ThumbRemoveButton,
  ThumbContent,
  CacheIconContainer,
};
