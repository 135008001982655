import { action, computed, observable } from "mobx";
import { cloneDeep, set } from "lodash-es";

// TODO: remove when exports data is fetched from the backend
//import mockStructure from "../mocks/exports/analisienergetiche/index";
import api from "../services/backend";
import { uuidv4 } from "../utils";
import LogStore from "../stores/Log";
import exportService from "../services/export";

import type { Entry, FixMeLater } from "../types";

export default class EntryModel {
  uid: Entry["uid"];

  @observable contentTypeId: Entry["contentTypeId"];
  @observable contentTypeName: Entry["contentTypeName"];
  @observable data: Entry["data"];
  @observable createdAt: Entry["createdAt"];
  @observable updatedAt: Entry["updatedAt"];
  @observable stagesMetadata: Entry["stagesMetadata"];
  @observable isExporting: boolean = false;
  @observable tenantId: Entry["tenantId"];
  logStore: LogStore | null;

  constructor(uid: Entry["uid"] = uuidv4(), logStore?: LogStore) {
    this.uid = uid;
    this.logStore = logStore || null;
  }

  @action
  updateFromJson(entryJson: Entry) {
    this.contentTypeId = entryJson.contentTypeId;
    this.data = entryJson.data;
    this.createdAt = entryJson.createdAt;
    this.updatedAt = entryJson.updatedAt;
    this.stagesMetadata = entryJson.stagesMetadata;
    this.contentTypeName = entryJson.contentTypeName;
    this.tenantId = entryJson.tenantId;
  }

  // TODO: we should remove metadata when ContentType refs are implemented
  @action
  export = async (
    metadata: Array<FixMeLater>,
    useMock: boolean = false
  ): Promise<void> => {
    this.isExporting = true;

    // the exportService has a huge bundle size caused by pdfMake
    // TODO: NOT working anymore: You may need an appropriate loader to handle this file type.
    // const exportService = await import("../services/export");
    try {
      // useMock ? mockValues : cloneDeep(this.data);
      const data = cloneDeep(this.data);

      for (let field of metadata) {
        if (field.uiSchema["ui:widget"] === "field-relation" && field.data) {
          const entryData = await api.getEntry(
            field.schema.tenantId,
            field.schema.contentTypeId,
            field.data
          );

          set(data, field.name, entryData.data);
        }
      }
      /*
       exportService.default.exportEntry(
        JSON.parse(mockStructure[this.contentTypeName]),
        data
      );*/
      const result = await api.getExportConfiguration(
        this.tenantId,
        this.contentTypeId
      );

      exportService.exportEntry(result, data);

      this.logStore &&
        this.logStore.success("Dati esportati correttamente", null);
    } catch (err) {
      this.logStore && this.logStore.error(`Errore nell'export dei dati`, err);
    }
    this.isExporting = false;
  };

  @computed
  get asJson(): Entry {
    return {
      uid: this.uid,
      contentTypeId: this.contentTypeId,
      data: this.data,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      stagesMetadata: this.stagesMetadata,
      contentTypeName: this.contentTypeName,
      tenantId: this.tenantId,
    };
  }
}
