/* @flow */
import React, { useCallback } from "react";

import StyledInput from "./styled";
import WidgetReadOnlyEmptyValue from "../../components/WidgetReadOnlyEmptyValue";

type Props = {|
  id: string,
  name?: string,
  onBlur?: (event: any) => any,
  onChange?: (event: any) => any,
  value?: number,
  defaultValue?: number,
  min?: number,
  max?: number,
  step?: number,
  readOnly?: boolean,
  isOnline?: boolean,
|};

const RangeWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue,
  min,
  max,
  step,
  readOnly = false,
  isOnline,
}: Props) => {
  const handleChange = useCallback(
    (event: any) => {
      if (readOnly) return;
      onChange && onChange(event);
    },
    [onChange, readOnly]
  );
  if (readOnly) {
    return <div>{value || defaultValue || <WidgetReadOnlyEmptyValue />}</div>;
  }
  return (
    <div>
      <StyledInput
        type={"range"}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        value={(value != null ? value : defaultValue) || 0}
        min={min}
        max={max}
        step={step}
        readOnly={readOnly || !isOnline}
      />
      {value != null ? value : defaultValue}
    </div>
  );
};

RangeWidget.mapSchemaToProps = (schema: Object, uiSchema: Object) => {
  let props = {};
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.title = schema.title;
  props.min = schema.minimum || uiSchema["minimum"];
  props.max = schema.maximum || uiSchema["maximum"];
  props.step = schema.multipleOf;
  props.readOnly = schema.readOnly;
  return props;
};

export default RangeWidget;
