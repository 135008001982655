import { makeAutoObservable } from "mobx";

import backendService from "../services/backend";
import EntryModel from "../models/Entry";
// import cacheUtil from "../utils/cache";
// import fileUtil from "../utils/file";
// import { uuidv4 } from "../utils/string";
// import Uppy from "@uppy/core";
// import AwsS3Multipart from "@uppy/aws-s3-multipart";
// import keys from "../config/keys";
// import { set } from "lodash-es";

import type { Tenant, Entry, FormData, Stores, CustomAction } from "../types";
import { ContentType } from "../models/ContentType";

const CACHE_KEY = "CACHE_ENTRY_API_REQUESTS";

export default class EntryStore {
  stores: Stores;
  isInitialized: boolean = false;

  entries: EntryModel[] = [];

  isFetching: boolean = false;

  isCreating: boolean = false;

  isUpdating: boolean = false;

  isDeleting: boolean = false;

  isPerforming: boolean = false;

  isSynchronizing: boolean = false;

  //pwaStore: PWAStore;
  fetchingQueue: ContentType["uid"][] = [];

  constructor(stores: Stores) {
    this.stores = stores;
    makeAutoObservable(this);
  }

  initialize = async (): Promise<void> => {
    try {
      // autorun(() => {
      //   if (this.pwaStore.isOnline) {
      //     this.runSynchronization();
      //   }
      // });
    } catch (err) {
      this.stores.log.warning(`Errore nella inizializzazione EntryStore`, err);
    }
    this.isInitialized = true;
  };

  destroy = async (): Promise<void> => {
    this.entries = [];
    this.isFetching = false;
    this.isCreating = false;
    this.isUpdating = false;
    this.isDeleting = false;
  };

  fetchEntries = async (
    tenantId: Tenant["uid"],
    contentTypeId: ContentType["uid"]
  ): Promise<void> => {
    //if (this.fetchingQueue.indexOf(contentTypeId) < 0) {
    this.isFetching = true;
    let remoteEntries: Entry[] = [];
    try {
      remoteEntries = await backendService.getEntries(tenantId, contentTypeId);
    } catch (err) {
      throw err;
      // this.stores.log.warning(
      //   `Errore nel caricamento dati, assicurarsi di essere connessi a internet`,
      //   err
      // );
    } finally {
      const newEntries: EntryModel[] = [];
      remoteEntries.forEach((entry) => {
        const entryModel = new EntryModel(entry.uid, this.stores.log);
        entryModel.updateFromJson(entry);
        newEntries.push(entryModel);
      });
      //this.entries = this.entries.filter(
      //  (entry) => entry.contentTypeId !== Number(contentTypeId)
      //);
      this.entries = newEntries; //this.entries.concat(newEntries);

      //this.fetchingQueue = this.fetchingQueue.filter(
      //  (itemId) => itemId !== contentTypeId
      //);
      this.isFetching = false;
      // }
    }
  };

  fetchEntry = async (
    tenantId: Tenant["uid"],
    contentTypeId: ContentType["uid"],
    entryId: Entry["uid"]
  ): Promise<void> => {
    this.isFetching = true;
    let remoteEntry: Entry | null = null;

    try {
      remoteEntry = await backendService.getEntry(
        tenantId,
        contentTypeId,
        entryId
      );
    } catch (err) {
      this.stores.log.warning(
        `Errore nel caricamento dati, assicurarsi di essere connessi a internet`,
        err
      );
    }

    if (remoteEntry != null) {
      const entryModel = new EntryModel(entryId, this.stores.log);
      entryModel.updateFromJson(remoteEntry);
      this.entries = this.entries
        .filter((entry) => entry.uid !== entryId)
        .concat(entryModel);
    }

    this.isFetching = false;
  };

  createEntry = async (
    tenantId: Tenant["uid"],
    contentTypeId: ContentType["uid"],
    formData: FormData
  ): Promise<EntryModel | null> => {
    this.isCreating = true;
    let createdEntry: Entry | null = null;
    let entryModel: EntryModel | null = null;

    try {
      createdEntry = await backendService.createEntry(
        tenantId,
        contentTypeId,
        formData
      );
      this.stores.log.success("Dati creati correttamente", null);
    } catch (err) {
      this.stores.log.warning(`Errore nella creazione dati`, err);
    }
    if (createdEntry != null) {
      entryModel = new EntryModel(createdEntry.uid, this.stores.log);
      entryModel.updateFromJson(createdEntry);

      this.entries.push(entryModel);
    }

    this.isCreating = false;
    return entryModel;
  };

  updateEntry = async (
    tenantId: Tenant["uid"],
    contentTypeId: ContentType["uid"],
    entryId: Entry["uid"],
    formData: FormData
  ): Promise<EntryModel | undefined> => {
    this.isUpdating = true;
    let updatedEntry: Entry | null = null;
    let entryModel: EntryModel | undefined = undefined;
    // if (this.pwaStore.isOnline) {
    try {
      updatedEntry = await backendService.updateEntry(
        tenantId,
        contentTypeId,
        entryId,
        formData
      );

      this.stores.log.success("Dati aggiornati correttamente", null);
    } catch (err) {
      this.stores.log.warning(`Errore nel salvataggio dei dati`, err);
    }
    if (updatedEntry != null) {
      entryModel = this.entries.find((entry) => entry.uid === entryId);

      if (!entryModel) {
        entryModel = new EntryModel(updatedEntry.uid, this.stores.log);
        this.entries.push(entryModel);
      }
      entryModel.updateFromJson(updatedEntry);
    }
    // } else {
    //   cacheUtil.cacheAction(CACHE_KEY, {
    //     cacheItemId: uuidv4(),
    //     method: "UPDATE",
    //     tenantId,
    //     contentTypeId,
    //     entryId,
    //     data: formData,
    //   });

    //   this.logStore.info("Dati aggiornati in locale.", null);
    // }

    this.isUpdating = false;
    return entryModel;
  };

  deleteEntry = async (
    tenantId: Tenant["uid"],
    contentTypeId: ContentType["uid"],
    entryId: Entry["uid"]
  ): Promise<void> => {
    this.isDeleting = true;
    try {
      await backendService.deleteEntry(tenantId, contentTypeId, entryId);

      this.stores.log.success("Dati cancellati correttamente", null);
      this.entries = this.entries.filter((entry) => entry.uid !== entryId);
    } catch (err) {
      this.stores.log.warning(`Errore nella cancellazione dei dati`, err);
    }

    this.isDeleting = false;
  };

  performCustomAction = async (
    tenantId: Tenant["uid"],
    contentTypeId: ContentType["uid"],
    entryId: Entry["uid"],
    action: CustomAction
  ): Promise<void> => {
    this.isPerforming = true;

    try {
      await backendService.performAction(
        tenantId,
        contentTypeId,
        entryId,
        action
      );
    } catch (err) {
      throw err;
    } finally {
      this.isPerforming = false;
    }
  };

  get isExporting(): boolean {
    return this.entries.some((entryModel) => entryModel.isExporting);
  }

  get isLoading(): boolean {
    return (
      this.isFetching ||
      this.isCreating ||
      this.isUpdating ||
      this.isDeleting ||
      this.isExporting
    );
  }

  // cacheUploadFiles = (
  //   tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  //   contentTypeId: $PropertyType<ContentType, "id">,
  //   entryId: $PropertyType<Entry, "id">,
  //   fieldId: string,
  //   prevValue: string[]
  // ) => {
  //   cacheUtil.cacheUploadFileAction(CACHE_KEY, {
  //     cacheItemId: uuidv4(),
  //     method: "UPLOAD-FILE",
  //     tenantId,
  //     contentTypeId,
  //     entryId,
  //     fieldId,
  //     prevValue,
  //   });
  // };

  // @action
  // fetchOfflineQueue = () => {
  //   let queue = cacheUtil.getItem(CACHE_KEY) || [];

  //   queue.forEach(async (action) => {
  //     let actionCompleted = false;
  //     if (action.method === "UPDATE") {
  //       this.updateEntry(
  //         action.tenantId,
  //         action.contentTypeId,
  //         action.entryId,
  //         action.data
  //       );
  //       actionCompleted = true;
  //     } else if (action.method === "UPLOAD-FILE") {
  //       actionCompleted = await new Promise(async (resolve, reject) => {
  //         const entryFilesQueue = cacheUtil.getItem(
  //           `upload_cache_${action.entryId}_${action.fieldId}`
  //         );

  //         if (entryFilesQueue && entryFilesQueue.length > 0) {
  //           const entryPatchData = {};

  //           const uppy = Uppy({ logger: Uppy.debugLogger }).use(
  //             AwsS3Multipart,
  //             {
  //               companionUrl: keys.PRESIGN_FILE_S3_URL,
  //             }
  //           );

  //           entryFilesQueue.forEach((file) => {
  //             uppy.addFile({
  //               name: file.name,
  //               type: file.type,
  //               data: fileUtil.dataURItoFile(file.src, file.name),
  //             });
  //           });
  //           const uploadResult = await uppy.upload();

  //           const newFilesURL = uploadResult.successful.map((fileMeta) =>
  //             fileMeta.uploadURL.split("?").shift()
  //           );
  //           const filesURL = action.prevValue.filter((src) => src.length > 0);

  //           set(entryPatchData, action.fieldId, filesURL.concat(newFilesURL));
  //           this.updateEntry(
  //             action.tenantId,
  //             action.contentTypeId,
  //             action.entryId,
  //             entryPatchData
  //           );

  //           cacheUtil.removeItem(
  //             `upload_cache_${action.entryId}_${action.fieldId}`
  //           );

  //           resolve(true);
  //         }
  //       });
  //     }

  //     if (actionCompleted) {
  //       queue = queue.filter(
  //         (queueAction) => queueAction.cacheItemId !== action.cacheItemId
  //       );
  //       cacheUtil.setItem(CACHE_KEY, queue);
  //     }
  //   });
  // };

  // @action
  // runSynchronization = async (): Promise<void> => {
  //   if (this.pwaStore.isOnline) {
  //     this.isSynchronizing = true;

  //     await this.fetchOfflineQueue();

  //     this.isSynchronizing = false;
  //   }
  // };

  // @action
  // fetchRoles = async (
  //   tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  // ): Promise<UserRole[]> => {
  //   this.isFetching = true;
  //   let remoteRoles = [];

  //   try {
  //     remoteRoles = await backendService.getRoles(tenantId);
  //   } catch (err) {
  //     this.logStore.warning(
  //       `Errore nel caricamento dati, assicurarsi di essere connessi a internet`,
  //       err
  //     );
  //   }

  //   this.isFetching = false;
  //   return remoteRoles;
  // };

  // @action
  // inviteUser = async (
  //   email: $PropertyType<User, "email">,
  //   role: $PropertyType<UserRole, "name">,
  //   tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  // ): Promise<void> => {
  //   this.isFetching = true;

  //   try {
  //     await backendService.inviteUser(email, role, tenantId);
  //     this.logStore.success("Utente invitato correttamente", null);
  //   } catch (err) {
  //     this.logStore.warning(
  //       `Errore nell'esecuzione della richiesta, assicurarsi di essere connessi a internet`,
  //       err
  //     );
  //   }

  //   this.isFetching = false;
  // };
}
