import React, { useCallback, useEffect } from "react";
import { inject, observer, Observer } from "mobx-react";
import { Redirect, useHistory } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import type { TFunction } from "react-i18next";

import StyledTenantList from "./styled";
import navigationUtils from "../../utils/navigation";
import Container from "../../components/Container";
import {Spinner} from "../../components/Spinner";
import EntryHeader from "../../components/EntryHeader";
import {Card} from "../../components/Card";

import type { Tenant, Stores } from "../../types";
import { useStores } from "../../hooks/useStores";


export const TenantList = observer( () => {
  const {t} = useTranslation();
  const {tenant, app} = useStores();
  const history = useHistory();
  // const {
  //   tenants,
  //   history,
  //   location,
  //   isLoading,
  //   fetchTenants,
  //   resetStores,
  //   t,
  //   setApplicationConfiguration,
  // } = props;

  

  const handleTenantClick = useCallback(
    (tenantId: Tenant['uid']) => {
      history.push(navigationUtils.routes.contentType.list(tenantId))
    },
    [history]
  );

  // if (tenants && tenants.length === 1) {
  //   return (
  //     <Redirect to={navigationUtils.routes.contentType.list(tenants[0].name)} />
  //   );
  // }

  return (
          <Container>
            <EntryHeader.Header>
              <StyledTenantList.Title>{t("tenantList.title")}</StyledTenantList.Title>
            </EntryHeader.Header>
            {tenant.isLoading && <Spinner size={150} />}
            <StyledTenantList.CardsContainer>
              {tenant.tenants.map((tenant) => (
                <Card
                  key={tenant.uid}
                  icon={"thLarge"}
                  logo={tenant.configuration.logo}
                  title={tenant.label[app.locale]}
                  onClick={() => handleTenantClick(tenant.uid)}
                />
              ))}
            </StyledTenantList.CardsContainer>
          </Container>
  );
});