import { makeAutoObservable } from "mobx";
import { JSONSchema, MultiLanguageValue, UISchema } from "../types";

export class ContentType {
  uid: string;
  slug: string;
  label: MultiLanguageValue;
  description: string | null;
  schema: JSONSchema;
  uiSchema: UISchema | null;

  constructor(
    uid: string,
    slug: string,
    label: MultiLanguageValue,
    description: string | null,
    schema: JSONSchema,
    uiSchema: UISchema
  ) {
    this.uid = uid;
    this.slug = slug;
    this.label = label;
    this.description = description;
    this.schema = schema;
    this.uiSchema = uiSchema || {};
    makeAutoObservable(this);
  }
}
