/* This component not work with react-i18next!!! */
import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import { withTheme } from "styled-components";
import { isArray, isPlainObject } from "lodash-es";
import { meetsContrastGuidelines, invert } from "polished";

import { fuzzyMatch } from "../../utils/string";
import WidgetReadOnlyEmptyValue from "../../components/WidgetReadOnlyEmptyValue";
import { Entry, FixMeLater, Locale, Tenant } from "../../types";
import { useTranslation } from "react-i18next";
import { ContentType } from "../../models/ContentType";
import { get } from "lodash";

const filterOptions = (candidate: SelectOption, input?: string) => {
  if (!input) {
    return true;
  }
  return fuzzyMatch(candidate.label, input);
};

type SelectOptionValue = string | boolean | number;
type SelectOptionLabel = string;
type SelectOption = {
  value: SelectOptionValue;
  label: SelectOptionLabel;
};

type Props = {
  id: string;
  name?: string;
  onBlur?: (event: any) => any;
  onChange?: (event: any) => any;
  value?: SelectOptionValue | SelectOptionValue[];
  defaultValue?: SelectOptionValue | SelectOptionValue[];
  options?: SelectOption[];
  isMultiple?: boolean;
  autoFocus?: boolean;
  theme: FixMeLater;
  creatable?: boolean;
  readOnly?: boolean;
  isOnline?: boolean;
  language?: Locale;
  languages?: Locale[];
  isLoading?: boolean;
  placeholder?: string;
  isClearable?: boolean;
  optionsSource?: Promise<Entry[]>;
  optionValuesInterpolation?: string[];
  optionLabelsInterpolation?: string[];
};

const SelectWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue,
  options = [],
  isMultiple,
  autoFocus = false,
  theme,
  creatable = false,
  readOnly = false,
  isOnline = true,
  language,
  isLoading = false,
  placeholder,
  isClearable = false,
  optionsSource,
  optionValuesInterpolation,
  optionLabelsInterpolation,
}: Props) => {
  const { t } = useTranslation();
  const [currentOptions, setCurrentOptions] = useState(options);
  const getSelectValue = (value, defaultValue, options, isMultiple) => {
    let outerValue = value || defaultValue;
    if (isMultiple) {
      if (outerValue == null) {
        outerValue = [];
      } else if (!Array.isArray(outerValue)) {
        outerValue = [outerValue];
      }
    }
    return isMultiple
      ? // $FlowFixMe
        options.filter((option) => outerValue.includes(option.value))
      : options.find((option) => option.value === outerValue);
  };
  const [selectValue, setSelectValue] = useState(
    getSelectValue(value, defaultValue, currentOptions, isMultiple)
  );
  useEffect(() => {
    setSelectValue(
      getSelectValue(value, defaultValue, currentOptions, isMultiple)
    );
  }, [setSelectValue, value, defaultValue, currentOptions, isMultiple]);

  const fetchOptionSource = async () => {
    const entries = await optionsSource;
    const remoteOptions = entries.map((entry) => {
      const interpolatedValue = optionValuesInterpolation
        .map((interpolationPath) => get(entry, interpolationPath))
        .join(" ");
      const interpolatedLabel = optionLabelsInterpolation
        .map((interpolationPath) => get(entry, interpolationPath))
        .join(" ");

      return { value: interpolatedValue, label: interpolatedLabel };
    });

    setCurrentOptions(remoteOptions);
  };

  useEffect(() => {
    if (optionsSource) {
      fetchOptionSource();
    }
  }, []);

  const defaultOption =
    isMultiple && Array.isArray(defaultValue)
      ? currentOptions.filter((option) => defaultValue.includes(option.value))
      : currentOptions.find((option) => option.value === defaultValue);

  const handleChange = useCallback(
    (selectedOption) => {
      if (readOnly) return;
      setSelectValue(selectedOption || defaultOption);
      if (onChange) {
        onChange(
          selectedOption &&
            (isMultiple || Array.isArray(selectedOption)
              ? selectedOption.map((option) => option.value)
              : selectedOption.value)
        );
      }
    },
    [onChange, isMultiple, setSelectValue, defaultOption, readOnly]
  );

  const selectStyles = {
    clearIndicator: (styles, state) => ({ ...styles }),
    container: (styles, state) => ({
      ...styles,
      width: "100%",
    }),
    control: (styles, state) => ({
      ...styles,
      padding: "0",
      "&:hover": {
        borderColor: state.isFocused
          ? theme.global.colors.brand
          : theme.global.colors.border.normal,
      },
      border: `${theme.global.border.size.xsmall} solid ${theme.global.colors.border.normal}`,
      borderRadius: theme.global.border.radius.small,
      boxShadow: "none",
      transition: `border-color ${theme.global.animation.fade.duration} ease`,
      cursor: "pointer",
      minHeight: theme.global.input.minHeight,
    }),
    dropdownIndicator: (styles, state) => ({ ...styles, padding: "6px" }),
    group: (styles, state) => ({ ...styles }),
    groupHeading: (styles, state) => ({ ...styles }),
    indicatorsContainer: (styles, state) => ({ ...styles, padding: "0px" }),
    indicatorSeparator: (styles, state) => ({
      ...styles,
      backgroundColor: theme.global.colors.border.normal,
    }),
    input: (styles, state) => ({
      ...styles,
    }),
    loadingIndicator: (styles, state) => ({ ...styles }),
    loadingMessageCSS: (styles, state) => ({ ...styles }),
    menu: (styles, state) => ({
      ...styles,
      "@media (max-width:1000px)": {
        position: "fixed",
        top: "auto",
        left: 0,
        bottom: 0,
        margin: 0,
        maxHeight: "100px",
        overflowY: "auto",
        borderRadius: 0,
        backgroundColor: "#CCD0D5",
      },
    }),
    menuList: (styles, state) => ({ ...styles }),
    menuPortal: (styles, state) => ({ ...styles }),
    multiValue: (styles, state) => ({ ...styles }),
    multiValueLabel: (styles, state) => ({ ...styles }),
    multiValueRemove: (styles, state) => ({ ...styles }),
    noOptionsMessageCSS: (styles, state) => ({ ...styles }),
    option: (styles, state) => {
      const { AA } = meetsContrastGuidelines(
        theme.global.colors.brand,
        theme.global.colors.secondaryColor
      );

      const color = theme.global.colors.white;
      // AA ? theme.global.colors.secondaryColor
      // : invert(theme.global.colors.secondaryColor);

      return {
        ...styles,
        backgroundColor: state.isSelected
          ? theme.global.colors.brand
          : "transparent",
        cursor: "pointer",
        "&:hover": {
          color,
          backgroundColor: theme.global.colors.brand,
        },
        "&:active": {
          color,
          backgroundColor: theme.global.colors.brand,
        },
      };
    },
    placeholder: (styles, state) => ({ ...styles }),
    singleValue: (styles, state) => ({ ...styles }),
    valueContainer: (styles, state) => ({ ...styles }),
  };

  const SelectComponent = creatable ? CreatableSelect : Select;

  if (readOnly) {
    let selectedValue = value;

    if (isArray(value)) {
      selectedValue = Object.values(value).join(", ");
    } else if (isPlainObject(value)) {
      selectedValue = value[language];
    }

    return <div>{selectedValue || <WidgetReadOnlyEmptyValue />}</div>;
  }

  return (
    <SelectComponent
      id={id}
      name={name}
      isMulti={isMultiple}
      onChange={handleChange}
      onBlur={onBlur}
      value={selectValue}
      autoFocus={autoFocus}
      options={currentOptions}
      filterOption={
        /* $FlowFixMe */
        filterOptions
      }
      style={{ width: "100%" }}
      styles={selectStyles}
      isDisabled={readOnly || !isOnline}
      blurInputOnSelect={false}
      placeholder={placeholder || t("common.select")}
      isLoading={isLoading}
      isClearable={isClearable}
    />
  );
};

SelectWidget.mapSchemaToProps = (
  schema: FixMeLater,
  uiSchema: FixMeLater,
  language: Locale
) => {
  let props: FixMeLater = {};
  if (schema.enum != null) {
    props.options = schema.enum.map((optionValue, index) => ({
      value: optionValue,
      label:
        schema.enumNames != null && index < schema.enumNames.length
          ? String(schema.enumNames[index][language])
          : String(optionValue),
    }));
  }
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.creatable = !!uiSchema["ui:creatable"];
  props.readOnly = schema.readOnly;
  return props;
};

export default withTheme(SelectWidget);
