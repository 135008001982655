/** https://github.com/mvasin/react-div-100vh */
import React, { Component } from "react";

import convertStyle from "../../utils/screen";

type Props = {
  style?: {},
};
type State = {
  style: {},
};

export default class FullscreenWrapper extends Component<Props, State> {
  state = {
    style: {},
  };
  // On mount and window resize converts rvh values to px (if there are any).
  // Also, adds `height: 100rvh` if height is not specified at all
  updateStyle = () => {
    const convertedStyle = convertStyle(this.props.style, window.innerHeight);
    this.setState({ style: convertedStyle });
  };

  componentDidMount() {
    this.updateStyle();
    window.addEventListener("resize", this.updateStyle);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateStyle);
  }

  render() {
    return <div {...this.props} style={this.state.style} />;
  }
}
