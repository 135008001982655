import React from "react";
import { Redirect } from "react-router-dom";

import navigationUtils from "../../utils/navigation";

import { AuthLayout } from "../../components/AuthLayout";
import Form from "../../components/Form";
import { Link } from "../../components/Link";
import { FormData, JSONSchema, Locale, UISchema } from "../../types";
import { useTranslation } from "react-i18next";
import { useStores } from "../../hooks/useStores";
import { observer } from "mobx-react";
import i18n from "../../services/i18n";
import { useSnackbar } from "notistack";

export const AuthScreen: React.FC = observer(() => {
  const { auth } = useStores();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  if (auth.isLoggedIn) {
    return <Redirect to={"/cms"} />;
  }

  const schema: JSONSchema = {
    title: t("auth.login"),
    type: "object",
    required: ["username", "password"],
    properties: {
      username: {
        title: { [i18n.language]: t("auth.username") },
        type: "string",
      },
      password: {
        title: { [i18n.language]: t("auth.password") },
        type: "string",
      },
    },
  };
  const uiSchema: UISchema = {
    username: {
      "ui:autofocus": true,
    },
    password: {
      "ui:widget": "password",
    },
  };

  return (
    <AuthLayout title={schema.title}>
      <>
        <Form
          schema={schema}
          uiSchema={uiSchema}
          formData={{}}
          onSubmit={async (values: FormData) => {
            try {
              await auth.login(values.username, values.password);
            } catch (err) {
              enqueueSnackbar(t("common.error"), {
                variant: "error",
              });
            }
          }}
          submitLabel={t("auth.submitLogin")}
          language={i18n.language as Locale}
          languages={[i18n.language as Locale]}
        />
        <Link
          href={navigationUtils.routes.auth.lostPassword()}
          text={t("auth.lostPassword")}
        />
      </>
    </AuthLayout>
  );
});
