/** @flow @format */
import { action, observable } from "mobx";
import WebFont from "webfontloader";

import type { Font, FontProvider, FontFamily } from "../types";

export default class FontModel {
  fontProvider: FontProvider;
  fontFamily: FontFamily;
  @observable isLoading: boolean = false;
  @observable isLoaded: boolean = false;
  @observable couldNotLoad: boolean = false;

  constructor(font: Font) {
    this.fontProvider = font.fontProvider;
    this.fontFamily = font.fontFamily;
  }

  @action
  handleLoading = () => {
    this.isLoading = true;
    this.isLoaded = false;
    this.couldNotLoad = false;
  };

  @action
  handleActive = () => {
    this.isLoading = false;
    this.isLoaded = true;
    this.couldNotLoad = false;
  };
  @action
  handleInactive = () => {
    this.isLoading = false;
    this.isLoaded = false;
    this.couldNotLoad = true;
  };

  @action
  handleFontLoading = (familyName: string, fontVariation: string) => {};

  @action
  handleFontActive = (familyName: string, fontVariation: string) => {};

  @action
  handleFontInactive = (familyName: string, fontVariation: string) => {};

  @action
  load = async () => {
    if (this.isLoaded) {
      return;
    }

    const WebFontConfig = {
      loading: this.handleLoading,
      active: this.handleActive,
      inactive: this.handleInactive,
      fontloading: this.handleFontLoading,
      fontactive: this.handleFontActive,
      fontinactive: this.handleFontInactive,
      [this.fontProvider]: {
        families: [this.fontFamily],
      },
    };

    WebFont.load(WebFontConfig);
  };
}
