import React from "react";
import {
  WidthProvider,
  Responsive as ResponsiveGridLayout,
} from "react-grid-layout";

import "react-grid-layout/css/styles.css";

type DashboardLayoutProps = {
  children: any;
};

const ReactGridLayout: any = WidthProvider(ResponsiveGridLayout);

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  return (
    <ReactGridLayout
      /*cols={12}*/
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
      rowHeight={30}
      isDraggable={false}
      isResizable={false}
    >
      {children}
    </ReactGridLayout>
  );
};

export default DashboardLayout;
