/* @flow */
import React from "react";

import StyledAudio from "./styled";

type Props = {
  src?: any,
  controls?: boolean,
};

const Audio = ({ src, controls = true }: Props) => {
  return (
    <StyledAudio controls={controls}>
      <source src={src} type="audio/mpeg" />
    </StyledAudio>
  );
};

export default Audio;
