import styled, { keyframes, css } from "styled-components";

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }`;

const pulseAnimation = keyframes`
  from {
    transform: scale(0.0);
  }

  to {
    transform: scale(1.0);
    opacity: 0;
  }`;

const relativeStyle = css`
  position: relative;
`;
const absoluteStyle = css<{
  size: number
}>`
  position: absolute;
  top: calc(50% - ${(props) => props.size / 2}px);
  left: calc(50% - ${(props) => props.size / 2}px);
`;

const SpinSpinner = styled.div<{
  size: number,
  relativeStyle?:boolean,
}>`
  display: inline-block;
  box-sizing: border-box;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  animation: fadein 0.5s, ${spinAnimation} infinite 0.75s linear;
  border: 2px solid ${(props) => props.theme.global.colors.brand};
  border-top-color: transparent;
  border-radius: 100%;

  ${(props) => (props.relativeStyle ? relativeStyle : absoluteStyle)}
`;

const PulseSpinner = styled.div<{
  size: number,
  relativeStyle?:boolean,
}>`
  display: inline-block;
  box-sizing: border-box;
  min-width: ${(props) => props.size}px;
  min-height: ${(props) => props.size}px;
  background-color: ${(props) => props.theme.global.colors.brand};
  border-radius: 100%;
  animation: fadein 0.5s, ${pulseAnimation} 0.8s infinite ease-in-out;

  ${(props) => (props.relativeStyle ? relativeStyle : absoluteStyle)}
`;

export default {
  Spin: SpinSpinner,
  Pulse: PulseSpinner,
};
